import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum StocktakeTargetType {
    Inventory = 'INVENTORY',
    InventoryGroup = 'INVENTORYGROUP',
    Location = 'LOCATION',
    Supplier = 'SUPPLIER',
}
export default StocktakeTargetType;

const labels: EnumDisplayLabels<typeof StocktakeTargetType> = {
    Inventory: 'Inventory',
    InventoryGroup: 'Inventory group',
    Location: 'Location',
    Supplier: 'Supplier',
};

export const StocktakeTargetTypeDisplay = createDisplayEnum(StocktakeTargetType, labels);
