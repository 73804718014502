import { CircularProgress } from '@mui/material';
import StatusPill from 'components/StatusPill/StatusPill';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { SalesOrderLineStatus } from '../../models/SalesOrderLineStatus';
import './SalesItemStatusBadge.scss';

export default function SalesItemStatusBadge({
    statusId,
    status,
    size = 'medium',
    className,
}: {
    statusId?: number;
    status?: SalesOrderLineStatus;
    size?: 'small' | 'medium';
    className?: string;
}) {
    const query = salesApi.useSalesItemStatusesQuery();
    const statusToRender = status ?? query.data?.find(s => s.id === statusId);

    return status == null && query.isLoading ? (
        <CircularProgress size={20} />
    ) : statusToRender ? (
        <StatusPill
            className={coalesceClassNames('SalesItemStatusBadge', className)}
            style={{
                backgroundColor: statusToRender.backgroundColor,
                borderColor: statusToRender.backgroundColor,
                color: statusToRender.textColor,
            }}
            size={size}
        >
            {statusToRender.name}
        </StatusPill>
    ) : (
        <>{statusId}</>
    );
}
