import { rest, RestRequest } from 'msw';

import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { mergeDeep } from 'utils/helpers';
import InventoryReceiptStatus from './enums/InventoryReceiptStatus';
import LocationType from './enums/LocationType';
import UnitOfMeasureFamily from './enums/UnitOfMeasureFamily';
import {
    fakeInventory,
    fakeInventoryGroupSummaries,
    fakeInventoryMovements,
    fakeInventoryReceipts,
    fakeLocations,
    fakeSuppliers,
    fakeUnitsOfMeasure,
    generateFakeInventoryReceipt,
    generateLocationPath,
} from './faker/InventoryFaker';
import { Inventory } from './models/Inventory';
import { InventoryDetailResult } from './models/InventoryDetailResult';
import { InventoryLocationJoinDetail } from './models/InventoryLocationJoinDetail';
import {
    InventoryLocationPath,
    InventoryLocationsResult,
    InventoryLocationWarehouse,
} from './models/InventoryLocationsResult';
import { InventoryMeasure } from './models/InventoryMeasure';
import { InventoryReceiptUpdate } from './models/InventoryReceiptUpdate';
import { Location } from './models/Location';
import { LocationDetailResult } from './models/LocationDetailResult';
import { LocationInventory } from './models/LocationInventory';

const BASE_URL = `${Env.API_BASE_URL}`;

export const inventoryHandlers = [
    // // List inventory
    rest.post(`${BASE_URL}/inventory`, (req, res, ctx) => {
        const result = {
            data: fakeInventory,
            total: 0,
            schemaExtensions: {
                udfs: [],
            },
        };
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Inventory details
    rest.get(`${BASE_URL}/inventory/:id/detail`, (req, res, ctx) => {
        const data = fakeInventory.find(i => i.id === req.params.id);
        if (!data) {
            return res(ctx.delay(500), ctx.status(400));
        }

        const result: InventoryDetailResult = {
            data,
            schemaExtensions: {
                attributes: [],
                udfs: [],
            },
        };

        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Inventory locations
    rest.get(`${BASE_URL}/inventory/:id/locations`, (req, res, ctx) => {
        const buckets = fakeLocations.filter(l => l.locationType === LocationType.Bucket);
        const paths = buckets.reduce((result, bucket) => {
            const myPath = [] as InventoryLocationPath[];
            let node: Location | undefined = bucket;
            while (node) {
                myPath.unshift({
                    id: node.id,
                    name: node.name,
                    locationType: node.locationType,
                });
                node = node.parentLocationId
                    ? // eslint-disable-next-line @typescript-eslint/no-loop-func
                      fakeLocations.find(l => l.id === node?.parentLocationId)
                    : undefined;
            }

            result.push(myPath);
            return result;
        }, [] as InventoryLocationPath[][]);

        let totalCount = 0;
        const warehouses = paths.reduce((arr: InventoryLocationWarehouse[], path) => {
            const wId = path[0].id;
            let warehouse: InventoryLocationWarehouse | undefined = arr.find(w => w.id === wId);
            if (!warehouse) {
                warehouse = {
                    id: path[0].id,
                    name: path[0].name,
                    locationType: LocationType.Warehouse,
                    total: 0,
                    locations: [],
                };
                arr.push(warehouse);
            }
            // create a copy and remove first elem
            const pathWithoutWarehouse = [...path];
            pathWithoutWarehouse.shift();

            warehouse.locations.push({
                count: 1,
                path: pathWithoutWarehouse,
            });
            warehouse.total++;
            totalCount++;

            return arr;
        }, []);

        const result: InventoryLocationsResult = {
            inventoryId: req.params.id as string,
            total: totalCount,
            warehouses,
        };

        return res(ctx.delay(500), ctx.status(result ? 200 : 400), ctx.json(result));
    }),

    // Update
    rest.post(`${BASE_URL}/inventory/:id/update`, (req, res, ctx) => {
        const body = req.body as Inventory;
        const index = fakeInventory.findIndex(i => i.id === body.id);
        if (index > -1) {
            fakeInventory.splice(index, 1, body);
        }
        return res(ctx.delay(500), ctx.status(index > -1 ? 200 : 400), ctx.json({}));
    }),

    // inventory stock levels
    rest.get(`${BASE_URL}/inventory/location/:id`, (req, res, ctx) => {
        const result: LocationInventory[] = fakeInventory.map(i => ({
            tenantInventoryId: i.id,
            quantityAllocated: 0,
            quantityOnHand: 1,
            context: {
                inventory: {
                    id: i.id,
                    inventoryType: i.inventoryType,
                    partNumber: i.partNumber,
                    description: i.description,
                    notes: i.notes,
                    minimumQuantityLevel: i.minimumQuantityLevel,
                    maximumQuantityLevel: i.maximumQuantityLevel,
                    reorderQuantity: i.reorderQuantity,
                },
            },
        }));
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // Inventory stock movements
    rest.get(`${BASE_URL}/inventory/:id/movements`, (req, res, ctx) => {
        const result = fakeInventoryMovements;
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // Inventory/location container details
    rest.get(`${BASE_URL}/inventory/:inventoryId/location/:locationId`, (req, res, ctx) => {
        const inventory = fakeInventory.find(i => req.params.inventoryId === i.id);

        const result: InventoryLocationJoinDetail = {
            tenantInventoryId: inventory?.id ?? faker.string.uuid(),
            quantityOnHand: faker.number.int(1000),
            quantityAllocated: faker.number.int(100),
            context: { locationPath: generateLocationPath(req.params.locationId as string) },
        };
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // Inventory/location container details
    rest.get(`${BASE_URL}/inventory/:inventoryId/measures`, (req, res, ctx) => {
        const result: InventoryMeasure[] = [
            {
                canPurchase: true,
                canSell: true,
                canConsume: true,
                id: faker.string.uuid(),
                isArchived: true,
                isDisplayUnit: true,
                name: 'Each',
                unitOfMeasure: {
                    name: 'Each',
                    family: UnitOfMeasureFamily.Unit,
                    key: 'each',
                    symbol: 'ea',
                },
                value: 1,
            },
        ];
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // Inventory groups list
    rest.post(`${BASE_URL}/inventorygroup/withattributes`, (req, res, ctx) => {
        const data = fakeInventoryGroupSummaries.map(g => ({
            id: g.id,
            name: g.name,
            context: {
                attributes: [],
            },
        }));
        const result = {
            data,
            total: data.length,
        };
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // Units of measure

    rest.get(`${BASE_URL}/unitsofmeasure`, (req, res, ctx) => {
        const result = fakeUnitsOfMeasure;
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // inventory receipts list
    rest.post(`${BASE_URL}/inventoryreceipt`, (req, res, ctx) => {
        const result = {
            data: fakeInventoryReceipts,
            total: fakeInventoryReceipts.length,
        };
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // inventory receipt create
    rest.post(`${BASE_URL}/inventoryreceipt/create`, (req, res, ctx) => {
        const model = generateFakeInventoryReceipt();
        mergeDeep(model, req.body);
        model.status = InventoryReceiptStatus.Draft;
        fakeInventoryReceipts.push(model);
        return res(ctx.delay(100), ctx.status(200), ctx.json(model));
    }),

    // inventory receipt detail
    rest.get(`${BASE_URL}/inventoryreceipt/:id`, (req, res, ctx) => {
        const result = fakeInventoryReceipts.find(ir => req.params.id === ir.id);
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // inventory receipt update
    rest.post(
        `${BASE_URL}/inventoryreceipt/:id/update`,
        (req: RestRequest<InventoryReceiptUpdate>, res, ctx) => {
            const model = fakeInventoryReceipts.find(ir => req.params.id === ir.id);
            mergeDeep(model, req.body);
            model?.inventory.forEach(item => {
                const inventory = fakeInventory.find(i => i.id === item.id);
                if (inventory) {
                    item.context = { inventory };
                }
                item.locationAssignments.forEach(location => {
                    const contextLocation = fakeLocations.find(l => l.id === location.locationId);
                    if (contextLocation) {
                        location.context = {
                            location: contextLocation,
                            path: generateLocationPath(contextLocation.id),
                        };
                    }
                });
            });
            return res(ctx.delay(100), ctx.status(200));
        },
    ),

    // inventory receipt archive
    rest.post(`${BASE_URL}/inventoryreceipt/:id/archive`, (req, res, ctx) => {
        const model = fakeInventoryReceipts.find(ir => req.params.id === ir.id);
        if (model) {
            model.isArchived = true;
        }
        return res(ctx.delay(100), ctx.status(200));
    }),

    // inventory receipt unarchive
    rest.post(`${BASE_URL}/inventoryreceipt/:id/unarchive`, (req, res, ctx) => {
        const model = fakeInventoryReceipts.find(ir => req.params.id === ir.id);
        if (model) {
            model.isArchived = false;
        }
        return res(ctx.delay(100), ctx.status(200));
    }),

    // Location list
    rest.post(`${BASE_URL}/location`, (req, res, ctx) => {
        const result = {
            data: fakeLocations,
            schemaExtensions: {
                udfs: [],
            },
        };
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),

    // Location detail
    rest.get(`${BASE_URL}/location/:id/detail`, (req, res, ctx) => {
        const data = fakeLocations.find(i => i.id === req.params.id);
        if (!data) {
            return res(ctx.delay(500), ctx.status(400));
        }

        const result: LocationDetailResult = {
            data,
            schemaExtensions: {
                udfs: [],
            },
        };

        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Supplier list
    rest.post(`${BASE_URL}/supplier`, (req, res, ctx) => {
        const result = {
            data: fakeSuppliers,
            schemaExtensions: {
                udfs: [],
            },
        };
        return res(ctx.delay(100), ctx.status(200), ctx.json(result));
    }),
];
