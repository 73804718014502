import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { ApiTagType, api } from 'services/api';
import { WorkOrderDetail, WorkOrderDetailSchema } from './models/WorkOrderDetail';
import { WorkOrderItemDetail, WorkOrderItemDetailSchema } from './models/WorkOrderItemDetail';
import { Workflow, WorkflowSchema } from './models/Workflow';

export type WorkOrderSearchParams = DynamicQueryParams<{
    search: string;
}>;

const workOrdersApi = api.injectEndpoints({
    endpoints: build => ({
        workOrderDetail: build.query<WorkOrderDetail, string>({
            query: id => ({
                url: `/workorder/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => WorkOrderDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.WorkOrder, id }],
        }),

        workOrderWorkstationGroupAssign: build.mutation<
            void,
            { workOrderId: string; workstationGroupId: string }
        >({
            query: ({ workOrderId, workstationGroupId }) => ({
                url: workstationGroupId
                    ? `/workorder/${workOrderId}/workstationgroup/assign`
                    : `/workorder/${workOrderId}/workstationgroup/unassign`,
                method: 'POST',
                data: {
                    workstationGroupId,
                },
            }),
            invalidatesTags: (res, err, { workOrderId }) => [
                ApiTagType.Schedule,
                { type: ApiTagType.WorkOrder, id: workOrderId },
                ApiTagType.WorkItem,
                ApiTagType.WorkstationStep,
            ],
        }),

        workItemDetail: build.query<WorkOrderItemDetail, string>({
            query: id => ({
                url: `/workorderitem/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => WorkOrderItemDetailSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.WorkItem, id }],
        }),

        workflow: build.query<Workflow, string>({
            query: id => ({
                url: `/workflow/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => WorkflowSchema.parse(result),
            providesTags: (res, err, id) => [{ type: ApiTagType.Workflow, id }],
        }),

        workflowUpdate: build.mutation<void, Workflow>({
            query: model => ({
                url: `/workflow/${model.id}/update`,
                method: 'POST',
                data: model,
            }),
            transformResponse: () => undefined,
            invalidatesTags: (res, err, model) => [{ type: ApiTagType.Workflow, id: model.id }],
        }),
    }),
});

export default workOrdersApi;
