import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import AccountingConnectionStatus, {
    AccountingConnectionStatusDisplay,
} from 'features/accounting/enums/AccountingConnectionStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './AccountingConnectionStatusBadge.scss';

export default function AccountingConnectionStatusBadge({
    status,
    size = 'medium',
}: {
    status: AccountingConnectionStatus;
    size?: StatusPillSize;
}) {
    return (
        <StatusPill
            className={coalesceClassNames('AccountingConnectionStatusBadge', status)}
            size={size}
        >
            {AccountingConnectionStatusDisplay.display(status)}
        </StatusPill>
    );
}
