import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import StatusPill from 'components/StatusPill/StatusPill';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './InventoryMeasureStatusBadge.scss';

export default function InventoryMeasureStatusBadges({
    isDisplay,
    isArchived,
}: {
    isDisplay: boolean;
    isArchived: boolean;
}) {
    return (
        <>
            {isDisplay && (
                <StatusPill
                    className={coalesceClassNames(
                        'InventoryMeasureStatusBadge',
                        isDisplay && 'display',
                    )}
                    size="small"
                >
                    Display
                </StatusPill>
            )}
            {isArchived && (
                <>
                    {' '}
                    <ArchivedBadge size="small" />
                </>
            )}
        </>
    );
}
