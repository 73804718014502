import WorkItemFieldUnits from 'features/workOrders/enums/WorkItemFieldUnits';
import { WorkstationWorkItemField } from 'features/workOrders/models/WorkstationWorkItem';
import React, { useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import './WorkItemFieldDisplay.scss';

/** Display WorkstationWorkItemField value using unitSystem formatting */
export default function WorkItemFieldDisplay({ field }: { field: WorkstationWorkItemField }) {
    const valueWithUnits = useMemo(() => {
        if (Array.isArray(field.value)) {
            return field.value
                .filter(Boolean) // remove empty values
                .map(val => getFieldValueWithUnits(val, field.unitSystem))
                .join(', ');
        }
        return getFieldValueWithUnits(field.value, field.unitSystem);
    }, [field.unitSystem, field.value]);

    if (isEmpty(valueWithUnits) && field.hideIfEmpty) {
        return null;
    }

    return (
        <span className={coalesceClassNames('WorkItemFieldDisplay', field.appearance)}>
            {valueWithUnits}
        </span>
    );
}

const fractionLookup: Record<number, string> = {
    0.0625: '1/16',
    0.125: '1/8',
    0.1875: '3/16',
    0.25: '1/4',
    0.3125: '5/16',
    0.375: '3/8',
    0.4375: '7/16',
    0.5: '1/2',
    0.5625: '9/16',
    0.625: '5/8',
    0.6875: '11/16',
    0.75: '3/4',
    0.8125: '13/16',
    0.875: '7/8',
    0.9375: '15/16',
};

export function formatInches(floatValue?: number): string {
    if (floatValue === 0) {
        return '0';
    }

    if (floatValue) {
        const inches = Math.floor(floatValue);
        const decimal = floatValue % 1;
        const fraction = fractionLookup[decimal];

        if (fraction) {
            // Format using unicode fraction slash
            const [num, den] = fraction.split('/');
            return `${inches} ${num}\u2044${den}"`;
        }
        return `${inches}"`;
    }
    return `${floatValue}`;
}

function getFieldValueWithUnits(
    val: string | boolean | number | null | undefined,
    unitSystem: WorkItemFieldUnits,
): string {
    if (unitSystem === WorkItemFieldUnits.Millimetres) {
        return isEmpty(val) ? '' : `${val} mm`;
    }
    if (unitSystem === WorkItemFieldUnits.Inches) {
        const floatVal =
            typeof val === 'number' ? val : typeof val === 'string' ? parseFloat(val) : Number.NaN;

        if (!Number.isNaN(floatVal)) {
            return formatInches(floatVal);
        }
    }
    if (typeof val === 'boolean') {
        return val ? 'Yes' : 'No';
    }
    return `${val}`;
}
