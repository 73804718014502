import Icons from 'Icons';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { useBrandOptions } from 'features/customers/hooks/useBrandOptions';
import DocumentFileIcon from 'features/documents/components/DocumentFileIcon/DocumentFileIcon';
import documentsApi from 'features/documents/documents.api';
import DocumentFriendlyType, {
    DocumentFriendlyTypeDisplay,
} from 'features/documents/enums/DocumentFriendlyType';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import './DocumentDetailModal.scss';

export default function DocumentDetailModal({
    model,
    isLoading,
    isError,
    showBrand,
    onArchive,
    close,
}: {
    model?: DocumentFile;
    isLoading?: boolean;
    isError?: boolean;
    showBrand?: boolean;
    onArchive: (documentId: number) => Promise<any>;
    close?: () => void;
}) {
    const brandOptions = useBrandOptions();

    const [updateMutation, updateStatus] = documentsApi.useDocumentUpdateMutation();

    const handleSave = useCallback(
        async editModel => {
            await updateMutation(editModel);
        },
        [updateMutation],
    );

    const dialogManager = useDialogManager();
    const handleArchive = useCallback(async () => {
        if (model) {
            const confirm = await dialogManager.confirm({
                title: 'Archive document',
                message: (
                    <>
                        Are you sure you want to archive <em>"{model.name}"</em>?
                    </>
                ),
                acceptLabel: 'Yes, archive now',
                acceptButtonType: 'Danger',
            });

            if (confirm) {
                // call archive API
                await dialogManager.showLoadingWhile(onArchive(model.id));
                close?.();
            }
        }
    }, [onArchive, close, dialogManager, model]);

    return (
        <MyEditModal
            className="DocumentDetailModal"
            title={'Document'}
            titleContext={model?.name}
            subtitle={model?.isDealerOwned && 'This file is owned by the dealer account'}
            mobileTitle="Documents"
            close={close}
            model={model}
            onSave={handleSave}
            isSaving={updateStatus.isLoading}
            readonly={model?.isDealerOwned}
            isLoading={isLoading}
            isError={isError}
            fullHeight={false}
            headerMenuItems={
                model?.isDealerOwned
                    ? undefined
                    : [
                          {
                              label: 'Archive',
                              IconLeft: Icons.Archive,
                              onClick: handleArchive,
                          },
                      ]
            }
        >
            {({ editModel, isEditing, updateField, isSaving }) => (
                <>
                    <div className="DocumentDetailModal__Main">
                        <a
                            className="DocumentDetailModal__Link"
                            href={model?.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {editModel.type === DocumentFriendlyType.Image ? (
                                <span
                                    className="DocumentDetailModal__Link__Preview"
                                    style={{ backgroundImage: `url(${model?.url})` }}
                                />
                            ) : (
                                <DocumentFileIcon
                                    className="DocumentDetailModal__Link__DocumentFileIcon"
                                    type={editModel.type}
                                />
                            )}
                            <Icons.Download className="download-icon" />
                        </a>

                        <PropertyContainer
                            className="DocumentDetailModal__Main__PropertyContainer"
                            fullWidthInputs
                        >
                            <PropertyEditText
                                label="Name"
                                value={editModel.name}
                                onChange={val => updateField({ name: val })}
                                readonly={!isEditing}
                                disabled={isSaving}
                                validationRequired={true}
                            />

                            {showBrand && (
                                <PropertyDisplay
                                    label="Brand"
                                    value={`${editModel.brand_id ?? ''}`}
                                    options={brandOptions}
                                />
                            )}

                            <PropertyDisplay
                                label="File type"
                                value={DocumentFriendlyTypeDisplay.display(editModel.type)}
                            />

                            <PropertyDisplay
                                label="Created"
                                value={formatDateTimeRelative(editModel.created_at)}
                            />
                        </PropertyContainer>
                    </div>
                </>
            )}
        </MyEditModal>
    );
}
