import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import { YesNoEnumDisplay } from 'enums/YesNoEnum';
import { Location } from 'features/inventory/models/Location';
import React, { useState } from 'react';
import './LocationsTable.scss';

const FILTERS = FilterBuilder<Location>()
    .filter({
        label: 'Search',
        type: 'search',
        defaultValue: '',
        getFields: item => [item.name, item.locationTypeDisplay],
    })
    .filter({
        label: 'Can Store',
        type: 'select',
        options: YesNoEnumDisplay.options,
        defaultValue: '',
        className: 'LocationsTable__CanStoreFilter',
        getField: item => `${item.canStore}`,
    })
    .filter({
        label: 'Can Allocate',
        type: 'select',
        options: YesNoEnumDisplay.options,
        defaultValue: '',
        className: 'LocationsTable__CanAllocateFilter',
        getField: item => `${item.canAllocate}`,
    })
    .build();

const COLUMN_DEFS = ColumnBuilder<Location>()
    .column({
        label: 'Name',
        key: 'name',
        isSortable: true,
        getValue: item => item.name,
    })
    .column({
        label: 'Type',
        key: 'locationTypeDisplay',
        isSortable: true,
        getValue: item => item.locationTypeDisplay,
    })
    .column({
        label: 'Can Store',
        key: 'canStore',
        isSortable: true,
        align: 'center',
        getValue: item => item.canStore,
        renderValue: val => (val ? <Icons.Check /> : null),
    })
    .column({
        label: 'Can Allocate',
        key: 'canAllocate',
        isSortable: true,
        align: 'center',
        getValue: item => item.canAllocate,
        renderValue: val => (val ? <Icons.Check /> : null),
    })
    .build();

export default function LocationsTable({
    data,
    isLoading = false,
    isError = false,
    isRefreshing = false,
    onRefresh,
}: {
    data?: Location[];
    isLoading?: boolean;
    isError?: boolean;
    isRefreshing?: boolean;
    onRefresh?: () => void;
}) {
    const [filteredData, setFilteredData] = useState<Location[]>();

    return (
        <>
            {(data?.length ?? 0) > 0 && (
                <LayoutHeader>
                    <DataTableFilters
                        data={data}
                        filters={FILTERS}
                        onChange={setFilteredData}
                    />
                </LayoutHeader>
            )}

            <LayoutBody>
                <DataTable
                    className="LocationsTable__DataTable"
                    isLoading={isLoading}
                    isError={isError}
                    data={filteredData}
                    onRefresh={onRefresh}
                    isRefreshing={isRefreshing}
                    rowLinkTo={item => `/locations/${item.id}`}
                    zebra="light"
                    useStickyHeader={true}
                    useFrontEndSorting={true}
                    columns={COLUMN_DEFS}
                    rowEndIcon={<Icons.ChevronRight />}
                    emptyState="No locations found"
                />
            </LayoutBody>
        </>
    );
}
