import { dateTimeOldSystem } from 'utils/dateSchemas';
import { parseEnum } from 'utils/helpers';
import z from 'zod';
import DocumentFriendlyType, { getDocumentFriendlyType } from '../enums/DocumentFriendlyType';

export const DealerDocumentFileSchema = z
    .object({
        id: z.number(),
        type: z.string().transform(
            // Handle bad data from backend - sometimes type can be file extension instead
            val => parseEnum(DocumentFriendlyType, val) ?? getDocumentFriendlyType(`file.${val}`),
        ),
        name: z.string(),
        created_at: dateTimeOldSystem({ isUTC: true }),
        url: z.string(),
    })
    .transform(model => ({ ...model, isDealerOwned: true }));

export type DealerDocumentFile = z.infer<typeof DealerDocumentFileSchema>;
