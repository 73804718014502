import { dateOnly, dateTimeOldSystem } from 'utils/dateSchemas';
import { tryParseJson } from 'utils/helpers';
import z from 'zod';

const SalesOrderHistoryStateSchema = z.object({
    id: z.number(),
    customer_notes: z.string().nullable().optional(),
    eta: dateOnly({ allowBlank: true }).nullable().optional(),
    notes: z.string().nullable().optional(),
    order_status_id: z.number().nullable().optional(),
    total_freight_override: z.number({ coerce: true }).nullable().optional(),
    tracking_information: z.string().nullable().optional(),
});

export type SalesOrderHistoryState = z.infer<typeof SalesOrderHistoryStateSchema>;

// These are the attributes of a sales order that we want to display on the history view
export const salesOrderHistoryFieldLabels: Partial<Record<keyof SalesOrderHistoryState, string>> = {
    customer_notes: 'External notes',
    eta: 'ETA',
    notes: 'Internal notes',
    order_status_id: 'Status',
    total_freight_override: 'Total freight override',
    tracking_information: 'Tracking information',
};

export const SalesOrderHistoryEntrySchema = z
    .object({
        created_at: dateTimeOldSystem(),
        id: z.number(),
        logs: z.string(),
        manufacturer_employees_first_name: z.string(),
        manufacturer_employees_last_name: z.string(),
        new: z.string(), // stringified json obj
        old: z.string(), // stringified json obj
        type: z.enum(['order-dl', 'order-update']),
    })
    .transform(model => {
        const activityNew = SalesOrderHistoryStateSchema.parse(tryParseJson(model.new));
        const activityOld = SalesOrderHistoryStateSchema.parse(tryParseJson(model.old));

        return {
            ...model,
            manufacturer_employees_full_name: `${model.manufacturer_employees_first_name} ${model.manufacturer_employees_last_name}`,
            new: activityNew,
            old: activityOld,
        };
    });

export type SalesOrderHistoryEntry = z.infer<typeof SalesOrderHistoryEntrySchema>;
