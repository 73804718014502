import { dateTimeOldSystem } from 'utils/dateSchemas';
import { parseEnum } from 'utils/helpers';
import z from 'zod';
import DocumentFriendlyType, { getDocumentFriendlyType } from '../enums/DocumentFriendlyType';

export const ManufacturerDocumentFileSchema = z.object({
    id: z.number(),
    extension: z.string(),
    type: z.string().transform(
        // Handle bad data from backend - sometimes type can be file extension instead
        val => parseEnum(DocumentFriendlyType, val) ?? getDocumentFriendlyType(`file.${val}`),
    ),
    name: z.string(),
    manufacturer_id: z.number().nullable(),
    created_at: dateTimeOldSystem({ isUTC: true }),
    brand_id: z.number().nullable(),
    document_type: z.string(),
    url: z.string(),
});

export type ManufacturerDocumentFile = z.infer<typeof ManufacturerDocumentFileSchema>;
