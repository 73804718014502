import MyAutocompleteInput from 'components/MyAutocompleteInput/MyAutocompleteInput';
import inventoryApi from 'features/inventory/inventory.api';
import { Location } from 'features/inventory/models/Location';
import React, { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import { getLocationPath } from 'utils/locationHelpers';
import './LocationSelectInput.scss';

export default function LocationSelectInput({
    label,
    value,
    locations,
    onChange,
    disabled = false,
    validationRequired,
    validationCustom,
}: {
    label?: string;
    value: string;
    locations?: Location[];
    onChange?: (id: string, label?: string) => void;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
}) {
    // load all locations
    const locationsQuery = inventoryApi.useLocationListQuery();

    const locationOptions = useMemo(() => {
        const allLocations = locationsQuery.data?.data || [];
        const result = (locations || allLocations)
            .filter(l => l.canStore)
            .map(l => {
                const path = getLocationPath(l, allLocations)
                    .map(p => p.name)
                    .join(' / ');
                return {
                    label: path.length ? `${path} / ${l.name}` : l.name,
                    value: l.id,
                };
            });
        result.sort((a, b) => typescriptNaturalSort(a.label, b.label));
        return result;
    }, [locations, locationsQuery.data?.data]);

    return (
        <MyAutocompleteInput
            className="LocationSelectInput"
            fullWidth
            label={label}
            value={value}
            onChange={onChange}
            options={locationOptions}
            disabled={disabled}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
        />
    );
}
