import WorkstationMain from 'features/workstations/components/WorkstationMain/WorkstationMain';
import workstationsApi from 'features/workstations/workstations.api';
import {
    selectCurrentWorkstation,
    setCurrentWorkstation,
} from 'features/workstations/workstations.slice';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default function CurrentWorkstationPage() {
    // Instead of calling usePageTitle here, it is called within WorkstationMain
    const { stationId } = useParams();
    const workstationsQuery = workstationsApi.useGetWorkstationsQuery();

    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dialogManager = useDialogManager();

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.WorkOrder, ApiTagType.WorkItem, ApiTagType.WorkstationStep], {
        onMount: true,
    });

    // Set currentWorkstation when landing on this page
    // as long as station id is valid
    useEffect(() => {
        if (stationId !== currentWorkstation?.id) {
            if (workstationsQuery.data) {
                const station = workstationsQuery.data?.find(w => w.id === stationId);
                if (!station) {
                    // not a valid workstation id, redirect to the first workstation
                    navigate('/workstations', { replace: true });
                } else {
                    // found a valid workstation id, switch to it
                    // and show a toast that we've changed stations
                    setTimeout(() => {
                        dialogManager.toast({
                            title: 'Workstation changed',
                            message: (
                                <>
                                    Now working on <strong>{station.name}</strong>
                                </>
                            ),
                            icon: <Icons.Workstation color="#009eda" />, // $color-primary-base
                            displayTime: 8000,
                        });
                    }, 1);

                    dispatch(setCurrentWorkstation(station));
                }
            }
        }
    }, [dispatch, stationId, currentWorkstation, workstationsQuery.data, navigate, dialogManager]);

    return (
        <>
            {currentWorkstation?.id === stationId && <WorkstationMain />}
            <Outlet />
        </>
    );
}
