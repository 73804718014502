import z from 'zod';
import CustomerAccessType from '../enums/CustomerAccessType';
import CustomerType from '../enums/CustomerType';
import { CompanySchema } from './Company';
import { CustomerConfigFactory, CustomerConfigSchema } from './CustomerConfig';

export const CustomerSchema = z.object({
    id: z.number(),
    countryId: z.number(),
    accessType: z.nativeEnum(CustomerAccessType),
    context: z.object({
        mainCompany: CompanySchema.partial(),
        customerConfig: CustomerConfigSchema.default(CustomerConfigFactory.create),
    }),
});

export type Customer = z.infer<typeof CustomerSchema>;

export function getCustomerType({
    isInternal,
    taxApplicable,
}: {
    isInternal: boolean;
    taxApplicable: boolean;
}) {
    if (isInternal) {
        if (taxApplicable) {
            return CustomerType.InternalTaxable;
        }
        return CustomerType.InternalNonTaxable;
    }
    return CustomerType.External;
}
