import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import accountingApi from 'features/accounting/accounting.api';
import { AccountingConnectionTypeDisplay } from 'features/accounting/enums/AccountingConnectionType';
import { AccountingConnection } from 'features/accounting/models/AccountingConnection';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { ApiTagType } from 'services/api';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import AccountingConnectionStatusBadge from '../../../accounting/components/AccountingConnectionStatusBadge/AccountingConnectionStatusBadge';
import AcountingConnectionDetailModal from '../../../accounting/components/AcountingConnectionDetailModal/AcountingConnectionDetailModal';
import './SalesOrderAccountingTab.scss';

const COLUMNS = ColumnBuilder<AccountingConnection>()
    .column({
        label: 'Type',
        key: 'type',
        isSortable: true,
        getValue: item => item.type,
        renderValue: AccountingConnectionTypeDisplay.display,
    })
    .column({
        label: 'Requested',
        key: 'createdAt',
        whiteSpace: 'nowrap',
        isSortable: true,
        defaultSort: 'DESC',
        getValue: item => item.createdAt,
        renderValue: val => formatDateTimeRelative(val),
    })
    .column({
        label: 'Status',
        key: 'status',
        isSortable: true,
        getValue: item => item.status,
        renderValue: val => (
            <AccountingConnectionStatusBadge
                size="small"
                status={val}
            />
        ),
    })
    .column({
        label: 'Summary',
        key: 'summary',
        isSortable: true,
        getValue: item => item.summary,
    })
    .build();

export default function SalesOrderAccountingTab({
    manufacturerOrderId,
}: {
    manufacturerOrderId: number;
}) {
    const query = accountingApi.useSalesOrderAccountingQuery(manufacturerOrderId);
    const refreshData = useApiTagInvalidate([ApiTagType.SalesOrderAccounting]);

    const [detailId, setDetailId] = useState<string>();
    const detailModel = useMemo(
        () => (detailId ? query.data?.find(d => d.id === detailId) : undefined),
        [detailId, query.data],
    );

    const [createInvoiceMutation] = accountingApi.useSalesOrderInvoiceCreateMutation();
    const dialogManager = useDialogManager();
    const createInvoiceRequest = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Create invoice request',
            message: 'Are you sure you want to create a new invoice request for this order?',
            acceptLabel: 'Yes, create now',
        });

        if (confirm) {
            dialogManager.showLoadingWhile(createInvoiceMutation(manufacturerOrderId).unwrap());
        }
    }, [createInvoiceMutation, dialogManager, manufacturerOrderId]);

    return (
        <div className="SalesOrderAccountingTab">
            <div className="SalesOrderAccountingTab__Heading">
                <h2>Accounting connections</h2>
                <MyButton
                    label="Create invoice request"
                    IconLeft={Icons.Plus}
                    onClick={createInvoiceRequest}
                />
            </div>

            <DataTable
                className="SalesOrderAccountingTab__DataTable"
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                columns={COLUMNS}
                useFrontEndSorting={true}
                onRowClick={item => setDetailId(item.id)}
                onRefresh={refreshData}
                isRefreshing={query.isFetching}
                emptyState="No accounting records found"
            />

            {detailModel && (
                <AcountingConnectionDetailModal
                    model={detailModel}
                    isLoading={query.isFetching}
                    isError={query.isError}
                    close={() => setDetailId(undefined)}
                />
            )}
        </div>
    );
}
