import { generateShortUuid, mergeDeep } from 'utils/helpers';
import z from 'zod';
import WorkflowBreakdownConditionType from '../enums/WorkflowBreakdownConditionType';
import WorkflowBreakdownFieldSource from '../enums/WorkflowBreakdownFieldSource';
import WorkflowBreakdownNonMappedKeys from '../enums/WorkflowBreakdownNonMappedKeys';

const WorkflowItemSplitConditionSchema = z.object({
    type: z.nativeEnum(WorkflowBreakdownConditionType), // enum only MATCH for now
    source: z.nativeEnum(WorkflowBreakdownFieldSource),
    key: z.string(), // the source key, not used if value is set
    value: z.string().nullable(), // static value to apply to newKey
});

const WorkflowItemSplitFieldSchema = z.object({
    source: z.nativeEnum(WorkflowBreakdownFieldSource),
    key: z.string(), // the source key, not used if value is set
    newKey: z.string().optional(), // the output key
    value: z.string().nullable().optional(), // static value to apply to newKey
});

const WorkflowItemSplitMappingSchema = z.object({
    id: z.string(),
    type: z.string(), // duplicate of id for now?
    fields: z.array(WorkflowItemSplitFieldSchema),
});

export const WorkflowItemSplitConfigSchema = z
    .object({
        id: z.string().optional(),
        type: z.string(), // name field e.g "DUAL",
        settings: z.object({
            nonMappedKeys: z.nativeEnum(WorkflowBreakdownNonMappedKeys),
            globals: z.array(WorkflowItemSplitFieldSchema),
        }),
        conditions: z.array(WorkflowItemSplitConditionSchema),
        mapping: z.array(WorkflowItemSplitMappingSchema),
    })
    .transform(data => {
        // id's are generated by the FE just for keying and are not used by the BE
        // these are volatile and can't be relied on for anything serious
        data.id = data.id ?? generateShortUuid();
        return data;
    });

export type WorkflowItemSplitCondition = z.infer<typeof WorkflowItemSplitConditionSchema>;
export type WorkflowItemSplitField = z.infer<typeof WorkflowItemSplitFieldSchema>;
export type WorkflowItemSplitMapping = z.infer<typeof WorkflowItemSplitMappingSchema>;
export type WorkflowItemSplitConfig = z.infer<typeof WorkflowItemSplitConfigSchema>;

export class WorkflowItemSplitConfigFactory {
    static create(data: Partial<WorkflowItemSplitConfig>) {
        const defaults: WorkflowItemSplitConfig = {
            id: generateShortUuid(),
            type: '',
            conditions: [
                {
                    key: '',
                    source: WorkflowBreakdownFieldSource.Option,
                    type: WorkflowBreakdownConditionType.Match,
                    value: '',
                },
            ],
            mapping: [],
            settings: {
                globals: [],
                nonMappedKeys: WorkflowBreakdownNonMappedKeys.Omit,
            },
        };

        const mergedData = mergeDeep(defaults, data);

        return WorkflowItemSplitConfigSchema.parse(mergedData);
    }
}
