import { faker } from '@faker-js/faker';
import WorkstationPrintMethod from '../enums/WorkstationPrintMethod';
import WorkstationPrintOption from '../enums/WorkstationPrintOption';
import { Workstation } from '../models/Workstation';

faker.seed(42);
let fakeWorkstationIndex = 0;
const workstationNames = [
    'Assembly station X',
    'Axel Cutting Red',
    'Axel Cutting Blue',
    'Axel Cutting QA #6',
    'Axel Cutting QA #7',
    'Axel Foley',
    'Fabric Cutting Station A',
    'Fabric Cutting Station B',
    'Fabric Cutting Station C',
];

const generateWorkstation = (): Workstation => {
    const result: Workstation = {
        id: faker.string.uuid(),
        name: workstationNames[fakeWorkstationIndex % workstationNames.length],
        allowMultipleInProgress: false,
        printOption: WorkstationPrintOption.ExpressOnComplete,
        printMethod: WorkstationPrintMethod.Open,
    };
    fakeWorkstationIndex++;
    return result;
};

export const fakeWorkstations = faker.helpers.multiple(generateWorkstation, { count: 9 });
