import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import { ShipmentStatusDisplay } from 'features/dispatch/enums/ShipmentStatus';
import { ShipmentEntity } from 'features/dispatch/models/ShipmentEntity';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ShipmentStatusBadge.scss';

export default function ShipmentStatusBadge({
    shipment,
    size = 'medium',
}: {
    shipment?: ShipmentEntity;
    size?: StatusPillSize;
}) {
    return (
        <StatusPill
            className={coalesceClassNames('ShipmentStatusBadge', shipment?.status)}
            size={size}
        >
            {shipment && ShipmentStatusDisplay.display(shipment.status)}
        </StatusPill>
    );
}
