import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton from 'components/MyButton/MyButton';
import MyMenuButton from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import InventoryReceiptStatus from 'features/inventory/enums/InventoryReceiptStatus';
import { useWarehouseOptions } from 'features/inventory/hooks/useWarehouseOptions';
import inventoryApi from 'features/inventory/inventory.api';
import {
    InventoryReceiptDetail,
    InventoryReceiptErrorDisplay,
    validateInventoryReceiptDetail,
    validateInventoryReceiptInventory,
} from 'features/inventory/models/InventoryReceiptDetail';
import {
    InventoryReceiptItem,
    InventoryReceiptLocation,
} from 'features/inventory/models/InventoryReceiptInventory';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useState } from 'react';
import { formatDateRelative } from 'utils/dateHelpers';
import { InventoryReceiptAddItemModal } from '../InventoryReceiptAddItemModal/InventoryReceiptAddItemModal';
import { InventoryReceiptEditModal } from '../InventoryReceiptEditModal/InventoryReceiptEditModal';
import { InventoryReceiptItemLocationModal } from '../InventoryReceiptItemLocationModal/InventoryReceiptItemLocationModal';
import InventoryReceiptStatusBadges from '../InventoryReceiptStatusBadge/InventoryReceiptStatusBadge';
import './InventoryReceiptDetailModal.scss';

const COLUMNS = ColumnBuilder<InventoryReceiptItem>()
    .column({
        label: 'Item',
        key: 'description',
        isSortable: true,
        getValue: inventory => inventory.context.inventory.description,
        renderValue: (val, item) => {
            const error = validateInventoryReceiptInventory(item);
            return (
                <div>
                    <div className="InventoryReceiptDetailModal__Table__Description">{val}</div>
                    <div className="InventoryReceiptDetailModal__Table__LocationWrapper">
                        {item.locationAssignments.map((l: InventoryReceiptLocation) => (
                            <div
                                key={l.locationId}
                                className="InventoryReceiptDetailModal__Table__Location"
                            >
                                <span className="InventoryReceiptDetailModal__Table__LocationPath">
                                    {l.context.path.map((p, i) => (
                                        <span key={p.id}>
                                            {i > 0 && (
                                                <Icons.ChevronRight className="InventoryReceiptDetailModal__Table__LocationPath__Icon" />
                                            )}
                                            {p.name}
                                        </span>
                                    ))}
                                </span>
                                <div className="InventoryReceiptDetailModal__Table__Quantity">
                                    {l.quantity}
                                </div>
                            </div>
                        ))}
                    </div>
                    {error && (
                        <div className="InventoryReceiptDetailModal__Table__Error">
                            <Icons.Warning className="InventoryReceiptDetailModal__Table__Error__Icon" />
                            {InventoryReceiptErrorDisplay.display(error)}
                        </div>
                    )}
                </div>
            );
        },
    })
    .column({
        label: 'Total Quantity',
        key: 'quantity',
        isSortable: true,
        align: 'right',
        getValue: inventory => inventory.totalQuantity,
    })

    .build();

export default function InventoryReceiptDetailModal({
    model,
    isLoading = false,
    isError = false,
    close,
}: {
    model?: InventoryReceiptDetail;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [archiveQuery] = inventoryApi.useInventoryReceiptArchiveMutation();
    const [unarchiveQuery] = inventoryApi.useInventoryReceiptUnarchiveMutation();
    const dialogManager = useDialogManager();

    const handleArchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: 'Are you sure you want to archive this record?',
            acceptLabel: 'Yes, archive it',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(archiveQuery(model.id).unwrap());
            close?.();
        }
    }, [close, archiveQuery, dialogManager, model]);

    const handleUnarchive = useCallback(async () => {
        if (model) {
            await unarchiveQuery(model.id);
        }
    }, [unarchiveQuery, model]);

    const warehouseOptions = useWarehouseOptions();

    const [saveMutation, saveMutationState] = inventoryApi.useInventoryReceiptUpdateMutation();
    const [processMutation, processMutationState] =
        inventoryApi.useInventoryReceiptProcessMutation();

    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    const [selectedInventory, setSelectedInventory] = useState<InventoryReceiptItem[]>([]);
    const [splitItemId, setSplitItemId] = useState<string>();
    const splitItem = model?.inventory.find(i => i.id === splitItemId);

    const removeSelectedInventory = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Remove selected inventory',
            message: `Are you sure you want to remove ${selectedInventory.length} row${
                selectedInventory.length !== 1 ? 's' : ''
            } from this inventory receipt? This cannot be undone, and locations will need to be reassigned.`,
            acceptLabel: 'Yes, remove',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            const updatedInventory = model.inventory.filter(
                i => !selectedInventory.find(s => s.id === i.id),
            );
            await saveMutation({ ...model, inventory: updatedInventory ?? [] }).unwrap();
            setSelectedInventory([]);
        }
    }, [dialogManager, model, saveMutation, selectedInventory]);

    return (
        <MyModal
            className="InventoryReceiptDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Receiving"
            header={
                <PageHeader
                    title="Inventory Receipt"
                    titleContext={model?.tuid}
                >
                    {model?.status === InventoryReceiptStatus.Draft && !model.isArchived && (
                        <>
                            <MyButton
                                label="Process"
                                buttonType="Primary"
                                isLoading={processMutationState.isLoading}
                                disabled={
                                    saveMutationState.isLoading ||
                                    !validateInventoryReceiptDetail(model)
                                }
                                onClick={() => processMutation(model.id)}
                            />
                        </>
                    )}
                    <MyMenuKebabButton
                        menuItems={[
                            model?.status === InventoryReceiptStatus.Draft &&
                                !model.isArchived && {
                                    label: 'Edit',
                                    IconLeft: Icons.Edit,
                                    onClick: () => setIsEditing(true),
                                },
                            model?.isArchived === false && {
                                label: 'Archive',
                                IconLeft: Icons.Archive,
                                onClick: handleArchive,
                            },
                            model?.isArchived === true && {
                                label: 'Unarchive',
                                IconLeft: Icons.Undo,
                                onClick: handleUnarchive,
                            },
                        ]}
                    />
                </PageHeader>
            }
        >
            {model && (
                <>
                    {!validateInventoryReceiptDetail(model) && (
                        <MessagePanel
                            messageType="warning"
                            className="InventoryReceiptDetailModal__MessagePanel"
                        >
                            One or more items have issues. Processing is unavailable until they are
                            resolved.
                        </MessagePanel>
                    )}
                    <PropertyContainer layout="table">
                        <PropertyDisplay
                            label="Warehouse"
                            value={model.locationId}
                            options={warehouseOptions}
                        />

                        <PropertyDisplay
                            label="Date Received"
                            value={formatDateRelative(model.dateReceived)}
                        />

                        <PropertyDisplay
                            label="Status"
                            value={
                                <InventoryReceiptStatusBadges
                                    status={model.status}
                                    isArchived={model.isArchived}
                                />
                            }
                        />

                        <PropertyDisplay
                            label="Notes"
                            verticalAlign="top"
                            value={model.notes}
                        />
                    </PropertyContainer>
                    <>
                        <div className="InventoryReceiptDetailModal__Toolbar">
                            <div>
                                {selectedInventory.length > 0 && (
                                    <MyMenuButton
                                        className="InventoryReceiptDetailModal__Toolbar__BulkActionMenuButton"
                                        buttonLabel={
                                            <>
                                                {selectedInventory.length}{' '}
                                                {selectedInventory.length === 1 ? 'row' : 'rows'}{' '}
                                                selected
                                            </>
                                        }
                                        buttonType="Secondary"
                                        IconRight={Icons.CaretDown}
                                        menuItems={[
                                            {
                                                label: 'Remove inventory',
                                                IconLeft: Icons.Archive,
                                                onClick: removeSelectedInventory,
                                            },
                                        ]}
                                    />
                                )}
                            </div>

                            {!model.isArchived && model.status === InventoryReceiptStatus.Draft && (
                                <MyButton
                                    label="Add items"
                                    IconLeft={Icons.Plus}
                                    buttonType="Accent"
                                    onClick={() => setIsAdding(true)}
                                />
                            )}
                        </div>
                        {model.inventory.length > 0 && (
                            <DataTable
                                className="InventoryReceiptDetailModal__Table"
                                data={model.inventory}
                                columns={COLUMNS}
                                zebra="light"
                                canSelectRows={
                                    !model.isArchived &&
                                    model.status === InventoryReceiptStatus.Draft
                                }
                                onRowSelectChanged={setSelectedInventory}
                                onRowClick={
                                    model.status === InventoryReceiptStatus.Draft
                                        ? row => setSplitItemId(row.id)
                                        : undefined
                                }
                                rowClass={item =>
                                    validateInventoryReceiptInventory(item)
                                        ? ''
                                        : 'InventoryReceiptDetailModal__Table__DataRow--error'
                                }
                            />
                        )}
                    </>
                </>
            )}
            {model && isAdding && (
                <InventoryReceiptAddItemModal
                    model={model}
                    onClose={() => setIsAdding(false)}
                    parentLocationId={model.locationId}
                />
            )}
            {model && isEditing && (
                <InventoryReceiptEditModal
                    model={model}
                    onClose={() => setIsEditing(false)}
                />
            )}
            {model && splitItem && (
                <InventoryReceiptItemLocationModal
                    model={model}
                    item={splitItem}
                    onClose={() => setSplitItemId(undefined)}
                    parentLocationId={model.locationId}
                />
            )}
        </MyModal>
    );
}
