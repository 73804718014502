import { rest } from 'msw';

import Env from 'config/Env';
import { fakeCountSheetSummaries, fakeCountSheets, fakeStocktakes } from './faker/StocktakeFaker';
import { StocktakeDiscrepancy } from './models/StocktakeDiscrepancy';

const BASE_URL = `${Env.API_BASE_URL}`;

export const stocktakeHandlers = [
    rest.post(`${BASE_URL}/stocktake`, (req, res, ctx) => {
        const data = fakeStocktakes.filter(
            s => s.isArchived === (req.body as any).meta.includeArchived,
        );
        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                data,
                total: data.length,
                schemaExtensions: {
                    udfs: [],
                },
            }),
        );
    }),

    rest.get(`${BASE_URL}/stocktake/:id/detail`, (req, res, ctx) => {
        const data = fakeStocktakes.find(s => s.id === req.params.id);
        return res(
            ctx.delay(500),
            ctx.status(data ? 200 : 400),
            ctx.json({
                data,
                schemaExtensions: {
                    udfs: [],
                },
            }),
        );
    }),

    rest.get(`${BASE_URL}/stocktake/:id/discrepancies`, (req, res, ctx) => {
        const data: StocktakeDiscrepancy[] = [];
        return res(ctx.delay(500), ctx.status(data ? 200 : 400), ctx.json(data));
    }),

    rest.get(`${BASE_URL}/stocktake/:id/countsheetstatuses`, (req, res, ctx) => {
        const data = fakeCountSheetSummaries.filter(s => s.stocktakeId === req.params.id);
        return res(
            ctx.delay(500),
            ctx.status(200),
            ctx.json({
                data,
                total: data.length,
            }),
        );
    }),

    rest.get(`${BASE_URL}/stocktake/:stocktakeId/countsheet/:countSheetKey`, (req, res, ctx) => {
        const data = fakeCountSheets.find(
            cs => cs.stocktakeId === req.params.stocktakeId && cs.key === req.params.countSheetKey,
        );
        return res(ctx.delay(500), ctx.status(data ? 200 : 400), ctx.json(data));
    }),

    rest.post(`${BASE_URL}/stocktake/:stocktakeId/countsheet/:key`, (req, res, ctx) => {
        const body = req.body as {
            id: string;
            key: string;
            stocktakeId: string;
            inventory: {
                tenantInventoryId: string;
                countedQuantity: number | null;
            }[];
        };

        const countSheet = fakeCountSheets.find(
            cs => cs.stocktakeId === body.stocktakeId && cs.key === body.key,
        );
        if (countSheet) {
            countSheet.inventory.forEach(inv => {
                const i = body.inventory.find(x => x.tenantInventoryId === inv.tenantInventoryId);
                if (i) {
                    inv.countedQuantity = i.countedQuantity ?? undefined;
                }
            });
        }
        return res(ctx.delay(500), ctx.status(200));
    }),

    rest.post(`${BASE_URL}/stocktake/:id/import`, (req, res, ctx) => {
        return res(
            ctx.delay(500),
            ctx.status(400),
            ctx.json({
                type: 'BadRequest',
                description: 'only a single file can be uploaded',
                code: 'StocktakeExcelError',
                reason: `"QtyBlank" only when excel file has an error in it`,
            }),
        );
    }),
];
