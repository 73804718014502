import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkflowBreakdownConditionType {
    Match = 'MATCH',
    LessThan = 'LT',
    LessThanOrEqualTo = 'LTE',
    GreaterThan = 'GT',
    GreaterThanOrEqualTo = 'GTE',
    NotEqualTo = 'NE',
}
export default WorkflowBreakdownConditionType;

const labels: EnumDisplayLabels<typeof WorkflowBreakdownConditionType> = {
    Match: 'Match',
    LessThan: '<',
    LessThanOrEqualTo: '<=',
    GreaterThan: '>',
    GreaterThanOrEqualTo: '>=',
    NotEqualTo: '!=',
};

export const WorkflowBreakdownConditionTypeDisplay = createDisplayEnum(
    WorkflowBreakdownConditionType,
    labels,
);
