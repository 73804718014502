import DataTablePaging from 'components/DataTable/DataTablePaging';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import {
    salesOrderHistoryFieldLabels,
    SalesOrderHistoryState,
} from 'features/sales/models/SalesOrderHistoryEntry';
import salesApi, { SalesOrderHistoryParams } from 'features/sales/sales.api';
import useDynamicQueryParams from 'hooks/useDynamicQueryParams';
import React, { useMemo } from 'react';
import { isEmpty } from 'utils/helpers';
import HistoryEntryDisplay from '../HistoryEntryDisplay/HistoryEntryDisplay';
import SalesOrderStatusBadge from '../SalesOrderStatusBadge/SalesOrderStatusBadge';
import './SalesOrderHistoryTab.scss';

export default function SalesOrderHistoryTab({
    manufacturerOrderId,
}: {
    manufacturerOrderId: number;
}) {
    const [queryParams, , , paging, setPaging] = useDynamicQueryParams<SalesOrderHistoryParams>(
        {
            manufacturerOrderId,
        },
        {
            pageSize: 20,
        },
    );
    const query = salesApi.useSalesOrderHistoryQuery(queryParams);

    /** Map SalesOrderHistoryEntry to HistoryEntryDisplay params */
    const historyEntries = useMemo(() => {
        return query.data?.data.map(entry => {
            const entries = Object.entries(entry.new).filter(([k, value]) => {
                const key = k as keyof SalesOrderHistoryState;
                const oldVal = entry.old[key];
                return oldVal !== value && !!salesOrderHistoryFieldLabels[key];
            });

            const log = entry.type === 'order-dl' ? entry.logs : null;

            // dont bother computing changedFields if we're displaying log
            const changedFields = log
                ? []
                : entries.map(([key, value]) => ({
                      label:
                          salesOrderHistoryFieldLabels[key as keyof SalesOrderHistoryState] ?? '',
                      displayValue:
                          key === 'order_status_id' ? (
                              <SalesOrderStatusBadge
                                  statusId={value as number}
                                  size="small"
                              />
                          ) : (
                              value
                          ),
                  }));

            return {
                id: entry.id,
                log,
                changedFields,
                updatedBy: entry.manufacturer_employees_full_name,
                timestamp: entry.created_at,
            };
        });
    }, [query.data]);

    return (
        <div className="SalesOrderHistoryTab">
            {query.isLoading ? (
                <div className="SalesOrderHistoryTab__Loading">
                    <MyLinearProgress />
                </div>
            ) : query.isError ? (
                <ErrorContent />
            ) : isEmpty(historyEntries) ? (
                <div className="SalesOrderHistoryTab__SectionEmpty">No history entries found</div>
            ) : (
                historyEntries?.map(entry => (
                    <HistoryEntryDisplay
                        key={entry.id}
                        changedFields={entry.changedFields}
                        log={entry.log}
                        updatedBy={entry.updatedBy}
                        timestamp={entry.timestamp}
                    />
                ))
            )}

            {(query.data?.data.length ?? 0) > 0 && (
                <DataTablePaging
                    data={paging}
                    totalCount={query.data?.total}
                    onChange={setPaging}
                />
            )}
        </div>
    );
}
