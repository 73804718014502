import { ScheduleEntitySchema } from 'features/schedule/models/ScheduleEntity';
import { ScheduleMetaEntitySchema } from 'features/schedule/models/ScheduleMetaEntity';
import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import { WorkOrderEntitySchema } from 'features/workOrders/models/WorkOrderEntity';
import z from 'zod';
import { SalesOrderWorkItemStepSchema } from './SalesOrderWorkItemStep';

export const SalesOrderWorkItemSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    description: z.string(),
    context: z.object({
        workOrderItemState: z.nativeEnum(WorkItemStatus),
        workOrderItemSteps: z.array(SalesOrderWorkItemStepSchema).optional(),
        schedule: ScheduleEntitySchema.optional(),
        scheduleMeta: ScheduleMetaEntitySchema.optional(),
        workOrder: WorkOrderEntitySchema.optional(),
    }),
});

export type SalesOrderWorkItem = z.infer<typeof SalesOrderWorkItemSchema>;
