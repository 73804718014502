import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import { Package } from 'features/dispatch/models/Package';
import LocationDisplay from 'features/inventory/components/LocationDisplay/LocationDisplay';
import WorkItemStatusBadge from 'features/workOrders/components/WorkItemStatusBadge/WorkItemStatusBadge';
import { WorkOrderItemDetail } from 'features/workOrders/models/WorkOrderItemDetail';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import PackageEditModal from '../PackageEditModal/PackageEditModal';
import './PackageDetailModal.scss';

export default function PackageDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: Package;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const dialogManager = useDialogManager();

    const edit = useCallback(() => {
        dialogManager.custom(PackageEditModal, { model });
    }, [dialogManager, model]);

    return (
        <MyModal
            className="PackageDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle="Packages"
            header={
                <PageHeader
                    title="Package"
                    titleContext={model?.tuid}
                >
                    <MyButton
                        label="Edit"
                        IconLeft={Icons.Edit}
                        buttonType="Accent"
                        onClick={edit}
                    />
                </PageHeader>
            }
        >
            {model && (
                <>
                    <PropertyContainer layout="table">
                        <PropertyDisplay
                            label="Description"
                            value={model.description}
                        />

                        <PropertyDisplay
                            label="Location"
                            value={
                                <LocationDisplay
                                    locationId={model.locationId}
                                    isLink={true}
                                />
                            }
                        />

                        <PropertyDisplay
                            label="Dimensions"
                            value={model.dimensions}
                        />

                        <PropertyDisplay
                            label="Weight"
                            value={model.weight}
                        />

                        <PropertyDisplay
                            label="Notes"
                            value={model.notes}
                        />
                    </PropertyContainer>

                    <WorkItemsTab packageModel={model} />
                </>
            )}
        </MyModal>
    );
}

const WORK_ITEM_COLUMNS = ColumnBuilder<WorkOrderItemDetail>()
    .column({
        label: 'Work item',
        key: 'tuid',
        getValue: item => item.tuid,
        isSortable: true,
    })
    .column({
        label: 'Work order',
        key: 'tuid',
        getValue: item => item.context.workOrder.tuid,
        isSortable: true,
    })
    .column({
        label: 'Description',
        key: 'description',
        getValue: item => item.description,
        isSortable: true,
    })
    .column({
        label: 'Status',
        key: 'workOrderItemState',
        isSortable: true,
        getValue: item => item.context.workOrderItemState,
        renderValue: val => (
            <div className="ShipmentDetailModal__WorkOrdersTab__WorkItemStatusCell">
                <WorkItemStatusBadge status={val} />
            </div>
        ),
    })
    .build();

function WorkItemsTab({ packageModel }: { packageModel: Package }) {
    const query = useMemo(
        () => ({
            data: packageModel.context.assignedWorkOrderItems,
            shipment: packageModel,
            isLoading: false,
            isError: false,
            isFetching: false,
            refetch: () => {},
        }),
        [packageModel],
    );
    return (
        <>
            <h2 className="PackageDetailModal__WorkItemsTab__Title">Work Items</h2>
            <p className="PackageDetailModal__WorkItemsTab__Description">
                Work items included in this package.
            </p>
            <DataTable
                className="PackageDetailModal__WorkItemsTab__DataTable"
                data={query.data}
                columns={WORK_ITEM_COLUMNS}
                emptyState={<>&ndash;</>}
            />
        </>
    );
}
