import z from 'zod';

export const SalesOrderLineStatusSchema = z.object({
    id: z.number(),
    name: z.string(),
    backgroundColor: z.string(),
    textColor: z.string(),
    isActive: z.boolean(),
    sortOrder: z.number(),
});

export type SalesOrderLineStatus = z.infer<typeof SalesOrderLineStatusSchema>;
