import { faker } from '@faker-js/faker';
import { DateTime } from 'luxon';
import { Schedule } from '../models/Schedule';

faker.seed(42);

export function generateFakeSchedule(date: DateTime): Schedule {
    return {
        id: faker.string.uuid(),
        date: date.toISODate() as string,
        context: {
            // cant populate these here because it would create a circular reference
            // poulating is done in schedule.mocks instead
            scheduleWorkOrders: [],
        },
    };
}

const dtFirst = DateTime.now().startOf('day').minus({ days: 4 });
const dates = [] as DateTime[];
for (let i = 0; i < 60; i++) {
    const dt = dtFirst.plus({ days: i });
    // dont include weekends
    if (dt.weekday < 6) {
        dates.push(dt);
    }
}

export const fakeSchedules = dates.map(d => generateFakeSchedule(d));
