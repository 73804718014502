import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import StocktakeStatus, { StocktakeStatusDisplay } from 'features/stocktake/enums/StocktakeStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './StocktakeStatusBadge.scss';

export default function StocktakeStatusBadge({
    status,
    isArchived,
    size = 'small',
}: {
    status: StocktakeStatus;
    isArchived?: boolean;
    size?: StatusPillSize;
}) {
    return (
        <>
            <StatusPill
                className={coalesceClassNames('StocktakeStatusBadge', status)}
                size={size}
            >
                {StocktakeStatusDisplay.display(status)}
            </StatusPill>
            {isArchived && (
                <>
                    {' '}
                    <ArchivedBadge size={size} />
                </>
            )}
        </>
    );
}
