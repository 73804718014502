import Icons from 'Icons';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton, { MyButtonLink, MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import Env from 'config/Env';
import InventoryTable from 'features/inventory/components/InventoryTable/InventoryTable';
import inventoryApi from 'features/inventory/inventory.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import useFileUploader from 'hooks/useFileUploader';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './InventoryPage.scss';

export default function InventoryPage() {
    usePageTitle('Inventory');
    const exportUrl = `${Env.API_BASE_URL}/inventory/export`;
    const [importQuery, importQueryState] = inventoryApi.useInventoryImportMutation();

    const fileUploader = useFileUploader(file => importQuery(file).unwrap());

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.Inventory], {
        onMount: true,
    });

    return (
        <>
            <LayoutHeader>
                <PageHeader title="Inventory">
                    <div className="InventoryPage__Actions">
                        <MyButton
                            buttonType="Accent"
                            size="medium"
                            label="Add Inventory"
                            IconLeft={Icons.Plus}
                            href="add"
                            LinkComponent={MyButtonLink}
                            disabled={importQueryState.isLoading}
                        />
                        <MyButton
                            buttonType="Secondary"
                            size="medium"
                            label="Import"
                            IconLeft={Icons.Upload}
                            onClick={fileUploader.onClick}
                            isLoading={importQueryState.isLoading}
                        />
                        <MyButton
                            buttonType="Secondary"
                            size="medium"
                            label="Export"
                            IconLeft={Icons.Export}
                            href={exportUrl}
                            LinkComponent={MyButtonLinkNewTab}
                            disabled={importQueryState.isLoading}
                        />
                    </div>
                </PageHeader>
            </LayoutHeader>

            <InventoryTable />

            <fileUploader.HiddenFileInput />

            <Outlet />
        </>
    );
}
