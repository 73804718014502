import Icons from 'Icons';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import InventoryReceiptTable from 'features/inventory/components/InventoryReceiptTable/InventoryReceiptTable';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './InventoryReceivingPage.scss';

export default function InventoryReceivingPage() {
    usePageTitle('Receiving');

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.InventoryReceipt], { onMount: true });

    return (
        <>
            <LayoutHeader>
                <PageHeader title="Receiving">
                    <div className="InventoryReceivingPage__Actions">
                        <MyButton
                            buttonType="Accent"
                            size="medium"
                            label="New Inventory Receipt"
                            IconLeft={Icons.Plus}
                            href="add"
                            LinkComponent={MyButtonLink}
                        />
                    </div>
                </PageHeader>
            </LayoutHeader>

            <InventoryReceiptTable />

            <Outlet />
        </>
    );
}
