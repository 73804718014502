import { DateTime } from 'luxon';

export const parseDate = (date: string | DateTime | Date) => {
    if (date instanceof DateTime) {
        // already parsed
        return date;
    }
    if (date instanceof Date) {
        return DateTime.fromJSDate(date);
    }
    return DateTime.fromISO(date);
};

/** Format time with lowercase am/pm */
export const formatTime = (date?: string | DateTime | Date) => {
    if (!date) {
        return '';
    }
    const dt = parseDate(date);
    return dt.toFormat('h:mma').toLowerCase();
};

/** Format datetime into friendly format e.g "20 mins ago" */
export const formatDateTimeFriendlyRelative = (date?: string | DateTime | Date) => {
    if (!date) {
        return '';
    }
    const dt = parseDate(date);
    return dt.toRelative();
};

/** Format date simple universal formatting with Today/Tomorrow/Yesterday or short day name and date
 * eg. "Today", "Tomorrow", "Fri 7 Jul", "Thurs 7 Jul 2029" */
export const formatDateRelative = (
    date?: string | DateTime | Date,
    options?: {
        /** Prevent returning names like 'yesterday' or 'tomorrow' and always give a date */
        alwaysDate?: boolean;
        hideDayName?: boolean;
    },
) => {
    if (!date) {
        return '';
    }
    const dt = parseDate(date);
    if (!options?.alwaysDate) {
        if (dt.hasSame(DateTime.now().minus({ days: 1 }), 'day')) {
            // yesterday
            return `Yesterday`;
        }
        if (dt.hasSame(DateTime.now(), 'day')) {
            // today
            return `Today`;
        }
        if (dt.hasSame(DateTime.now().plus({ days: 1 }), 'day')) {
            // tomorrow
            return `Tomorrow`;
        }
    }
    const dateFormat = options?.hideDayName ? 'd MMM' : 'EEE d MMM';
    if (dt.hasSame(DateTime.now(), 'year')) {
        return `${dt.toFormat(dateFormat)}`;
    }
    return `${dt.toFormat(`${dateFormat} yyyy`)}`;
};

/** Format date simple universal formatting with Today/Tomorrow/Yesterday or short day name and date plus time of day
 * eg. "Today, 5:00pm", "Fri 7 Jul, 5:00pm" */
export const formatDateTimeRelative = (
    date: string | DateTime | Date,
    options?: {
        /** Prevent returning names like 'yesterday' or 'tomorrow' and always give a date */
        alwaysDate?: boolean;
        hideDayName?: boolean;
    },
) => {
    const dt = parseDate(date);
    const dateString = formatDateRelative(dt, options);
    const timeStr = `${formatTime(dt)}`;
    return `${dateString}, ${timeStr}`;
};

export type formatDateRangeOptions = {
    includeYear?: boolean;
};

export const formatDateRange = (
    from: DateTime | string,
    to: DateTime | string,
    opts?: formatDateRangeOptions,
) => {
    const dateFrom = from instanceof DateTime ? from : DateTime.fromISO(from);
    const dateTo = to instanceof DateTime ? to : DateTime.fromISO(to);

    let result = '';

    if (dateFrom.hasSame(dateTo, 'year')) {
        if (dateFrom.hasSame(dateTo, 'month')) {
            if (dateFrom.hasSame(dateTo, 'day')) {
                // start and end are same day, just show date
                result = dateFrom.toFormat('d LLL yyyy');
            } else {
                // start and end are in same month, show day range
                // e.g 1 - 4 Feb
                result = `${dateFrom.toFormat('d')} - ${dateTo.toFormat('d MMM')}`;
            }
        } else {
            // start and end are in different months of the same year
            // e.g 1 Jan - 4 Feb
            result = `${dateFrom.toFormat('d MMM')} - ${dateTo.toFormat('d MMM')}`;
        }

        if (opts?.includeYear || !dateTo.hasSame(DateTime.now(), 'year')) {
            // dates are in the same year, but not this year
            // display year as well
            result = `${result} ${dateTo.toFormat('yy')}`;
        }
    } else {
        // start and end are in different years, show full dates
        result = `${dateFrom.toFormat('d MMM yy')} - ${dateTo.toFormat('d MMM yy')}`;
    }
    return result;
};

export const isBetweenDates = (
    date: DateTime | string, // the date to compare
    minDate?: DateTime | string, // start date (inclusive) - leave blank for no minimum
    maxDate?: DateTime | string, // end date (inclusive) - leave blank for no max
) => {
    const dt = date instanceof DateTime ? date : DateTime.fromISO(date);

    if (minDate) {
        const min = minDate instanceof DateTime ? minDate : DateTime.fromISO(minDate);

        if (min.diff(dt).as('milliseconds') > 0) {
            return false;
        }
    }

    if (maxDate) {
        const max = maxDate instanceof DateTime ? maxDate : DateTime.fromISO(maxDate);

        if (max.diff(dt).as('milliseconds') < 0) {
            return false;
        }
    }

    return true;
};

/** Returns true if date is in the past */
export const dateIsPast = (date?: DateTime | string) => {
    if (!date) {
        return false;
    }
    const dt = date instanceof DateTime ? date : DateTime.fromISO(date);
    return dt.diffNow().as('milliseconds') < 0;
};

/** Returns true if date is today */
export const dateIsToday = (date?: DateTime | string) => {
    if (!date) {
        return false;
    }
    const dt = date instanceof DateTime ? date : DateTime.fromISO(date);
    return dt.hasSame(DateTime.now(), 'day');
};

/** Returns true if date is tomorrow */
export const dateIsTomorrow = (date?: DateTime | string) => {
    if (!date) {
        return false;
    }
    const dt = date instanceof DateTime ? date : DateTime.fromISO(date);
    return dt.hasSame(DateTime.now().plus({ days: 1 }), 'day');
};

/** Takes a date string in a non-standard format and corrects it to an ISO standard string */
export function fixDateStringToIso(date: string, format: string, zone?: string | null) {
    const dt = DateTime.fromFormat(date, format, { zone: zone ?? undefined });
    return dt.isValid ? (dt.toISO() as string) : date;
}
