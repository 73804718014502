import { accountingHandlers } from 'features/accounting/accounting.mocks';
import { authHandlers } from 'features/auth/auth.mocks';
import { customersHandlers } from 'features/customers/customers.mocks';
import { dispatchHandlers } from 'features/dispatch/dispatch.mock';
import { inventoryHandlers } from 'features/inventory/inventory.mocks';
import { salesHandlers } from 'features/sales/sales.mocks';
import { scheduleHandlers } from 'features/schedule/schedule.mocks';
import { stocktakeHandlers } from 'features/stocktake/stocktake.mocks';
import { workOrdersHandlers } from 'features/workOrders/workOrders.mocks';
import { workstationsHandlers } from 'features/workstations/workstations.mocks';

export const handlers = [
    ...authHandlers,
    ...accountingHandlers,
    ...customersHandlers,
    ...dispatchHandlers,
    ...inventoryHandlers,
    ...salesHandlers,
    ...scheduleHandlers,
    ...stocktakeHandlers,
    ...workOrdersHandlers,
    ...workstationsHandlers,
];
