import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './LayoutBody.scss';

export default function LayoutBody({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return <div className={coalesceClassNames('LayoutBody', className)}>{children}</div>;
}
