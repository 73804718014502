import MyButton from 'components/MyButton/MyButton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import WorkItemStepHistoryModal from 'features/workOrders/components/WorkItemStepHistoryModal/WorkItemStepHistoryModal';
import WorkOrderDetailModal from 'features/workOrders/components/WorkOrderDetailModal/WorkOrderDetailModal';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import useUrlQueryState from 'hooks/useUrlQueryState';
import React from 'react';
import WorkstationGoToItemModal from '../WorkstationGoToItemModal/WorkstationGoToItemModal';
import './WorkstationStepSidebar.scss';

/** Work order/item information that appears in the WorkstationStepDetailsModal */
export default function WorkstationStepSidebar({
    model,
    currentWorkstationId,
}: {
    model: WorkstationStepDetail;
    currentWorkstationId?: string;
}) {
    /** Determines which 'more info' modal is shown
     * This is done via urlParams so the browser's back button can bring you back to this state
     */
    const [showMoreModal, setShowMoreModal] = useUrlQueryState('showMore', {
        allowedValues: ['', 'stepHistory', 'relatedSteps', 'workOrder'],
    });

    return (
        <div className="WorkstationStepSidebar">
            <PropertyContainer>
                <PropertyDisplay
                    label="Customer order"
                    value={model.context.workOrderItem.customerOrderReference}
                />

                <PropertyDisplay
                    label="Sidemark"
                    value={model.context.workOrderItem.customerOrderSidemark}
                />

                <PropertyDisplay
                    label="More info"
                    value={
                        <ul className="WorkstationStepSidebar__Links">
                            <li>
                                <MyButton
                                    label="Step history"
                                    buttonType="LinkButton"
                                    onClick={() => setShowMoreModal('stepHistory')}
                                />
                            </li>
                            <li>
                                <MyButton
                                    label="Related steps"
                                    buttonType="LinkButton"
                                    onClick={() => setShowMoreModal('relatedSteps')}
                                />
                            </li>
                            <li>
                                <MyButton
                                    label="Work order"
                                    buttonType="LinkButton"
                                    onClick={() => setShowMoreModal('workOrder')}
                                />
                            </li>
                        </ul>
                    }
                />
            </PropertyContainer>

            {showMoreModal === 'workOrder' && (
                <WorkOrderDetailModal
                    workOrderId={model.context.workOrder.id}
                    tuid={model.context.workOrder.tuid}
                    close={() => setShowMoreModal('')}
                />
            )}

            {showMoreModal === 'relatedSteps' && currentWorkstationId && (
                <WorkstationGoToItemModal
                    stationId={currentWorkstationId}
                    workItemTuid={model?.context.workOrderItem.tuid}
                    close={() => setShowMoreModal('')}
                />
            )}

            {showMoreModal === 'stepHistory' && (
                <WorkItemStepHistoryModal
                    model={model}
                    close={() => setShowMoreModal('')}
                />
            )}
        </div>
    );
}
