import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import ShipmentStatusBadge from '../ShipmentStatusBadge/ShipmentStatusBadge';
import './ShipmentsTable.scss';

import DataTablePaging from 'components/DataTable/DataTablePaging';
import DataTableCriteria, { CriteriaBuilder } from 'components/DataTableCriteria/DataTableCriteria';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import dispatchApi, { ShipmentListParams } from 'features/dispatch/dispatch.api';
import { Shipment } from 'features/dispatch/models/Shipment';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback } from 'react';
import { ApiTagType } from 'services/api';

const COLUMN_DEFS = ColumnBuilder<Shipment>()
    .column({
        label: 'Shipment',
        key: 'tuid',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        label: 'Name',
        key: 'name',
        isSortable: true,
        getValue: item => item.name,
    })
    .column({
        label: 'Date',
        key: 'date',
        isSortable: true,
        defaultSort: 'DESC',
        getValue: item => item.date,
        renderValue: val => formatDateTimeRelative(val),
    })
    // .column({
    //     label: 'Packages',
    //     key: 'packages',
    //     align: 'center',
    //     isSortable: true,
    //     getValue: item => item.context.packages.length,
    // })
    // .column({
    //     label: 'Work items',
    //     key: 'context.workOrderItems.length',
    //     isSortable: true,
    //     align: 'center',
    //     getValue: item => item.context,
    //     renderValue: (val, item) => <ShipmentWorkItemsProgress items={item.context.workOrderItems} />,
    // })
    .column({
        label: 'Status',
        key: 'status',
        isSortable: true,
        getValue: item => item.status,
        renderValue: (val, item) => (
            <ShipmentStatusBadge
                shipment={item}
                size="small"
            />
        ),
    })
    .build();

const CRITERIA_FIELDS = CriteriaBuilder<ShipmentListParams>()
    .criteria({
        field: 'search',
        label: 'Search',
        type: 'search',
        defaultValue: '',
    })
    .criteria({
        field: 'date',
        label: 'Date',
        type: 'date',
        range: 'any',
        defaultValue: '',
    })
    .criteria({
        field: 'hideCompleted',
        label: 'Hide completed',
        type: 'select',
        defaultValue: 'true',
        options: [
            {
                label: 'Hide',
                value: 'true',
            },
            {
                label: 'Show',
                value: 'false',
            },
        ],
    })
    .build();

export default function ShipmentsTable() {
    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery<ShipmentListParams>(CRITERIA_FIELDS);

    const query = dispatchApi.useShipmentListQuery(queryParams);
    const refreshData = useApiTagInvalidate([ApiTagType.Shipment]);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<Shipment>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    return (
        <>
            <LayoutHeader>
                <DataTableCriteria
                    fields={CRITERIA_FIELDS}
                    onChange={setQueryCriteria}
                />
            </LayoutHeader>

            <LayoutBody>
                <DataTable
                    className="ShipmentsTable__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={query.data?.data}
                    onRefresh={refreshData}
                    rowLinkTo={item => item.id}
                    zebra="light"
                    useStickyHeader={true}
                    useFrontEndSorting={false}
                    onSortChanged={handleSortChanged}
                    isRefreshing={query.isFetching}
                    columns={COLUMN_DEFS}
                />
            </LayoutBody>

            {(query.data?.total ?? 0) > 0 && (
                <LayoutHeader>
                    <DataTablePaging
                        data={paging}
                        totalCount={query.data?.total}
                        onChange={setQueryPaging}
                    />
                </LayoutHeader>
            )}
        </>
    );
}
