import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './LayoutHeader.scss';

export default function LayoutHeader({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) {
    return <div className={coalesceClassNames('LayoutHeader', className)}>{children}</div>;
}
