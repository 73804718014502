import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum UnitOfMeasureFamily {
    Unit = 'UNIT',
    Length = 'LENGTH',
    Weight = 'WEIGHT',
    Volume = 'VOLUME',
}
export default UnitOfMeasureFamily;

const labels: EnumDisplayLabels<typeof UnitOfMeasureFamily> = {
    Unit: 'Unit',
    Length: 'Length',
    Weight: 'Weight',
    Volume: 'Volume',
};

export const UnitOfMeasureFamilyDisplay = createDisplayEnum(UnitOfMeasureFamily, labels);
