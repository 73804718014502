import z from 'zod';
import SalesOrderCoreStatus from '../enums/SalesOrderCoreStatus';

export const SalesOrderStatusSchema = z.object({
    id: z.number(),
    name: z.string(),
    backgroundColor: z.string(),
    textColor: z.string(),
    isActive: z.boolean(),
    sortOrder: z.number(),
    coreStatus: z.nativeEnum(SalesOrderCoreStatus),
});

export type SalesOrderStatus = z.infer<typeof SalesOrderStatusSchema>;
