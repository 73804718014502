import { rest } from 'msw';

import Env from 'config/Env';
import { fakeWorkItemDetails, fakeWorkOrders } from './faker/WorkOrderFaker';

const BASE_URL = `${Env.API_BASE_URL}`;

export const workOrdersHandlers = [
    // Work order detail
    rest.get(`${BASE_URL}/workorder/:id`, (req, res, ctx) => {
        const id = req.params.id;
        const result = fakeWorkOrders.find(w => w.id === id);
        if (!result) {
            return res(
                ctx.status(400),
                ctx.json({ message: `Work Order with id '${id}' not found` }),
            );
        }
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),

    // Work item detail
    rest.get(`${BASE_URL}/workorderitem/:id`, (req, res, ctx) => {
        const id = req.params.id;
        const result = fakeWorkItemDetails.find(w => w.id === id);
        if (!result) {
            return res(
                ctx.status(400),
                ctx.json({ message: `Work item with id '${id}' not found` }),
            );
        }
        return res(ctx.delay(500), ctx.status(200), ctx.json(result));
    }),
];
