import { dateTimeOldSystem } from 'utils/dateSchemas';
import { tryParseJson } from 'utils/helpers';
import z from 'zod';

const SalesItemHistoryStateSchema = z.object({
    id: z.number(),
    notes: z.string().nullable().optional(),
    order_line_status_id: z.coerce.number().nullable().optional(),
    quantity: z.number().optional(),
    unit_cost_price: z.coerce.number().optional(),
    unit_sell_price: z.coerce.number().optional(),
    unit_tax: z.coerce.number().optional(),
});

export type SalesItemHistoryState = z.infer<typeof SalesItemHistoryStateSchema>;

// These are the attributes of a sales order line that we want to display on the history view
export const salesItemHistoryFieldLabels: Partial<Record<keyof SalesItemHistoryState, string>> = {
    notes: 'Notes',
    order_line_status_id: 'Status',
    quantity: 'Quantity',
    unit_cost_price: 'Unit cost price',
    unit_sell_price: 'Unit sell price',
    unit_tax: 'Unit tax',
};

export const SalesItemHistoryEntrySchema = z
    .object({
        id: z.number(),
        created_at: dateTimeOldSystem(),
        logs: z.string(),
        manufacturer_employees_first_name: z.string(),
        manufacturer_employees_last_name: z.string(),
        new: z.string(), // stringified json obj
        old: z.string(), // stringified json obj
        type: z.enum(['order-line', 'order-line-dl']),
    })
    .transform(model => {
        const activityNew = SalesItemHistoryStateSchema.parse(tryParseJson(model.new));
        const activityOld = SalesItemHistoryStateSchema.parse(tryParseJson(model.old));
        return {
            ...model,
            manufacturer_employees_full_name: `${model.manufacturer_employees_first_name} ${model.manufacturer_employees_last_name}`,
            new: activityNew,
            old: activityOld,
        };
    });

export type SalesItemHistoryEntry = z.infer<typeof SalesItemHistoryEntrySchema>;
