import { faker } from '@faker-js/faker';
import CustomerAccessType from '../enums/CustomerAccessType';
import CustomerHoldStatus from '../enums/CustomerHoldStatus';
import CustomerPaymentMethod from '../enums/CustomerPaymentMethod';
import { Customer, CustomerSchema } from '../models/Customer';
import { CustomerDetail } from '../models/CustomerDetail';

faker.seed(12);

export function generateCustomerDetail(): CustomerDetail {
    return {
        id: faker.number.int(100),
        countryId: faker.number.int(100),
        accessType: faker.helpers.enumValue(CustomerAccessType),
        companyName: faker.company.name(),
        companyPhone: faker.phone.number(),
        context: {
            mainCompany: {
                id: faker.number.int(100),
                name: faker.company.name(),
                accountId: faker.number.int(100),
                email: faker.internet.email(),
                timezone: faker.location.timeZone(),
                phone: faker.phone.number(),
            },
            customerConfig: {
                isInternal: faker.helpers.arrayElement([true, false]),
                creditLimit: faker.number.int(100),
                creditTerms: faker.word.sample(),
                notes: faker.word.sample(),
                paymentMethod: faker.helpers.enumValue(CustomerPaymentMethod),
                shippingDetails: faker.location.streetAddress(),
                status: faker.helpers.enumValue(CustomerHoldStatus),
                taxApplicable: faker.helpers.arrayElement([true, false]),
                taxExemptions: [
                    {
                        state: faker.location.state({ abbreviated: true }),
                        reason: faker.word.sample(),
                    },
                    {
                        state: faker.location.state({ abbreviated: true }),
                        reason: faker.word.sample(),
                    },
                ],
            },
            address: {
                fullAddress: faker.location.streetAddress(),
            },
            brands: [
                {
                    id: 451,
                    name: faker.company.name(),
                },
                {
                    id: 306,
                    name: faker.company.name(),
                },
                {
                    id: 2564,
                    name: faker.company.name(),
                },
            ],
        },
    };
}

export const fakeCustomerDetails = faker.helpers.multiple(generateCustomerDetail, {
    count: 12,
});

export function getCustomerList() {
    return fakeCustomerDetails.map<Customer>(c => CustomerSchema.parse(c));
}
