import z from 'zod';
import { UnitOfMeasureSchema } from './UnitOfMeasure';

export const InventoryMeasureSchema = z.object({
    canPurchase: z.boolean(),
    canSell: z.boolean(),
    canConsume: z.boolean(),
    id: z.string(),
    isArchived: z.boolean(),
    isDisplayUnit: z.boolean(),
    name: z.string().nullable(),
    unitOfMeasure: UnitOfMeasureSchema,
    value: z.number(),
});

export type InventoryMeasure = z.infer<typeof InventoryMeasureSchema>;
