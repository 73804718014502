import { z } from 'zod';
import CustomerHoldStatus from '../enums/CustomerHoldStatus';
import CustomerPaymentMethod from '../enums/CustomerPaymentMethod';
import { CustomerTaxExemptionSchema } from './CustomerTaxExemption';

export const CustomerConfigSchema = z.object({
    isInternal: z.boolean(),
    creditLimit: z.number().nullable(),
    creditTerms: z.string().nullable(),
    notes: z.string().nullable(),
    paymentMethod: z.nativeEnum(CustomerPaymentMethod),
    shippingDetails: z.string().nullable(),
    status: z.nativeEnum(CustomerHoldStatus),
    taxApplicable: z.boolean(),
    taxExemptions: z.array(CustomerTaxExemptionSchema).nullable(),
});

export type CustomerConfig = z.infer<typeof CustomerConfigSchema>;

export class CustomerConfigFactory {
    static create(data?: Partial<CustomerConfig>) {
        const defaults: CustomerConfig = {
            isInternal: false,
            creditLimit: null,
            creditTerms: null,
            notes: null,
            paymentMethod: CustomerPaymentMethod.Default,
            shippingDetails: null,
            status: CustomerHoldStatus.None,
            taxApplicable: false,
            taxExemptions: null,
        };

        const result: CustomerConfig = { ...defaults, ...data };

        return result;
    }
}
