import { MyCheckboxTreeNode } from 'components/MyCheckboxTree/MyCheckboxTree';
import inventoryApi from 'features/inventory/inventory.api';
import { Supplier } from 'features/inventory/models/Supplier';
import { useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import StocktakeTargetType from '../enums/StocktakeTargetType';
import { StocktakeTarget } from '../models/StocktakeTarget';

export default function useSupplierNodes({
    targets,
    appendMissingIds,
}: {
    targets?: StocktakeTarget[];
    appendMissingIds?: boolean;
}) {
    const suppliersQuery = inventoryApi.useSupplierListQuery();
    return useSupplierNodesPure({
        targets,
        appendMissingIds,
        allSupplierData: suppliersQuery.data?.data ?? [],
    });
}

export function useSupplierNodesPure({
    targets,
    appendMissingIds,
    allSupplierData,
}: {
    targets?: StocktakeTarget[];
    appendMissingIds?: boolean;
    allSupplierData: Supplier[];
}) {
    const selectedInventorySupplierIds = useMemo(
        () =>
            targets
                ?.filter(t => t.targetType === StocktakeTargetType.Supplier)
                .map(t => t.targetId),
        [targets],
    );

    const supplierNodes = useMemo(() => {
        const nodes = allSupplierData.map<MyCheckboxTreeNode>(l => ({
            id: l.id,
            label: l.name,
            nodes: [],
        }));

        nodes?.sort((a, b) => typescriptNaturalSort(a.label, b.label));

        // append any selected ids that don't appear in the tree
        // i.e locations that have been deleted
        if (appendMissingIds) {
            selectedInventorySupplierIds
                ?.filter(id => !allSupplierData.find(l => l.id === id))
                .forEach(id =>
                    nodes.push({
                        id,
                        label: id,
                        nodes: [] as MyCheckboxTreeNode[] | undefined,
                    }),
                );
        }
        return nodes;
    }, [allSupplierData, appendMissingIds, selectedInventorySupplierIds]);

    return [supplierNodes, selectedInventorySupplierIds] as const;
}
