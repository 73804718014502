import Icons from 'Icons';
import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import MySkeleton from 'components/MySkeleton/MySkeleton';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import LabelPrintDialog, {
    getMaxCopies,
    LabelPrintDialogResult,
} from 'features/sales/components/LabelPrintDialog/LabelPrintDialog';
import salesApi from 'features/sales/sales.api';
import StepCanTransition from 'features/workOrders/enums/StepCanTransition';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import WorkflowStepType from 'features/workOrders/enums/WorkflowStepType';
import { WorkstationWorkItemField } from 'features/workOrders/models/WorkstationWorkItem';
import WorkstationPrintMethod from 'features/workstations/enums/WorkstationPrintMethod';
import WorkstationPrintOption from 'features/workstations/enums/WorkstationPrintOption';
import { StepTransitionParams } from 'features/workstations/models/StepTransitionParams';
import { WorkstationStepDetail } from 'features/workstations/models/WorkstationStepDetail';
import workstationsApi from 'features/workstations/workstations.api';
import {
    selectCurrentWorkstation,
    selectPendingStepTransition,
} from 'features/workstations/workstations.slice';
import printJS from 'print-js';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { isEmpty } from 'utils/helpers';
import WorkItemFieldDisplay from '../WorkItemFieldDisplay/WorkItemFieldDisplay';
import WorkstationStepPacking from '../WorkstationStepPacking/WorkstationStepPacking';
import WorkstationStepSidebar from '../WorkstationStepSidebar/WorkstationStepSidebar';
import WorkstationStepStatusArea from '../WorkstationStepStatusArea/WorkstationStepStatusArea';
import './WorkstationStepDetailsModal.scss';

export function WorkstationStepDetailsModal({
    model,
    isLoading = false,
    isError = false,
    currentWorkstationId,
    nextUrl,
    prevUrl,
    animateIn,
    close,
}: {
    model?: WorkstationStepDetail;
    isLoading?: boolean;
    isError?: boolean;
    currentWorkstationId?: string;
    nextUrl?: string;
    prevUrl?: string;
    animateIn?: boolean;
    close?: () => void;
}) {
    const [transitionMutation] = workstationsApi.useWorkstationStepTransitionMutation();
    const workstation = useAppSelector(selectCurrentWorkstation);

    const dialogManager = useDialogManager();
    const [labelConfigQuery] = salesApi.useLazySalesItemWorkflowStepLabelConfigQuery();

    const currentWorkstation = useAppSelector(selectCurrentWorkstation);
    const printOption = currentWorkstation?.printOption ?? WorkstationPrintOption.None;

    const isExpress =
        printOption === WorkstationPrintOption.Express ||
        printOption === WorkstationPrintOption.ExpressOnStart ||
        printOption === WorkstationPrintOption.ExpressOnComplete;

    const printLabels = useCallback(async () => {
        if (model) {
            /** Wrap the logic in an async function
             * so that we can show a single loading dialog
             * while we wait for potentially several different calls to happen */
            const runPrint = async () => {
                try {
                    // get label configs
                    const config = await labelConfigQuery({
                        salesItemId: model.context.workOrderItem.salesOrderLineId,
                        workflowStepId: model.workflowStepId,
                    }).unwrap();

                    if (config.length === 0) {
                        dialogManager.toast({
                            title: 'No labels to be printed',
                        });
                        return;
                    }
                    let labelPromptResult: LabelPrintDialogResult;
                    if (isExpress) {
                        // for express printing we skip the dialog and just print every label with the default copies value
                        labelPromptResult = {
                            copies: getMaxCopies(config),
                            labelIds: config.map(c => c.id),
                            confirmed: true,
                        };
                    } else {
                        // non-express - show dialog as normal
                        labelPromptResult = await dialogManager.custom(LabelPrintDialog, {
                            config,
                        });
                    }
                    if (labelPromptResult.confirmed) {
                        // generate request params
                        const requestParams = new URLSearchParams();
                        requestParams.append('copies', String(labelPromptResult.copies));
                        labelPromptResult.labelIds.forEach(id => {
                            requestParams.append('labelIds', String(id));
                        });
                        if (currentWorkstation?.printMethod === WorkstationPrintMethod.Download) {
                            requestParams.append('download', 'true');
                        }

                        // construct the pdf url
                        const pdfUrl = `${window.BACKEND_URL}/workstations/${currentWorkstationId}/workordersteps/${model.id}/labels-new?${requestParams}`;

                        switch (currentWorkstation?.printMethod) {
                            case WorkstationPrintMethod.Open:
                            case WorkstationPrintMethod.Download: {
                                window.open(pdfUrl, '_blank');
                                break;
                            }
                            case WorkstationPrintMethod.AutoPrint: {
                                const response = await fetch(pdfUrl, {
                                    method: 'GET',
                                    credentials: 'include',
                                });
                                const url = URL.createObjectURL(await response.blob());
                                printJS(url);
                                break;
                            }
                            case WorkstationPrintMethod.Share: {
                                const response = await fetch(pdfUrl, {
                                    method: 'GET',
                                    credentials: 'include',
                                });

                                const fileData = await response.arrayBuffer();
                                const title = `${model.context.workOrderItem.tuid} - ${model.context.workflowStep.name}.pdf`;
                                const file = new File([fileData], title, {
                                    type: 'application/pdf',
                                });
                                const shareContent = {
                                    title,
                                    files: [file],
                                };

                                let doFallback = false;
                                if (navigator.share && navigator.canShare(shareContent)) {
                                    try {
                                        await navigator.share(shareContent);
                                    } catch (e: any) {
                                        // if user cancels then an AbortError is raised, which we should just swallow
                                        // for any other error we should fallback to the OPEN behavior and log the error to rollbar
                                        if (e.name !== 'AbortError') {
                                            doFallback = true;
                                            window.ROLLBAR_INSTANCE?.error(e, {
                                                versionHash: process.env.REACT_APP_GIT_HASH,
                                            });
                                        }
                                    }
                                } else {
                                    // share not supported
                                    doFallback = true;
                                }

                                if (doFallback) {
                                    // sharing failed or not supported - fallback to opening in a new tab
                                    window.open(pdfUrl, '_blank');
                                }
                                break;
                            }
                            default:
                                throw new Error(
                                    `Unsupported print method ${currentWorkstation?.printMethod}`,
                                );
                        }
                    }
                } catch (e) {
                    dialogManager.alert({
                        title: 'Failed to generate label',
                        message: 'Please check your label configs before trying again.',
                    });
                }
            };

            // show loading dialog while we wait for above to happen
            dialogManager.showLoadingWhile(runPrint());
        }
    }, [
        currentWorkstationId,
        dialogManager,
        isExpress,
        labelConfigQuery,
        model,
        currentWorkstation?.printMethod,
    ]);

    const doStepTransition = useCallback(
        async (params: StepTransitionParams) => {
            if (!currentWorkstationId || !model) {
                throw new Error(
                    'doStepTransition failed because the state has not finished initializing',
                );
            }
            await transitionMutation({
                stepId: model.id,
                stationId: currentWorkstationId,
                from: model.status,
                to: params.to,
                reason: params.reason,
            });

            // express printing
            // automatically print labels when starting or completing steps if the workstation is set to do so
            const isStarting =
                model.status === WorkItemStepStatus.Unstarted &&
                params.to === WorkItemStepStatus.InProgress;

            const isCompleting = params.to === WorkItemStepStatus.Completed;

            if (
                (printOption === WorkstationPrintOption.ExpressOnStart && isStarting) ||
                (printOption === WorkstationPrintOption.ExpressOnComplete && isCompleting)
            ) {
                printLabels?.();
            }
        },
        [currentWorkstationId, model, printLabels, printOption, transitionMutation],
    );

    const handleResetState = useCallback(() => {
        doStepTransition?.({
            to: WorkItemStepStatus.Unstarted,
        });
    }, [doStepTransition]);

    const kebabMenuItems: MyMenuButtonItem[] = useMemo(() => {
        const canReset =
            model?.status !== WorkItemStepStatus.Unstarted &&
            model?.context.canTransition === StepCanTransition.True;

        const items: (false | MyMenuButtonItem)[] = [
            !!canReset && {
                label: 'Reset to unstarted',
                IconLeft: Icons.StepReset,
                onClick: handleResetState,
            },
        ];

        return items.filter(Boolean) as MyMenuButtonItem[];
    }, [handleResetState, model?.context.canTransition, model?.status]);

    const pendingStepTransition = useAppSelector(selectPendingStepTransition);

    return (
        <MyModal
            className="WorkstationStepDetailsModal"
            close={close}
            isError={isError}
            fullHeight={true}
            animateIn={animateIn}
            mobileTitle="Workstation"
        >
            {isLoading ? (
                <LoadingSkeleton />
            ) : (
                model && (
                    <>
                        <div className="WorkstationStepDetailsModal__Header">
                            <div className="WorkstationStepDetailsModal__Header__Main">
                                <div className="WorkstationStepDetailsModal__Header__Main__Path">
                                    {workstation?.name}
                                    <Icons.ChevronRight />
                                    {model.context.workOrderItem.tuid}
                                </div>
                                <h1 className="WorkstationStepDetailsModal__Header__Main__Title">
                                    {model.context.workflowStep.name}
                                </h1>
                            </div>
                            <div className="WorkstationStepDetailsModal__Header__Actions">
                                {kebabMenuItems.length > 0 && (
                                    <MyMenuKebabButton
                                        disabled={!!pendingStepTransition}
                                        menuItems={kebabMenuItems}
                                    />
                                )}
                            </div>
                        </div>

                        <WorkstationStepStatusArea
                            model={model}
                            doStepTransition={doStepTransition}
                        />

                        {model?.context.workOrderItem.isCancelled && (
                            <MessagePanel messageType="warning">
                                This order has been cancelled
                            </MessagePanel>
                        )}
                        <div className="WorkstationStepDetailsModal__Main">
                            <WorkstationStepSidebar
                                model={model}
                                currentWorkstationId={currentWorkstationId}
                            />
                            <div className="WorkstationStepDetailsModal__Main__Details">
                                <h2 className="WorkstationStepDetailsModal__Main__Details__Description">
                                    {model.context.workOrderItem.description}
                                </h2>

                                {model.context.workflowStep.stepType ===
                                    WorkflowStepType.Packing && (
                                    // Packing UI
                                    <WorkstationStepPacking
                                        model={model}
                                        doStepTransition={doStepTransition}
                                    />
                                )}

                                {model.context.workOrderItem.detailFields.length > 0 && (
                                    <PropertyContainer
                                        layout="table"
                                        className="WorkstationStepDetailsModal__Main_DetailsField"
                                    >
                                        <InfoGridTable>
                                            {model.context.workOrderItem.detailFields.map(
                                                (f, index) => (
                                                    <DetailField
                                                        key={index}
                                                        field={f}
                                                    />
                                                ),
                                            )}
                                        </InfoGridTable>
                                    </PropertyContainer>
                                )}
                            </div>
                        </div>
                        <div className="WorkstationStepDetailsModal__Footer">
                            <div className="WorkstationStepDetailsModal__Footer__Left">
                                <MyButton
                                    onClick={printLabels}
                                    buttonType="Secondary"
                                    label="Print Labels"
                                    IconLeft={Icons.Print}
                                />
                            </div>
                            <div className="WorkstationStepDetailsModal__Footer__Right">
                                <MyButton
                                    className="WorkstationStepDetailsModal__QuickNavButton"
                                    buttonType="Hollow"
                                    label="Prev"
                                    title="Go to the previous work item in the queue"
                                    IconLeft={Icons.ArrowPrev}
                                    href={prevUrl}
                                    disabled={!prevUrl}
                                    LinkComponent={MyButtonLink}
                                />
                                <MyButton
                                    className="WorkstationStepDetailsModal__QuickNavButton"
                                    buttonType="Hollow"
                                    label="Next"
                                    title="Go to the next work item in the queue"
                                    IconRight={Icons.ArrowNext}
                                    href={nextUrl}
                                    disabled={!nextUrl}
                                    LinkComponent={MyButtonLink}
                                />
                            </div>
                        </div>
                    </>
                )
            )}
        </MyModal>
    );
}

function DetailField({ field }: { field: WorkstationWorkItemField }) {
    if (isEmpty(field.value) && field.hideIfEmpty) {
        return null;
    }
    return (
        <InfoGridRow
            label={field.displayName || field.key}
            value={isEmpty(field.value) ? '' : <WorkItemFieldDisplay field={field} />}
        />
    );
}

function LoadingSkeleton() {
    return (
        <div className="WorkstationStepDetailsModal__Skeleton">
            <MySkeleton
                width={200}
                height={52}
            />
            <MySkeleton
                height={72}
                style={{
                    marginRight: -32,
                    marginLeft: -32,
                }}
            />
            <div className="WorkstationStepDetailsModal__Skeleton__Body">
                <div className="WorkstationStepDetailsModal__Skeleton__Body__Main">
                    <MySkeleton
                        width={240}
                        height={44}
                    />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                    <MySkeleton height={32} />
                </div>
                <div className="WorkstationStepDetailsModal__Skeleton__Body__Sidebar">
                    <MySkeleton lines={2} />
                    <MySkeleton lines={2} />
                    <MySkeleton lines={4} />
                </div>
            </div>
        </div>
    );
}
