import { Portal } from '@mui/material';
import Icons from 'Icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import { wait } from 'utils/helpers';

export type ToastProps = {
    className?: string;
    icon?: 'wave' | 'party' | 'error' | React.ReactNode;
    actionText?: string;
    actionLinkTo?: string;
    actionOnClick?: (e: React.UIEvent) => void;
    title?: string | React.ReactFragment;
    message?: string | React.ReactFragment;
    displayTime?: number;
    dismissOnClick?: boolean;
    close?: () => void;
};

export default function Toast({
    className,
    title,
    message = '',
    icon = 'wave',
    actionText,
    actionLinkTo,
    actionOnClick,
    displayTime = 5000,
    dismissOnClick = true,
    close = () => {},
}: ToastProps) {
    const [isHiding, setIsHiding] = useState(false);
    const navigate = useNavigate();

    const hide = useCallback(async () => {
        if (!isHiding) {
            setIsHiding(true);
            await wait(500);
            close();
        }
    }, [isHiding, close]);

    useEffect(() => {
        // auto hide after 4 seconds
        const timeout = setTimeout(() => hide(), displayTime);
        return () => clearTimeout(timeout);
    }, [displayTime, hide]);

    const iconText =
        (typeof icon === 'string' &&
            {
                wave: '👋',
                party: '🎉',
                error: '😢',
            }[icon]) ||
        icon;

    const handleTouch = (e: React.UIEvent) => {
        if (dismissOnClick && !isHiding) {
            if (actionOnClick) {
                actionOnClick(e);
            }
            if (actionLinkTo) {
                navigate(actionLinkTo);
            }

            hide();
        }
    };

    return (
        <Portal>
            <div
                className={coalesceClassNames(
                    'DialogManager__Toast',
                    className,
                    isHiding && 'hiding',
                )}
                onClick={handleTouch}
                onMouseDown={handleTouch}
            >
                <div className="DialogManager__Toast__Frame">
                    <div className="icon">{iconText}</div>
                    <div className="DialogManager__Toast__Content">
                        <div className="title">{title}</div>
                        <div className="message">
                            {message}

                            {actionText && (
                                <>
                                    {' '}
                                    <Link
                                        className="DialogManager__Toast__ActionLink Link"
                                        to={actionLinkTo || '#'}
                                        onClick={actionOnClick}
                                    >
                                        {actionText}
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>

                    <button
                        className="DialogManager__Toast__CloseButton"
                        onClick={hide}
                    >
                        <Icons.Close />
                    </button>
                </div>
            </div>
        </Portal>
    );
}
