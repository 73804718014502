import { ScheduledWorkOrderSchema } from 'features/schedule/models/ScheduledWorkOrder';
import z from 'zod';
import { ScheduleEntitySchema } from './ScheduleEntity';

export const ScheduleSchema = ScheduleEntitySchema.extend({
    /** context / work orders is only populated when loaded via /withworkorders route */
    context: z
        .object({
            scheduleWorkOrders: z.array(ScheduledWorkOrderSchema),
        })
        .optional(),
});

export type Schedule = z.infer<typeof ScheduleSchema>;
