import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';
import WorkItemStepTransitionReason from '../enums/WorkItemStepTransitionReason';

export const WorkOrderItemStepEntitySchema = z.object({
    id: z.string(),
    workflowStepId: z.string(),
    workOrderItemId: z.string(),
    status: z.nativeEnum(WorkItemStepStatus),
    lastTransitionTimestamp: dateTimeInstant().nullable().optional(),
    lastTransitionWorkstationId: z.string().nullable().optional(),
    lastTransitionReason: z.nativeEnum(WorkItemStepTransitionReason).nullable().optional(),
    sortOrder: z.number().optional(),
});

export type WorkOrderItemStepEntity = z.infer<typeof WorkOrderItemStepEntitySchema>;
