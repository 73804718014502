import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import DataTableFilters, { FilterBuilder } from 'components/DataTableFilters/DataTableFilters';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import customersApi from 'features/customers/customers.api';
import CustomerAccessType from 'features/customers/enums/CustomerAccessType';
import { CustomerHoldStatusDisplay } from 'features/customers/enums/CustomerHoldStatus';
import { CustomerPaymentMethodDisplay } from 'features/customers/enums/CustomerPaymentMethod';
import { Customer } from 'features/customers/models/Customer';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React, { useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import coalesceClassNames from 'utils/coalesceClassNames';
import './CustomersTable.scss';

const COLUMN_DEFS = ColumnBuilder<Customer>()
    .column({
        label: 'Name',
        key: 'name',
        isSortable: true,
        getValue: item => item.context.mainCompany.name,
    })
    .column({
        label: 'Payment method',
        key: 'paymentMethod',
        isSortable: true,
        getValue: item => item.context.customerConfig.paymentMethod,
        renderValue: val => (
            <div className={coalesceClassNames('CustomersTable__PaymentMethod', val)}>
                {CustomerPaymentMethodDisplay.display(val)}
            </div>
        ),
    })
    .column({
        label: 'Hold status',
        key: 'status',
        isSortable: true,
        getValue: item => item.context.customerConfig.status,
        renderValue: val => (
            <div className={coalesceClassNames('CustomersTable__Status', val)}>
                {CustomerHoldStatusDisplay.display(val)}
            </div>
        ),
    })
    .column({
        label: 'Dealer portal access',
        key: 'accessType',
        isSortable: true,
        align: 'center',
        getValue: item => item.accessType,
        renderValue: val => (val === CustomerAccessType.DealerPortal ? <Icons.Check /> : null),
    })
    .build();

export default function CustomersTable() {
    const query = customersApi.useCustomerListQuery();
    const [filteredData, setFilteredData] = useState<Customer[]>();
    const [searchParams] = useSearchParams();

    // highlight the currently open item id if any
    const { customerId: activeItemId } = useParams();

    const refreshData = useApiTagInvalidate([ApiTagType.Customer]);

    const filters = useMemo(
        () =>
            FilterBuilder<Customer>()
                .filter({
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    getFields: item => [
                        item.context.mainCompany.name,
                        item.context.mainCompany.email,
                    ],
                    urlParam: 'search',
                })
                .filter({
                    label: 'Payment method',
                    type: 'select',
                    defaultValue: '',
                    getField: item => item.context.customerConfig.paymentMethod,
                    urlParam: 'paymentMethod',
                    options: CustomerPaymentMethodDisplay.options,
                })
                .filter({
                    label: 'Status',
                    type: 'select',
                    defaultValue: '',
                    getField: item => item.context.customerConfig.status,
                    urlParam: 'status',
                    options: CustomerHoldStatusDisplay.options,
                })
                .build(),
        [],
    );

    return (
        <>
            <LayoutHeader className="CustomersTable__FilterBar">
                <DataTableFilters
                    data={query.data}
                    filters={filters}
                    onChange={setFilteredData}
                />
            </LayoutHeader>

            <LayoutBody>
                <DataTable
                    className="CustomersTable__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={filteredData}
                    onRefresh={refreshData}
                    rowLinkTo={item => `${item.id}?${searchParams}`}
                    rowIsHighlighted={item => `${item.id}` === activeItemId}
                    zebra="light"
                    useStickyHeader={true}
                    useFrontEndSorting={true}
                    isRefreshing={query.isFetching}
                    columns={COLUMN_DEFS}
                />
            </LayoutBody>
        </>
    );
}
