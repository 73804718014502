import useUrlQueryState from 'hooks/useUrlQueryState';
import WorkstationListName from '../enums/WorkstationListName';

/** The workstations screen contains several lists of work items which are displayed as tabs.
 * There are several places that need to know which tab you are currently on and it's all driven by the url search params
 * This hook allow us to access and set the current tab in a type-safe way and avoid duplicating the logic in multiple places
 */
export default function useStepsListNameParam() {
    return useUrlQueryState('list', {
        allowedValues: Object.values(WorkstationListName),
    });
}
