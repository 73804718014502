import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum CustomerHoldStatus {
    None = 'NONE',
    StopOrder = 'STOP_ORDER',
    StopMake = 'STOP_MAKE',
}
export default CustomerHoldStatus;

const labels: EnumDisplayLabels<typeof CustomerHoldStatus> = {
    None: 'None',
    StopOrder: 'Stop order',
    StopMake: 'Stop make',
};

export const CustomerHoldStatusDisplay = createDisplayEnum(CustomerHoldStatus, labels);
