import { faker } from '@faker-js/faker';
import { fakeSalesOrders, getFakeSalesItemOptionsLegacy } from 'features/sales/faker/SalesFaker';
import { fakeSchedules } from 'features/schedule/faker/ScheduleFaker';
import { fakeWorkstations } from 'features/workstations/faker/WorkstationsFaker';
import StepCanTransition from '../enums/StepCanTransition';
import WorkflowStepType from '../enums/WorkflowStepType';
import WorkItemStatus from '../enums/WorkItemStatus';
import WorkItemStepStatus from '../enums/WorkItemStepStatus';
import { WorkOrderDetail } from '../models/WorkOrderDetail';
import { WorkOrderItemDetail } from '../models/WorkOrderItemDetail';
import { WorkOrderItemStepSummary } from '../models/WorkOrderItemStepSummary';
import { WorkOrderItemWithOptionsSchema } from '../models/WorkOrderItemWithOptions';
import { WorkOrderItemWithStateSchema } from '../models/WorkOrderItemWithState';

faker.seed(42);

export function generateFakeWorkOrderDetail(): WorkOrderDetail {
    const schedule = faker.helpers.arrayElement(fakeSchedules);
    const salesOrder = faker.helpers.arrayElement(fakeSalesOrders);
    return {
        id: faker.string.uuid(),
        tuid: `WRK${faker.number.int({ min: 1, max: 99999 }).toString().padStart(10, '0')}`,
        notes: '',

        context: {
            salesOrders: [
                {
                    id: salesOrder.id,
                    tuid: salesOrder.tuid,
                },
            ],
            workOrderItems: [],
            schedule: {
                id: schedule.id,
                date: schedule.date,
            },
            scheduleMeta: {
                sortOrder: 5,
            },
            workstationGroupAssignment: undefined,
        },
    };
}

const fakeStepNames = ['Do a little dance', 'Make a little love', 'Get down tonight'];
function generateFakeWorkItemStep(workOrderItem: WorkOrderItemDetail): WorkOrderItemStepSummary {
    const workflowStepId = faker.string.uuid();
    const status = faker.helpers.enumValue(WorkItemStepStatus);

    const hasTransitioned = [
        WorkItemStepStatus.InProgress,
        WorkItemStepStatus.OnHold,
        WorkItemStepStatus.Completed,
        WorkItemStepStatus.CompletedLocked,
    ].includes(status);
    const lastTransitionTimestamp = hasTransitioned
        ? faker.date.recent({ days: 7 }).toISOString()
        : null;

    const workstation = hasTransitioned ? faker.helpers.arrayElement(fakeWorkstations) : undefined;

    return {
        id: faker.string.uuid(),
        workflowStepId,
        workOrderItemId: workOrderItem.id,
        status,
        lastTransitionTimestamp,
        lastTransitionWorkstationId: workstation?.id,
        lastTransitionReason: undefined,
        sortOrder: faker.number.int(),
        context: {
            workOrderItem: WorkOrderItemWithOptionsSchema.parse(workOrderItem),
            workflowStep: {
                id: workflowStepId,
                name: faker.helpers.arrayElement(fakeStepNames),
                stepType: WorkflowStepType.Default,
            },
            workstationLatest: workstation,
            workOrderItemStepsBlocking: [],
            canTransition: faker.helpers.enumValue(StepCanTransition),
        },
    };
}

/** Map an array of options/syth options or labels to a hash object */
function mapOptionArray(arr?: { name: any; value: any }[] | null): Record<string, any> {
    const map = new Map(arr?.map(obj => [obj.name, obj.value]));
    return Object.fromEntries(map);
}

function getItemStateFromSteps(steps: WorkOrderItemStepSummary[]): WorkItemStatus {
    if (steps.some(s => s.status === WorkItemStepStatus.OnHold)) {
        return WorkItemStatus.OnHold;
    }
    if (
        steps.every(s =>
            [
                WorkItemStepStatus.Completed,
                WorkItemStepStatus.CompletedLocked,
                WorkItemStepStatus.Skipped,
                WorkItemStepStatus.SkippedLocked,
            ].includes(s.status),
        )
    ) {
        return WorkItemStatus.Completed;
    }

    if (steps.every(s => s.status === WorkItemStepStatus.Unstarted)) {
        return WorkItemStatus.Unstarted;
    }

    return WorkItemStatus.InProgress;
}

export function generateFakeWorkItem(workOrder: WorkOrderDetail): WorkOrderItemDetail {
    const salesOrder = fakeSalesOrders.find(s => `${s.id}` === workOrder.context.salesOrders[0].id);
    const salesItem =
        (salesOrder?.context.orderLines.length ?? 0) > 0
            ? faker.helpers.arrayElement(salesOrder?.context.orderLines ?? [])
            : undefined;

    const fakeOptionsLegacy = getFakeSalesItemOptionsLegacy();
    const options = mapOptionArray(fakeOptionsLegacy.options);
    const sythOptions = mapOptionArray(fakeOptionsLegacy.synthesized_options);
    const labels = mapOptionArray(fakeOptionsLegacy.labels);

    const schedule = fakeSchedules.find(s => s.id === workOrder.context.schedule.id);
    if (!schedule) {
        throw new Error(`Schedule not found with id ${workOrder.context.schedule.id}`);
    }

    const workItem: WorkOrderItemDetail = {
        id: faker.string.uuid(),
        tuid: `WI${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        description: faker.commerce.productName(),
        customerOrderReference: '',
        customerOrderSidemark: faker.company.name(),
        salesOrderLineId: `${salesItem?.id ?? ''}`,
        isCancelled: false,
        workflowId: faker.string.uuid(),
        workOrderId: workOrder.id,
        labels: { ...labels },
        options: {
            ...options,
            ...sythOptions,
        },
        context: {
            workOrder: { ...workOrder },
            workOrderItemState: faker.helpers.enumValue(WorkItemStatus), // this is modified below
            schedule,
            scheduleMeta: {
                sortOrder: 0,
            },
            workOrderItemSteps: [],
            workflow: {
                id: faker.string.uuid(),
                daysRequired: faker.number.int({ min: 1, max: 10 }),
                name: faker.commerce.productName(),
            },
        },
    };

    // generate steps
    workItem.context.workOrderItemSteps = faker.helpers.multiple(
        () => generateFakeWorkItemStep(workItem),
        {
            count: faker.number.int({ min: 0, max: 10 }),
        },
    );

    // fix the item status to match the steps
    workItem.context.workOrderItemState = getItemStateFromSteps(
        workItem.context.workOrderItemSteps,
    );

    return workItem;
}

export const fakeWorkOrders = faker.helpers.multiple(generateFakeWorkOrderDetail, { count: 80 });

// populate work items
export const fakeWorkItemDetails: WorkOrderItemDetail[] = [];
fakeWorkOrders.forEach(wo => {
    faker.helpers.multiple(
        () => {
            const itemDetail = generateFakeWorkItem(wo);
            fakeWorkItemDetails.push(itemDetail);
            const itemSummary = WorkOrderItemWithStateSchema.parse(itemDetail);
            wo.context.workOrderItems.push(itemSummary);
        },
        { count: faker.number.int({ min: 1, max: 10 }) },
    );
});
