import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import { SalesItem } from 'features/sales/models/SalesItem';
import {
    SalesItemOptionsLabelLegacy,
    SalesItemOptionsLegacy,
    SalesItemOptionsOptionLegacy,
} from 'features/sales/models/SalesItemOptionsLegacy';
import salesApi from 'features/sales/sales.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import typescriptNaturalSort from 'typescript-natural-sort';
import { isEmpty } from 'utils/helpers';
import './ProductOptionDisplayGridLegacy.scss';

export default function ProductOptionDisplayGridLegacy({ model }: { model: SalesItem }) {
    const dialogManager = useDialogManager();

    const optionsQueryLegacy = salesApi.useSalesItemOptionsLegacyQuery({
        itemId: model.id,
        manufacturerOrderId: model.manufacturerOrderId,
    });

    const showAdvancedOptions = useCallback(() => {
        dialogManager.custom(AdvancedOptionsModalLegacy, {
            model,
            salesItemOptionsLegacy: optionsQueryLegacy.data,
        });
    }, [dialogManager, model, optionsQueryLegacy.data]);

    return (
        <>
            {optionsQueryLegacy.isFetching ? (
                <div className="ProductOptionDisplayGridLegacy__OptionsLoading">
                    <MyLinearProgress />
                </div>
            ) : (
                <>
                    {(optionsQueryLegacy.data?.options?.length ?? 0) > 0 ? (
                        <InfoGridTable>
                            {optionsQueryLegacy.data?.options?.map((opt, index) => (
                                <DetailFieldLegacy
                                    key={index}
                                    field={opt}
                                />
                            ))}
                        </InfoGridTable>
                    ) : (
                        <div className="ProductOptionDisplayGridLegacy__SectionEmpty">&ndash;</div>
                    )}
                    <div className="ProductOptionDisplayGridLegacy__AdvancedOptionsLink">
                        <MyButton
                            className="Link"
                            buttonType="LinkButton"
                            isLoading={optionsQueryLegacy.isFetching}
                            onClick={showAdvancedOptions}
                            label="Show advanced"
                        />
                    </div>
                </>
            )}
        </>
    );
}

function DetailFieldLegacy({
    field,
}: {
    field: SalesItemOptionsOptionLegacy | SalesItemOptionsLabelLegacy;
}) {
    const value = useMemo(() => {
        if (isEmpty(field.value)) {
            return null;
        }
        if (Array.isArray(field.value)) {
            return <>{field.value.join(', ')}</>;
        }
        return <>{field.value}</>;
    }, [field.value]);

    return (
        <InfoGridRow
            label={field.name ?? ''}
            value={value}
        />
    );
}

function AdvancedOptionsModalLegacy({
    model,
    salesItemOptionsLegacy,
    close,
}: {
    model: SalesItem;
    salesItemOptionsLegacy?: SalesItemOptionsLegacy;
    close?: () => void;
}) {
    const optionsList = useMemo(() => {
        const labels = salesItemOptionsLegacy?.labels ?? [];
        const synthesizedOptions = salesItemOptionsLegacy?.synthesized_options ?? [];
        const options = [...labels, ...synthesizedOptions];

        options.sort((a, b) => typescriptNaturalSort(a.name, b.name));

        return options;
    }, [salesItemOptionsLegacy?.labels, salesItemOptionsLegacy?.synthesized_options]);

    return (
        <MyModal
            className="ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy"
            close={close}
            containerSelector="#modal-root-top"
            mobileTitle="Sales Item"
            header={
                <PageHeader
                    className="ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy"
                    title="Advanced Options"
                    titleContext={`Sales Item ${model?.lineNumber}`}
                ></PageHeader>
            }
        >
            {isEmpty(optionsList) && isEmpty(salesItemOptionsLegacy?.secondary_product_options) ? (
                // Empty state
                <div className="ProductOptionDisplayGridLegacy__SectionEmpty">
                    No advanced options found
                </div>
            ) : (
                <>
                    {!isEmpty(optionsList) && (
                        <InfoGridTable>
                            {optionsList?.map((label, index) => (
                                <DetailFieldLegacy
                                    key={index}
                                    field={label}
                                />
                            ))}
                        </InfoGridTable>
                    )}

                    {!isEmpty(salesItemOptionsLegacy?.secondary_product_options) && (
                        <div className="ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy__SecondaryProduct">
                            <h3 className="ProductOptionDisplayGridLegacy__AdvancedOptionsModalLegacy__SecondaryProduct__Title">
                                Secondary Product
                            </h3>
                            <InfoGridTable>
                                {salesItemOptionsLegacy?.secondary_product_options?.map(
                                    (label, index) => (
                                        <InfoGridRow
                                            key={index}
                                            label={label.name}
                                            value={label.value}
                                        />
                                    ),
                                )}
                            </InfoGridTable>
                        </div>
                    )}
                </>
            )}
        </MyModal>
    );
}
