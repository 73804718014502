import { AddressSchema } from 'models/Address';
import z from 'zod';
import { BrandSchema } from './Brand';
import { CompanySchema } from './Company';
import { CustomerConfigFactory, CustomerConfigSchema } from './CustomerConfig';

export const CustomerDetailSchema = z.object({
    id: z.number(),
    countryId: z.number(),
    accessType: z.string(),
    companyName: z.string().optional(),
    companyPhone: z.string().optional(),
    context: z.object({
        mainCompany: CompanySchema,
        customerConfig: CustomerConfigSchema.default(CustomerConfigFactory.create),
        address: AddressSchema.optional(),
        brands: z.array(BrandSchema),
    }),
});

export type CustomerDetail = z.infer<typeof CustomerDetailSchema>;
