import { ApiTagType, api } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import z from 'zod';
import { getDocumentFriendlyType } from './enums/DocumentFriendlyType';
import { DealerDocumentFile } from './models/DealerDocumentFile';
import { DocumentUploadResult, DocumentUploadResultSchema } from './models/DocumentUploadResult';
import {
    ManufacturerDocumentFile,
    ManufacturerDocumentFileSchema,
} from './models/ManufacturerDocumentFile';

const documentListResultSchema = z.object({
    data: z.array(ManufacturerDocumentFileSchema),
});

const documentsApi = api.injectEndpoints({
    endpoints: build => ({
        documentList: build.query<ManufacturerDocumentFile[], void>({
            query: () => ({
                url: `/old/documents`,
                method: 'GET',
                params: {
                    'type[options][0][value]': 'image',
                    'type[options][0][name]': 'Image',
                    'type[options][1][value]': 'pdf',
                    'type[options][1][name]': 'PDF',
                    'type[options][2][value]': 'word',
                    'type[options][2][name]': 'Word Document',
                    'type[options][3][value]': 'excel',
                    'type[options][3][name]': 'Excel Document',
                    document_category_id: 0,
                    name: '',
                    direct_upload: 0,
                    column: '',
                    rank: '',
                },
            }),
            transformResponse: (result: unknown) => documentListResultSchema.parse(result).data,
            providesTags: [ApiTagType.Document],
        }),

        /** Upload a document */
        documentUpload: build.mutation<
            DocumentUploadResult,
            {
                file: File;
                brandId?: number | null;
                directUpload: boolean;
            }
        >({
            async queryFn({ file, brandId, directUpload }, _queryApi, _extraOptions, fetchWithBQ) {
                const uuid = uuidv4();
                const friendlyName = file.name.split('.')[0];
                const friendlyType = getDocumentFriendlyType(file.name);

                const formData = new FormData();
                formData.append('document', file, file.name);
                formData.append('uuid', uuid);
                formData.append('friendly_type', friendlyType ?? '');
                formData.append('friendly_name', friendlyName);
                formData.append('document_category_id', `${brandId || ''}`);
                formData.append('directUpload', directUpload ? '1' : '0');

                const response = await fetchWithBQ({
                    url: `/old/documents`,
                    method: 'POST',
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.error) {
                    return {
                        error: response.error,
                    };
                }
                return {
                    data: DocumentUploadResultSchema.parse(response.data),
                };
            },
            invalidatesTags: [ApiTagType.Document],
        }),

        /** Update a document */
        documentUpdate: build.mutation<void, DealerDocumentFile>({
            query: doc => ({
                url: `/old/documents/${doc.id}`,
                method: 'POST',
                data: {
                    ...doc,
                    _method: 'PUT',
                },
            }),
            invalidatesTags: [ApiTagType.Document, ApiTagType.SalesOrderDocument],
        }),

        /** Archive a document */
        documentArchive: build.mutation<void, number>({
            query: documentId => ({
                url: `/old/documents/${documentId}`,
                method: 'POST',
                data: {
                    _method: 'DELETE',
                },
            }),
            invalidatesTags: [ApiTagType.Document],
        }),
    }),
});

export default documentsApi;
