import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import DndContainer from 'components/ReactSmoothDnd/DndContainer';
import Draggable from 'components/ReactSmoothDnd/Draggable';
import WorkItemsProgress from 'features/sales/components/WorkItemsProgress/WorkItemsProgress';
import { Schedule } from 'features/schedule/models/Schedule';
import { ScheduledWorkOrder } from 'features/schedule/models/ScheduledWorkOrder';
import scheduleApi from 'features/schedule/schedule.api';
import { selectLastMoveToSchedule } from 'features/schedule/schedule.slice';
import WorkOrderDetailModal from 'features/workOrders/components/WorkOrderDetailModal/WorkOrderDetailModal';
import useUrlQueryState from 'hooks/useUrlQueryState';
import Icons from 'Icons';
import { DateTime } from 'luxon';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DropResult } from 'smooth-dnd';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { dateIsPast, formatDateRelative } from 'utils/dateHelpers';
import { isEmpty } from 'utils/helpers';
import ScheduleMoveToDateDialog from '../ScheduleMoveToDateDialog/ScheduleMoveToDateDialog';
import './ScheduleWorkTable.scss';

type ScheduleColumn = {
    key: string;
    width: number;
    label: string;
    value?: (workOrder: ScheduledWorkOrder) => React.ReactNode;
    align?: 'left' | 'right' | 'center';
    overflow?: '' | 'ellipsis';
};

const COLUMNS: ScheduleColumn[] = [
    {
        label: 'Work Order',
        width: 120,
        key: 'workOrder',
        overflow: 'ellipsis',
        value: wo => wo.tuid,
    },
    {
        label: 'Ref.',
        width: 140,
        key: 'reference',
        overflow: 'ellipsis',
        value: wo => wo.identifier,
    },
    {
        label: 'Items',
        width: 48,
        key: 'count',
        value: wo => wo.context.workOrderItems.length,
        align: 'center',
    },
    {
        label: 'Progress',
        width: 120,
        key: 'progress',
        value: wo => (
            <WorkItemsProgress
                workItems={wo.context.workOrderItems}
                showPercentage
            />
        ),
    },
    {
        label: 'Due',
        width: 100,
        key: 'due',
        value: wo =>
            formatDateRelative(wo.requestedDate, {
                alwaysDate: true,
                hideDayName: true,
            }),
    },
    {
        label: 'Est. days',
        width: 60,
        key: 'daysRequired',
        value: wo => wo.daysRequired,
        align: 'center',
    },
    {
        label: 'Est. completion',
        width: 92,
        key: 'estimatedDate',
        value: wo =>
            formatDateRelative(wo.estimatedCompletionDate, {
                alwaysDate: true,
                hideDayName: true,
            }),
    },
    {
        label: 'Slack days',
        width: 68,
        key: 'slackDays',
        value: wo => (
            <div
                className={coalesceClassNames(
                    'ScheduleWorkTable__SlackDays',
                    wo.slackDays < 0 && 'negative',
                )}
            >
                {wo.slackDays}
            </div>
        ),
        align: 'center',
    },
    {
        label: 'Group',
        width: 200,
        key: 'group',
        value: wo => wo.workstationGroupAssignment?.name,
    },
];

export default function ScheduleWorkTable({
    schedule,
    isCollapsed,
    isLoading,
    handleDrop,
    onAssign,
    onDragStart,
    onDragEnd,
}: {
    schedule: Schedule;
    isLoading?: boolean;
    isCollapsed: boolean;
    handleDrop: (result: DropResult) => void;
    onAssign: (schedule: Schedule, workOrders: ScheduledWorkOrder[], sortOrder?: number) => void;
    onDragStart: () => void;
    onDragEnd: () => void;
}) {
    const empty = !schedule.context?.scheduleWorkOrders.length;

    return (
        <div
            role="table"
            className={coalesceClassNames(
                'ScheduleWorkTable',
                empty && 'ScheduleWorkTable--Empty',
                isCollapsed && 'ScheduleWorkTable--Collapsed',
            )}
        >
            <div className="ScheduleWorkTable__Head">
                <div className="ScheduleWorkTable__HeaderRow">
                    {/* Drag handle cell */}
                    <div className="ScheduleWorkTable__HeaderRow__Cell ScheduleWorkTable__HeaderRow__Cell--DragHandle">
                        &nbsp;
                    </div>
                    {/* Wrapper containing defined column cells */}
                    <div className="ScheduleWorkTable__HeaderRow__ColsWrapper">
                        {COLUMNS.map(col => (
                            <div
                                role="columnheader"
                                key={col.key}
                                className={coalesceClassNames(
                                    'ScheduleWorkTable__HeaderRow__Cell',
                                    col.align,
                                )}
                                style={{ width: col.width }}
                            >
                                {col.label}
                            </div>
                        ))}
                    </div>
                    {/* Menu cell */}
                    <div className="ScheduleWorkTable__HeaderRow__Cell ScheduleWorkTable__HeaderRow__Cell--Menu">
                        &nbsp;
                    </div>
                </div>
            </div>

            <DndContainer
                groupName="ScheduleWorkOrders"
                dragHandleSelector={isLoading ? 'none' : '.ScheduleWorkTable__Row__DragHandle'}
                getChildPayload={i => schedule.context?.scheduleWorkOrders[i]}
                onDrop={handleDrop}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                lockAxis="y"
                autoScrollEnabled={false}
                render={ref => (
                    <div
                        ref={ref}
                        className="ScheduleWorkTable__DraggableContainer"
                    >
                        {empty ? (
                            <div className="ScheduleWorkTable__EmptyMessage__Wrapper">
                                <div className="ScheduleWorkTable__EmptyMessage">
                                    No work scheduled
                                </div>
                            </div>
                        ) : (
                            <>
                                {schedule.context?.scheduleWorkOrders.map(wo => (
                                    <Row
                                        key={wo.id}
                                        workOrder={wo}
                                        schedule={schedule}
                                        isLoading={isLoading}
                                        onAssign={onAssign}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                )}
            />
        </div>
    );
}

function Row({
    workOrder,
    schedule,
    isLoading = false,
    onAssign,
}: {
    workOrder: ScheduledWorkOrder;
    schedule: Schedule;
    isLoading?: boolean;
    onAssign: (schedule: Schedule, workOrders: ScheduledWorkOrder[], sortOrder?: number) => void;
}) {
    const dialogManager = useDialogManager();
    const handleMoveDate = useCallback(async () => {
        const newSchedule: Schedule | undefined = await dialogManager.custom(
            ScheduleMoveToDateDialog,
            {
                workOrder,
                currentSchedule: schedule,
            },
        );

        if (newSchedule) {
            onAssign(newSchedule, [workOrder]);
        }
    }, [dialogManager, onAssign, schedule, workOrder]);

    /** The schedule that an item as last moved to (this is not latestScheduleDate) */
    const lastMoveToSchedule = useAppSelector(selectLastMoveToSchedule);

    /** The latest date that this item can be scheduled and still meet its reqeusted date */
    const dtLatestScheduleDate = useMemo(
        () => DateTime.fromISO(workOrder.latestScheduleDate),
        [workOrder.latestScheduleDate],
    );

    /** True if this item can be moved to its latest scheduled date
     * - it is not already scheduled on that date
     * - and date is not in the past */
    const canMoveToLatest = useMemo(() => {
        const dtSched = DateTime.fromISO(schedule.date);
        const isPast = dateIsPast(dtLatestScheduleDate);
        const isSetAlready = dtLatestScheduleDate.hasSame(dtSched, 'day');
        return !isPast && !isSetAlready;
    }, [dtLatestScheduleDate, schedule.date]);

    const [scheduleListQuery] = scheduleApi.useLazyScheduleListQuery();

    const handleMoveToLatest = useCallback(async () => {
        // load the schedule for the selected date from the api
        const queryResult = await scheduleListQuery({
            dateFrom: dtLatestScheduleDate.startOf('day').toISODate() as string,
            dateTo: dtLatestScheduleDate.endOf('day').toISODate() as string,
        }).unwrap();

        // move the item
        const latestSchedule = queryResult[0];
        if (latestSchedule) {
            onAssign(latestSchedule, [workOrder]);
        }
    }, [dtLatestScheduleDate, onAssign, scheduleListQuery, workOrder]);

    // Ability to highlight and scroll to an order from url param
    const [highlightId, setHighlightId] = useUrlQueryState('highlight');
    const isHighlighted = highlightId === workOrder.id;

    const showWorkOrderDetail = useCallback(async () => {
        await dialogManager.custom(WorkOrderDetailModal, {
            workOrderId: workOrder.id,
            tuid: workOrder.tuid,
        });
    }, [dialogManager, workOrder.id, workOrder.tuid]);

    const kebabMenuItems = useMemo(
        () =>
            [
                {
                    label: 'View work order',
                    onClick: showWorkOrderDetail,
                },
                {
                    label: (
                        <>
                            Move to <em>select date...</em>
                        </>
                    ),
                    onClick: handleMoveDate,
                },

                lastMoveToSchedule &&
                    lastMoveToSchedule?.date !== schedule.date && {
                        label: (
                            <>
                                Move to <strong>recent</strong>{' '}
                                <em>
                                    {formatDateRelative(lastMoveToSchedule.date, {
                                        alwaysDate: true,
                                    })}
                                </em>
                            </>
                        ),
                        onClick: () => onAssign(lastMoveToSchedule, [workOrder]),
                    },
                {
                    label: (
                        <>
                            Move to <strong>latest schedule date</strong>{' '}
                            <em>
                                {formatDateRelative(workOrder.latestScheduleDate, {
                                    alwaysDate: true,
                                })}
                            </em>
                        </>
                    ),
                    disabled: !canMoveToLatest,
                    onClick: handleMoveToLatest,
                },
                isHighlighted && {
                    label: 'Clear highlight',
                    onClick: () => setHighlightId(''),
                },
            ].filter(Boolean) as MyMenuButtonItem[],
        [
            canMoveToLatest,
            handleMoveDate,
            handleMoveToLatest,
            isHighlighted,
            lastMoveToSchedule,
            onAssign,
            schedule.date,
            setHighlightId,
            showWorkOrderDetail,
            workOrder,
        ],
    );

    const [hasScrolled, setHasScrolled] = useState(false);
    const rowElem = React.createRef<HTMLTableRowElement>();
    useEffect(() => {
        if (isHighlighted && !hasScrolled && rowElem.current) {
            rowElem.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            setHasScrolled(true);
        }
    }, [hasScrolled, isHighlighted, rowElem]);

    return (
        <Draggable
            className={coalesceClassNames(
                'ScheduleWorkTable__Row',
                isHighlighted && 'ScheduleWorkTable__Row--highlight',
            )}
            render={() => (
                <div
                    role="row"
                    data-id={workOrder.id}
                    ref={rowElem}
                >
                    <Cell
                        className="ScheduleWorkTable__Row__Cell--Handle"
                        value={
                            <>
                                {
                                    <div className="ScheduleWorkTable__Row__DragHandle">
                                        <Icons.DragHandle className="icon " />
                                    </div>
                                }
                            </>
                        }
                    />
                    <a
                        className="ScheduleWorkTable__Row__ClickableWrapper"
                        onClick={showWorkOrderDetail}
                    >
                        {COLUMNS.map(col => (
                            <Cell
                                key={col.key}
                                value={col.value?.(workOrder)}
                                width={col.width}
                                align={col.align}
                                overflow={col.overflow}
                            />
                        ))}
                    </a>
                    <Cell
                        className="ScheduleWorkTable__Row__Cell--Menu"
                        value={
                            <MyMenuKebabButton
                                className="ScheduleWorkTable__Row__MenuButton"
                                disabled={isLoading}
                                menuItems={kebabMenuItems}
                                buttonType="None"
                            />
                        }
                    />
                </div>
            )}
        />
    );
}

function Cell({
    value,
    className,
    width,
    align = 'left',
    overflow = '',
}: {
    value?: React.ReactNode;
    className?: string;
    width?: number;
    align?: 'left' | 'right' | 'center';
    overflow?: '' | 'ellipsis';
}) {
    return (
        <div
            role="cell"
            className={coalesceClassNames('ScheduleWorkTable__Row__Cell', align, className)}
            style={{ width }}
        >
            <div
                className={coalesceClassNames(
                    'ScheduleWorkTable__Row__Cell__Value',
                    overflow && 'ScheduleWorkTable__Row__Cell__Value--ellipsis',
                )}
            >
                {isEmpty(value) ? '-' : value}
            </div>
        </div>
    );
}
