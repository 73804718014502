import z from 'zod';

export const SalesItemLabelConfigSchema = z.object({
    name: z.string(),
    id: z.number(),
    copies: z.number(),
    copies_user_overridable: z.boolean(),
    width_in_units: z.number(),
    height_in_units: z.number(),
    units: z.enum(['in', 'px', 'cm', 'mm']),
});

export type SalesItemLabelConfig = z.infer<typeof SalesItemLabelConfigSchema>;
