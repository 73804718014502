import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import React, { useMemo } from 'react';
import WorkStepCountsProgress from './WorkStepCountsProgress';

/** Progress bar showing a summary of manufacturer orders and their work Item statuses
 * Wrapper for WorkStepCountsProgress */
export default function WorkStepsListProgress({
    steps,
    showPercentage,
}: {
    steps: WorkItemStepBasic[];
    showPercentage?: boolean;
}) {
    const data = useMemo(
        (): Partial<Record<WorkItemStepStatus, number>> =>
            steps?.reduce((acc, step) => {
                acc[step.status] = (acc[step.status] ?? 0) + 1;
                return acc;
            }, {} as Partial<Record<WorkItemStepStatus, number>>),
        [steps],
    );

    return (
        <WorkStepCountsProgress
            statusCounts={data}
            showPercentage={showPercentage}
        />
    );
}
