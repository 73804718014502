import CustomerDetailModal from 'features/customers/components/CustomerDetailModal/CustomerDetailModal';
import customersApi from 'features/customers/customers.api';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function CustomerDetailPage() {
    const { customerId = '' } = useParams();

    // retain url query params when navigating
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const query = customersApi.useCustomerDetailQuery(parseInt(customerId, 10));
    usePageTitle(query?.data?.context.mainCompany.name);

    return customerId ? (
        <>
            <CustomerDetailModal
                model={query?.data}
                isLoading={query.isLoading}
                isError={query.isError}
                close={() => navigate(`/customers?${searchParams}`)}
            />
        </>
    ) : null;
}
