// taken from https://www.npmjs.com/package/react-smooth-dnd 0.11.1
// brought in manually because of weird npm dependency issues
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { constants } from 'smooth-dnd';

const { wrapperClass } = constants;

export interface DraggableProps {
    render?: () => React.ReactElement;
    className?: string;
}

class Draggable extends Component<DraggableProps> {
    public static propsTypes = {
        render: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const clsName = `${this.props.className ? `${this.props.className} ` : ''}`;
        if (this.props.render) {
            return React.cloneElement(this.props.render(), {
                className: `${clsName}${wrapperClass}`,
            });
        }

        return (
            <div
                {...this.props}
                className={`${clsName}${wrapperClass}`}
            >
                {/* eslint-disable-next-line react/prop-types */}
                {this.props.children}
            </div>
        );
    }
}

export default Draggable;
