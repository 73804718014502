import { CircularProgress } from '@mui/material';
import StatusPill from 'components/StatusPill/StatusPill';
import salesApi from 'features/sales/sales.api';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { SalesOrderStatus } from '../../models/SalesOrderStatus';
import './SalesOrderStatusBadge.scss';

export default function SalesOrderStatusBadge({
    statusId,
    status,
    size = 'medium',
    className,
}: {
    statusId?: number;
    status?: SalesOrderStatus;
    size?: 'small' | 'medium';
    className?: string;
}) {
    const query = salesApi.useSalesOrderStatusesQuery();
    const statusToRender = status ?? query.data?.find(s => s.id === statusId);

    return status == null && query.isLoading ? (
        <CircularProgress size={28} />
    ) : statusToRender ? (
        <StatusPill
            className={coalesceClassNames('SalesOrderStatusBadge', className)}
            style={{
                backgroundColor: statusToRender.backgroundColor,
                borderColor: statusToRender.backgroundColor,
                color: statusToRender.textColor,
            }}
            size={size}
        >
            {statusToRender.name}
        </StatusPill>
    ) : (
        <>{statusId}</>
    );
}
