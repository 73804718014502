import { InfoGridRow, InfoGridTable } from 'components/InfoGridTable/InfoGridTable';
import { ProductOption } from 'features/sales/models/ProductOption';
import { ProductOptionGroup } from 'features/sales/models/ProductOptionGroup';
import React from 'react';
import { formatCurrency, isEmpty } from 'utils/helpers';
import './ProductOptionDisplayGrid.scss';

export default function ProductOptionDisplayGrid({
    optionGroups,
}: {
    optionGroups: ProductOptionGroup[];
}) {
    return (
        <>
            {isEmpty(optionGroups) ? (
                <div className="ProductOptionDisplayGrid__SectionEmpty">&ndash;</div>
            ) : (
                <InfoGridTable>
                    {optionGroups.map((group, i) => (
                        <InfoGridRow
                            key={i}
                            label={group.name}
                            value={group.options.map((option, oi) => {
                                return (
                                    <Option
                                        option={option}
                                        key={oi}
                                    />
                                );
                            })}
                        />
                    ))}
                </InfoGridTable>
            )}
        </>
    );
}

function Option({ option }: { option: ProductOption }) {
    return (
        <>
            <div className="ProductOptionDisplayGrid__Option">
                {option.price && (
                    <div className="ProductOptionDisplayGrid__Option__Price">
                        {formatCurrency(option.price)}
                    </div>
                )}
                <div className="ProductOptionDisplayGrid__Option__Desc">
                    {/* For FABRIC options - show itemName. If null or if this is a BASE option, show value */}
                    {option.itemName ?? option.value}
                    {option.quantity && (
                        <>
                            {' × '}
                            {option.quantity}
                        </>
                    )}
                </div>
            </div>
            {option.type === 'FABRIC' && (
                <div className="ProductOptionDisplayGrid__OptionFabric">
                    <FabricDetail
                        label="Brand"
                        value={option.brandName}
                    />
                    <FabricDetail
                        label="Color"
                        value={option.color}
                    />
                    <FabricDetail
                        label="Width"
                        value={option.itemFabricWidth}
                    />
                    <FabricDetail
                        label="Repeat"
                        value={option.itemFabricRepeat}
                    />
                </div>
            )}
        </>
    );
}

function FabricDetail({ label, value }: { label: string; value: string | number | null }) {
    if (!value) {
        return null;
    }
    return (
        <div className="ProductOptionDisplayGrid__FabricDetail">
            <div className="label">{label}</div>
            <div className="value">{value}</div>
        </div>
    );
}
