import { faker } from '@faker-js/faker';
import { fakeSalesOrders } from 'features/sales/faker/SalesFaker';
import { fakeSchedules } from 'features/schedule/faker/ScheduleFaker';
import { WorkItemFieldAppearance } from 'features/workOrders/enums/WorkItemFieldAppearance';
import WorkItemFieldUnits from 'features/workOrders/enums/WorkItemFieldUnits';
import WorkflowStepType from 'features/workOrders/enums/WorkflowStepType';
import { fakeWorkOrders } from 'features/workOrders/faker/WorkOrderFaker';
import { DateTime } from 'luxon';
import StepCanTransition from '../../workOrders/enums/StepCanTransition';
import WorkItemStepStatus from '../../workOrders/enums/WorkItemStepStatus';
import WorkstationPrintMethod from '../enums/WorkstationPrintMethod';
import WorkstationPrintOption from '../enums/WorkstationPrintOption';
import { WorkOrderItemStepHistory } from '../models/WorkOrderItemStepHistory';
import { WorkstationStepDetail } from '../models/WorkstationStepDetail';
import { WorkStationStepSummary } from '../models/WorkstationStepSummary';

faker.seed(42);

const generateRandomRef = (prefix?: string) => {
    const ref = faker.helpers.fromRegExp(/[0-9]{6}-[0-9]{3}-[0-9]{3}/);
    return prefix ? `${prefix}-${ref}` : ref;
};

const fakeWorkflowSteps = [
    {
        id: faker.string.uuid(),
        stepType: WorkflowStepType.Default,
        name: 'Do a thing',
    },
    {
        id: faker.string.uuid(),
        stepType: WorkflowStepType.Default,
        name: 'Cut one of those',
    },
    {
        id: faker.string.uuid(),
        stepType: WorkflowStepType.Default,
        name: 'Measure up that doodad',
    },
    // {
    //     id: faker.string.uuid(),
    //     stepType: WorkflowStepType.Packing,
    //     name: 'Packing',
    // },
];

const generateWorkstationStepSummary = (): WorkStationStepSummary => {
    const schedule = faker.helpers.arrayElement(fakeSchedules);
    const workOrder = faker.helpers.arrayElement(fakeWorkOrders);
    const workItem = faker.helpers.arrayElement(workOrder.context.workOrderItems);
    const workflowStep = faker.helpers.arrayElement(fakeWorkflowSteps);

    const salesOrder = fakeSalesOrders.find(s => `${s.id}` === workOrder.context.salesOrders[0].id);

    if (!salesOrder) {
        throw new Error(
            `generateWorkstationStepSummary: no Sales Order with id ${workOrder.context.salesOrders[0].id}`,
        );
    }

    return {
        id: faker.string.uuid(),
        status: WorkItemStepStatus.Unstarted,
        sortOrder: 0,
        workflowStepId: faker.string.uuid(),
        workOrderItemId: faker.string.uuid(),
        lastTransitionTimestamp: undefined,
        lastTransitionWorkstationId: undefined,
        lastTransitionReason: undefined,
        context: {
            canTransition: StepCanTransition.True,
            salesOrder: {
                id: salesOrder.id,
                tuid: salesOrder.tuid,
            },
            schedule,
            scheduleMeta: {
                sortOrder: fakeSchedules.indexOf(schedule),
            },
            workflowStep,
            workOrder: {
                id: workOrder.id,
                notes: workOrder.notes,
                tuid: workOrder.tuid,
            },
            workOrderItem: {
                id: workItem.id,
                tuid: workItem.tuid,
                description: workItem.description,
                customerOrderReference: workItem.customerOrderReference,
                customerOrderSidemark: workItem.customerOrderSidemark,
                salesOrderLineId: workItem.salesOrderLineId,
                workflowId: faker.string.uuid(),
                workOrderId: workOrder.id,
                isCancelled: false,
                identifier: generateRandomRef('PO'),

                detailFields:
                    workflowStep.stepType === WorkflowStepType.Packing
                        ? []
                        : [
                              {
                                  key: 'Width_MM',
                                  displayName: 'Width (mm)',
                                  value: '1250',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightBlue,
                                  unitSystem: WorkItemFieldUnits.Millimetres,
                              },
                              {
                                  key: 'Height_IN',
                                  displayName: 'Height (in)',
                                  value: '49.25',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightGreen,
                                  unitSystem: WorkItemFieldUnits.Inches,
                              },
                              {
                                  key: 'Empty_MM',
                                  displayName: 'Empty (mm)',
                                  value: '',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightPink,
                                  unitSystem: WorkItemFieldUnits.Millimetres,
                              },
                              {
                                  key: 'Empty_IN',
                                  displayName: 'Empty (in)',
                                  value: '',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightYellow,
                                  unitSystem: WorkItemFieldUnits.Inches,
                              },
                              {
                                  key: 'Zero_MM',
                                  displayName: 'Zero (mm)',
                                  value: 0,
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightBlue,
                                  unitSystem: WorkItemFieldUnits.Millimetres,
                              },
                              {
                                  key: 'Zero_IN',
                                  displayName: 'Zero (in)',
                                  value: 0,
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightPink,
                                  unitSystem: WorkItemFieldUnits.Inches,
                              },
                              {
                                  key: 'ShowIfEmpty',
                                  displayName: 'Show if empty',
                                  value: '',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightGreen,
                                  unitSystem: WorkItemFieldUnits.None,
                              },
                              {
                                  key: 'HideIfEmpty',
                                  displayName: 'Hide if empty (empty)',
                                  value: '',
                                  hideIfEmpty: true,
                                  appearance: WorkItemFieldAppearance.HighlightYellow,
                                  unitSystem: WorkItemFieldUnits.None,
                              },
                              {
                                  key: 'HideIfEmpty2',
                                  displayName: 'Hide if empty (not empty)',
                                  value: 'not empty',
                                  hideIfEmpty: true,
                                  appearance: WorkItemFieldAppearance.HighlightBlue,
                                  unitSystem: WorkItemFieldUnits.None,
                              },
                              {
                                  key: 'Options',
                                  displayName: 'Options',
                                  value: 'Add Chainless Bottom Weights, Single Lock, Triple Lock, 1200 x 1800, Right, Right, Bug Strip per Metre, Lock Guard, Stop Bead',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightYellow,
                                  unitSystem: WorkItemFieldUnits.None,
                              },
                              {
                                  key: 'Notes and Extras',
                                  displayName: 'Notes and Extras',
                                  value: '',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightGreen,
                                  unitSystem: WorkItemFieldUnits.None,
                              },
                              {
                                  key: 'Product Notes hidden on quote',
                                  displayName: 'Product Notes hidden on quote',
                                  value: '',
                                  hideIfEmpty: false,
                                  appearance: WorkItemFieldAppearance.HighlightBlue,
                                  unitSystem: WorkItemFieldUnits.None,
                              },
                          ],
                summaryFields: [
                    // {
                    //     key: 'Detail',
                    //     displayName: null,
                    //     value: faker.helpers.arrayElement([
                    //         'Something with a long value that might not fit well',
                    //         'Another thing that is quite long',
                    //         'This is also a pretty long value',
                    //     ]),
                    //     hideIfEmpty: false,
                    //     unitSystem: WorkItemFieldUnits.None,
                    // },
                    {
                        key: 'Color',
                        displayName: null,
                        value: faker.helpers.arrayElement(['Midnight Charcoal', 'White']),
                        hideIfEmpty: false,
                        unitSystem: WorkItemFieldUnits.None,
                    },
                    {
                        key: 'Width',
                        displayName: null,
                        value: faker.helpers.arrayElement(['2100', 2400, '', 0]),
                        hideIfEmpty: false,
                        unitSystem: WorkItemFieldUnits.Millimetres,
                    },
                    {
                        key: 'Height',
                        displayName: null,
                        value: faker.helpers.arrayElement([5, 14.25, 1.1875, '', 0]),
                        hideIfEmpty: false,
                        unitSystem: WorkItemFieldUnits.Inches,
                    },
                ],
            },
            workstationLatest: null,
        },
    };
};

const fakeWorkstationStepSummaryToDetails = (
    summary: WorkStationStepSummary,
): WorkstationStepDetail => {
    return {
        ...summary,
        context: {
            ...summary.context,
            canTransition: summary.context.canTransition,
            workflowStepInstance: {
                instanceId: faker.string.uuid(),
                workflowStepId: faker.string.uuid(),
                showDefaultActions: true,
                additionalActions: [],
            },
            workstationLatest: undefined,
            workOrderItemStepsBlocking: [],
            workOrderItemBlocking: [],
        },
    };
};

export function generateWorkItemStepHistory() {
    const history: WorkOrderItemStepHistory = {
        id: faker.string.uuid(),
        principalId: faker.string.uuid(),
        state: WorkItemStepStatus.Unstarted,
        timestamp: DateTime.now().toISO() as string,
        workflowStepId: faker.string.uuid(),
        workstationId: faker.string.uuid(),
        context: {
            principal: {
                deletedAt: null,
                firstName: 'Dave',
                id: 1,
                lastName: 'Lister',
                manufacturerId: 1,
                rememberToken: '',
            },
            workstation: {
                allowMultipleInProgress: true,
                id: faker.string.uuid(),
                name: 'Workstation 1',
                printOption: WorkstationPrintOption.ExpressOnStart,
                printMethod: WorkstationPrintMethod.Open,
            },
        },
    };
    return history;
}

export const fakeWorkstationSteps = faker.helpers.multiple(generateWorkstationStepSummary, {
    count: 22,
});

// make first item in progress
fakeWorkstationSteps[0].status = WorkItemStepStatus.OnHold;

// set a few to 'coming up'
for (let i = 7; i < 11; i++) {
    fakeWorkstationSteps[i].context.canTransition = StepCanTransition.FalseBlockingSteps;
}

// set a few to 'completed'
for (let i = 12; i < 14; i++) {
    fakeWorkstationSteps[i].status = WorkItemStepStatus.Completed;
}
export const fakeWorkstationStepDetails = fakeWorkstationSteps.map(
    fakeWorkstationStepSummaryToDetails,
);
