import z from 'zod';

export const CompanySchema = z.object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    timezone: z.string(),
    accountId: z.number(),
    phone: z.string().nullable().optional(),
});
export type Company = z.infer<typeof CompanySchema>;
