import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import WorkStepsListProgress from 'features/sales/components/WorkStepCountsProgress/WorkStepsListProgress';
import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import WorkItemStepUiState, {
    computeWorkItemStepUiState,
} from 'features/workOrders/enums/WorkItemStepUiState';
import { WorkItemStepBasic } from 'features/workOrders/models/WorkItemStepBasic';
import { WorkOrderItemDetail } from 'features/workOrders/models/WorkOrderItemDetail';
import { WorkOrderItemStepSummary } from 'features/workOrders/models/WorkOrderItemStepSummary';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatDateTimeFriendlyRelative } from 'utils/dateHelpers';
import WorkItemStepHistoryModal from '../WorkItemStepHistoryModal/WorkItemStepHistoryModal';
import WorkItemStepStatusBadge from '../WorkItemStepStatusBadge/WorkItemStepStatusBadge';
import './WorkItemStepList.scss';

const COLUMNS = ColumnBuilder<WorkOrderItemStepSummary>()
    .column({
        label: 'Step',
        key: 'step',
        getValue: step => step.context.workflowStep.name,
    })
    .column({
        label: 'Status',
        key: 'status',
        render: step => {
            const uiState = computeWorkItemStepUiState(step.status, step.context.canTransition);
            return (
                <WorkItemStepStatusBadge
                    uiState={uiState}
                    lastTransitionReason={step.lastTransitionReason ?? null}
                    badgeType="pill"
                />
            );
        },
    })
    .column({
        label: 'Last transition',
        key: 'lastTansition',
        render: step => (
            <div className="WorkItemStepList__TransitionCell">
                {step.lastTransitionTimestamp && (
                    <div className="WorkItemStepList__TransitionCell__Date">
                        {formatDateTimeFriendlyRelative(step.lastTransitionTimestamp)}
                    </div>
                )}
                {step.context.workstationLatest && (
                    <div className="WorkItemStepList__TransitionCell__Workstation">
                        {step.context.workstationLatest?.name}
                    </div>
                )}
            </div>
        ),
    })
    .build();

const UI_STATE_ORDER = [
    WorkItemStepUiState.InProgress,
    WorkItemStepUiState.OnHold,
    WorkItemStepUiState.Ready,
    WorkItemStepUiState.ComingUp,
    WorkItemStepUiState.Completed,
    WorkItemStepUiState.Skipped,
];

export default function WorkItemStepList({
    workItem,
    className,
}: {
    workItem: WorkOrderItemDetail;
    className?: string;
}) {
    const dialogManager = useDialogManager();

    const showHistory = useCallback(
        (step: WorkItemStepBasic) => {
            dialogManager.custom(WorkItemStepHistoryModal, {
                model: step,
            });
        },
        [dialogManager],
    );

    // list of steps that have not been skipped
    // these count towards the progress bar
    const progressSteps = workItem.context.workOrderItemSteps.filter(
        s =>
            s.status !== WorkItemStepStatus.Skipped &&
            s.status !== WorkItemStepStatus.SkippedLocked,
    );

    progressSteps.sort((a, b) => {
        // primary sort by ui state using predefined order
        const uiStateA = computeWorkItemStepUiState(a.status, a.context.canTransition);
        const uiStateB = computeWorkItemStepUiState(b.status, b.context.canTransition);
        if (uiStateA !== uiStateB) {
            return UI_STATE_ORDER.indexOf(uiStateA) - UI_STATE_ORDER.indexOf(uiStateB);
        }
        // secondary sort by last transition timestamp - most recent first
        if (a.lastTransitionTimestamp !== b.lastTransitionTimestamp) {
            return (a.lastTransitionTimestamp ?? '') > (b.lastTransitionTimestamp ?? '') ? -1 : 1;
        }
        // tertiary sort by sort order
        if (a.sortOrder !== b.sortOrder) {
            return (a.sortOrder ?? 999999) - (b.sortOrder ?? 999999);
        }
        // last ditch - sort by id
        return a.id > b.id ? 1 : -1;
    });

    const completeCount =
        progressSteps?.filter(s =>
            [WorkItemStepStatus.Completed, WorkItemStepStatus.CompletedLocked].includes(s.status),
        ).length ?? 0;

    const completePercent = progressSteps.length
        ? Math.round((completeCount / progressSteps.length) * 100)
        : 0;

    // list of skipped steps - these do not count towards the progress bar
    const skippedSteps = workItem.context.workOrderItemSteps.filter(s =>
        [WorkItemStepStatus.Skipped, WorkItemStepStatus.SkippedLocked].includes(s.status),
    );

    if (!progressSteps) {
        return null;
    }

    return (
        <div className={coalesceClassNames('WorkItemStepList', className)}>
            <div className="WorkItemStepList__Progress">
                <div className="WorkItemStepList__Progress__Counts">
                    <strong>
                        {progressSteps.length} {progressSteps.length === 1 ? 'step' : 'steps'}
                    </strong>
                    <div>
                        {completeCount} complete ({completePercent}%)
                    </div>
                </div>
                <div className="WorkItemStepList__Progress__Bar">
                    <WorkStepsListProgress steps={progressSteps} />
                </div>
            </div>

            <DataTable
                className="WorkItemStepList__Table"
                columns={COLUMNS}
                data={progressSteps}
                onRowClick={showHistory}
                showHeader="none"
                zebra="light"
                rowEndIcon={<Icons.ChevronRight className="chevron" />}
            />

            {skippedSteps.length > 0 && (
                <>
                    <h3 className="WorkItemStepList__SkippedTitle">
                        Skipped steps ({skippedSteps.length})
                    </h3>
                    <DataTable
                        className="WorkItemStepList__Table"
                        columns={COLUMNS}
                        data={skippedSteps}
                        onRowClick={showHistory}
                        showHeader="none"
                        zebra="light"
                        rowEndIcon={<Icons.ChevronRight className="chevron" />}
                    />
                </>
            )}
        </div>
    );
}
