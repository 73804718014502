import { faker } from '@faker-js/faker';
import { DateTime } from 'luxon';
import InventoryMovementBatchType from '../enums/InventoryMovementBatchType';
import InventoryReceiptStatus from '../enums/InventoryReceiptStatus';
import InventoryType from '../enums/InventoryType';
import LocationType, { LocationTypeDisplay } from '../enums/LocationType';
import UnitOfMeasureFamily from '../enums/UnitOfMeasureFamily';
import { Inventory } from '../models/Inventory';
import { InventoryContainer } from '../models/InventoryContainer';
import { InventoryGroupSummary } from '../models/InventoryGroupSummary';
import { InventoryLocationPath } from '../models/InventoryLocationsResult';
import { InventoryItemMovement } from '../models/InventoryMovementBatch';
import { InventoryReceiptDetail } from '../models/InventoryReceiptDetail';
import { Location } from '../models/Location';
import { Supplier } from '../models/Supplier';

faker.seed(42);
function generateFakeSupplier(): Supplier {
    return {
        id: faker.string.uuid(),
        name: faker.company.name(),
        userDefinedFields: {},
    };
}

export const fakeSuppliers = faker.helpers.multiple(generateFakeSupplier, { count: 5 });

export const fakeUnitsOfMeasure = [
    {
        name: 'Millimetre',
        key: 'MILLIMETRE',
        symbol: 'mm',
        family: UnitOfMeasureFamily.Length,
    },
    {
        name: 'Centimetre',
        key: 'CENTIMETRE',
        symbol: 'cm',
        family: UnitOfMeasureFamily.Length,
    },
    {
        name: 'Metre',
        key: 'METRE',
        symbol: 'm',
        family: UnitOfMeasureFamily.Length,
    },
    {
        name: 'Kilometre',
        key: 'KILOMETRE',
        symbol: 'km',
        family: UnitOfMeasureFamily.Length,
    },
    {
        name: 'Unit',
        key: 'UNIT',
        symbol: '',
        family: UnitOfMeasureFamily.Unit,
    },
    {
        name: 'Millilitre',
        key: 'MILLILITRE',
        symbol: 'mL',
        family: UnitOfMeasureFamily.Volume,
    },
    {
        name: 'Litre',
        key: 'LITRE',
        symbol: 'L',
        family: UnitOfMeasureFamily.Volume,
    },
    {
        name: 'Kilolitre',
        key: 'KILOLITRE',
        symbol: 'kL',
        family: UnitOfMeasureFamily.Volume,
    },
    {
        name: 'Gram',
        key: 'GRAM',
        symbol: 'g',
        family: UnitOfMeasureFamily.Weight,
    },
    {
        name: 'Kilogram',
        key: 'KILOGRAM',
        symbol: 'kg',
        family: UnitOfMeasureFamily.Weight,
    },
    {
        name: 'Ton',
        key: 'TON',
        symbol: 't',
        family: UnitOfMeasureFamily.Weight,
    },
];

function generateFakeLocation(parent?: Location): Location[] {
    const locationType = !parent
        ? LocationType.Warehouse
        : parent.locationType === LocationType.Warehouse
        ? LocationType.Aisle
        : parent.locationType === LocationType.Aisle
        ? LocationType.Bay
        : LocationType.Bucket;

    const self = {
        id: faker.string.uuid(),
        parentLocationId: parent?.id,
        parentLocationName: parent?.name,
        name: `${LocationTypeDisplay.display(locationType)} ${faker.number.int(100)}`,
        canAllocate: faker.helpers.arrayElement([true, false]),
        canStore: faker.helpers.arrayElement([true, false]),
        isArchived: false,
        locationType,
        locationTypeDisplay: LocationTypeDisplay.display(locationType) || '',
        children: [],
        userDefinedFields: {},
    };

    const childCount = locationType === LocationType.Bucket ? 0 : 2;
    if (childCount > 0) {
        return [
            self,
            ...faker.helpers
                .multiple(() => generateFakeLocation(self), { count: childCount })
                .flat(),
        ];
    }
    return [self];
}

// generate locations
export const fakeLocations = faker.helpers.multiple(generateFakeLocation, { count: 2 }).flat();

export function generateLocationPath(locationId: string, locations: Location[] = fakeLocations) {
    let node = locations.find(l => l.id === locationId);
    const path = [] as InventoryLocationPath[];
    while (node) {
        path.unshift({
            id: node.id,
            name: node.name,
            locationType: node.locationType,
        });
        node = node.parentLocationId
            ? // eslint-disable-next-line @typescript-eslint/no-loop-func
              fakeLocations.find(l => l.id === node?.parentLocationId)
            : undefined;
    }
    return path;
}

function generateFakeInventoryGroupSummary(): InventoryGroupSummary {
    return {
        id: faker.string.uuid(),
        name: faker.word.noun(),
    };
}

export const fakeInventoryGroupSummaries = faker.helpers.multiple(
    generateFakeInventoryGroupSummary,
    { count: 5 },
);

function generateFakeInventory(): Inventory {
    const supplier = faker.helpers.arrayElement(fakeSuppliers);
    const group = faker.helpers.arrayElement(fakeInventoryGroupSummaries);
    return {
        id: faker.string.uuid(),
        tenantSupplierId: supplier.id,
        tenantSupplierName: supplier.name,
        attributes: {},
        description: faker.word.words({ count: { min: 5, max: 10 } }),
        hasSerialNumber: false,
        inventoryGroupId: group.id,
        inventoryGroupName: group.name,
        inventoryType: faker.helpers.enumValue(InventoryType),
        maximumQuantityLevel: faker.number.int({ min: 10, max: 99 }),
        minimumQuantityLevel: faker.number.int({ min: 1, max: 10 }),
        notes: faker.word.words({ count: { min: 1, max: 10 } }),
        partNumber: faker.string.alphanumeric(10),
        reorderQuantity: faker.number.int({ min: 0, max: 30 }),
        supplierPartNumber: faker.string.alphanumeric(10),
        userDefinedFields: {},
        isArchived: false,
    };
}

export const fakeInventory = faker.helpers.multiple(generateFakeInventory, { count: 250 });

function generateFakeInventoryMovement(): InventoryItemMovement {
    const location = faker.helpers.arrayElement(fakeLocations.filter(l => l.canStore));
    const warehouse = fakeLocations.find(l => !l.parentLocationId); // TODO this properly

    let qty = faker.number.int({ min: -5, max: 5 });
    if (qty === 0) {
        qty = -1;
    }
    return {
        id: faker.string.uuid(),
        batchId: faker.string.uuid(),
        batchNumber: `M${faker.string.numeric({ length: 10 })}`,
        locationId: location?.id || '',
        locationName: location?.name || '',
        warehouseId: warehouse?.id || '',
        warehouseName: warehouse?.name || '',
        date: DateTime.now()
            .minus({ seconds: faker.number.int({ min: 600, max: 6000000 }) })
            .toISO() as string,
        quantity: qty,
        movementType: faker.helpers.enumValue(InventoryMovementBatchType),
        notes: faker.word.words({ count: { min: 1, max: 10 } }),
    };
}

export const fakeInventoryMovements = faker.helpers.multiple(generateFakeInventoryMovement, {
    count: 25,
});

export function generateFakeInventoryContainer(): InventoryContainer {
    return {
        id: faker.string.uuid(),
        tuid: `IC-${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        notes: faker.word.words(),
        quantityAllocated: faker.number.int(100),
        quantityOnHand: faker.number.int(100),
        reference: faker.string.alpha(5),
    };
}

export function generateFakeInventoryReceipt(): InventoryReceiptDetail {
    const parentLocation = faker.helpers.arrayElement(
        fakeLocations.filter(l => l.locationType === LocationType.Warehouse),
    );
    const validLocations = fakeLocations.filter(
        l => l.canStore && generateLocationPath(l.id, fakeLocations)[0].id === parentLocation.id,
    );
    return {
        id: faker.string.uuid(),
        isArchived: faker.helpers.maybe(() => true) || false,
        tuid: `REC${faker.number.int({ min: 1, max: 99999 }).toString().padStart(5, '0')}`,
        status: faker.helpers.enumValue(InventoryReceiptStatus),
        locationId: parentLocation.id,
        notes: faker.string.alpha(),
        dateReceived: DateTime.fromISO(
            faker.date.past({ refDate: '2024-01-01' }).toISOString(),
        ).toFormat('yyyy-MM-dd'),
        inventory: faker.helpers.multiple(() => {
            const inventory = fakeInventory[faker.number.int(fakeInventory.length - 1)];
            return {
                id: inventory.id,
                totalQuantity: faker.number.int(100),
                locationAssignments: faker.helpers.multiple(() => {
                    const location = faker.helpers.arrayElement(validLocations);
                    return {
                        locationId: location.id,
                        quantity: faker.number.int(100),
                        context: {
                            location,
                            path: generateLocationPath(location.id),
                        },
                    };
                }),
                context: {
                    inventory,
                },
            };
        }),
    };
}

export const fakeInventoryReceipts = faker.helpers.multiple(generateFakeInventoryReceipt, {
    count: 30,
});
