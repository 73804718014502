import WorkItemStatus from 'features/workOrders/enums/WorkItemStatus';
import { dateOnly } from 'utils/dateSchemas';
import z from 'zod';

export const ScheduledWorkOrderSchema = z.object({
    id: z.string(),
    daysRequired: z.number(),
    estimatedCompletionDate: z.string(),
    identifier: z.string(),
    latestScheduleDate: z.string(),
    notes: z.string(),
    requestedDate: dateOnly(),
    slackDays: z.number(),
    sortOrder: z.number(),
    tuid: z.string(),
    workstationGroupAssignment: z
        .object({
            id: z.string(),
            name: z.string(),
            warehouseLocationId: z.string(),
        })
        .nullable(),
    context: z.object({
        workOrderItems: z.array(
            z.object({
                id: z.string(),
                context: z.object({
                    workOrderItemState: z.nativeEnum(WorkItemStatus),
                }),
            }),
        ),
    }),
});

export type ScheduledWorkOrder = z.infer<typeof ScheduledWorkOrderSchema>;
