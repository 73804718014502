import MyEditModal from 'components/MyEditModal/MyEditModal';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import customersApi from 'features/customers/customers.api';
import { CustomerDetail } from 'features/customers/models/CustomerDetail';
import { CustomerTaxExemption } from 'features/customers/models/CustomerTaxExemption';
import { USStateDisplay } from 'features/customers/models/TaxExemptions';
import React from 'react';
import './CustomerTaxExemptionEditModal.scss';

export default function CustomerTaxExemptionEditModal({
    taxExemption,
    customer,
    close,
}: {
    taxExemption: CustomerTaxExemption;
    customer: CustomerDetail;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = customersApi.useCustomerUpdateMutation();

    const isNew = !customer.context.customerConfig.taxExemptions?.includes(taxExemption);

    const save = async (editModel: CustomerTaxExemption) => {
        // find the index of tax exemptions in the customer model and replace it with the new one
        // or append it to the end of the list
        const taxExemptions = [...(customer.context.customerConfig.taxExemptions ?? [])];
        if (isNew) {
            taxExemptions.push(editModel);
        } else {
            // editing an existing tax exemption
            // remove it and replace with a new one
            const index = taxExemptions.findIndex(t => t === taxExemption);
            taxExemptions.splice(index, isNew ? 0 : 1, editModel);
        }
        if (!customer.context.customerConfig) {
            return;
        }

        const customerCopy: CustomerDetail = {
            ...customer,
            context: {
                ...customer.context,
                customerConfig: {
                    ...customer.context.customerConfig,
                    taxExemptions,
                },
            },
        };

        await saveMutation(customerCopy).unwrap();
        close?.();
    };

    return (
        <MyEditModal
            className="CustomerTaxExemptionEditModal"
            model={taxExemption}
            editImmediately={true}
            fullHeight={false}
            onSave={save}
            close={close}
            isSaving={saveMutationState.isLoading}
            title={isNew ? 'New Tax Exemption' : 'Edit Tax Exemption'}
            mobileTitle="Customer"
        >
            {({ editModel, updateField, isSaving }) => {
                // check if the state already exists in the customer exemptions list
                // don't allow duplicates
                // You can't edit state in existing records, so only need to do this check for new ones
                const isStateInvalid =
                    isNew &&
                    customer.context.customerConfig.taxExemptions?.some(
                        t => t.state === editModel.state,
                    );
                return (
                    <PropertyContainer>
                        <PropertyEditSelect
                            label="State"
                            value={editModel.state}
                            options={USStateDisplay.options}
                            onChange={state => updateField({ state })}
                            fullWidth
                            disabled={isSaving || !isNew}
                            validationRequired
                            validationCustom={isStateInvalid && 'This state is already added'}
                        />
                        <PropertyEditText
                            label="Reason"
                            value={editModel.reason}
                            onChange={reason => updateField({ reason })}
                            disabled={isSaving}
                            validationRequired
                        />
                    </PropertyContainer>
                );
            }}
        </MyEditModal>
    );
}
