import { rest } from 'msw';

import { faker } from '@faker-js/faker';
import Env from 'config/Env';
import { fakeWorkOrders } from 'features/workOrders/faker/WorkOrderFaker';
import { DateTime } from 'luxon';
import { isNone } from 'utils/helpers';
import { fakeSchedules } from './faker/ScheduleFaker';
import { Schedule } from './models/Schedule';
import { ScheduledWorkOrder } from './models/ScheduledWorkOrder';
import { ScheduleAssignParams } from './schedule.api';

const BASE_URL = `${Env.API_BASE_URL}`;

function getFakeSchedulesByDate(
    dateFrom: string,
    dateTo: string,
    options?: { withWorkOrders?: boolean },
) {
    // loop through each day, fill in missing dates
    // mon-fri only
    let date = DateTime.fromISO(dateFrom).startOf('day');
    const dtEnd = DateTime.fromISO(dateTo).endOf('day');

    const result: Schedule[] = [];
    while (date.diff(dtEnd).as('milliseconds') < 0) {
        // ingore sat sun
        if (date.weekday < 6) {
            // eslint-disable-next-line @typescript-eslint/no-loop-func
            let schedule = fakeSchedules.find(s => {
                const dt = DateTime.fromISO(s.date);
                return dt.hasSame(date, 'day');
            });

            if (!schedule) {
                // create empty schedule
                schedule = {
                    id: '00000000-0000-0000-0000-000000000000',
                    date: date.toISODate() as string,
                    context: options?.withWorkOrders
                        ? {
                              scheduleWorkOrders: [],
                          }
                        : undefined,
                };
            }

            // populate work orders
            if (schedule.context) {
                schedule.context.scheduleWorkOrders = getScheduledWorkOrders(schedule);
            }

            result.push(schedule);
        }
        date = date.plus({ days: 1 });
    }
    return result;
}

/** Get list of work orders by schedule and transform to ScheduledWorkOrder */
function getScheduledWorkOrders(schedule: Schedule): ScheduledWorkOrder[] {
    const workOrders = fakeWorkOrders.filter(w => w.context.schedule.date === schedule.date);
    const dt = DateTime.fromISO(schedule.date);

    const daysRequired = 7;
    const requestedDate = dt.plus({
        days: 14,
    });

    const estimatedCompletionDate = dt.plus({ days: daysRequired });

    return workOrders.map((wo, index) => {
        const scheduledOrder: ScheduledWorkOrder = {
            ...wo,
            sortOrder: index,
            daysRequired,
            requestedDate: requestedDate.toISODate() as string,
            estimatedCompletionDate: estimatedCompletionDate.toISODate() as string,
            latestScheduleDate: requestedDate.plus({ days: daysRequired }).toISODate() as string,
            slackDays: Math.ceil(requestedDate.diff(estimatedCompletionDate).as('days')),

            // identifier is configurable to be one of
            // * work order reference
            // * sales order reference
            // * customer order reference
            // * customer order sidemark
            identifier: wo.context.workOrderItems[0].customerOrderSidemark,

            workstationGroupAssignment: {
                id: faker.string.uuid(),
                name: faker.word.words(2),
                warehouseLocationId: faker.string.uuid(),
            },
            context: {
                workOrderItems: wo.context.workOrderItems.map(wi => ({
                    id: wi.id,
                    context: {
                        workOrderItemState: wi.context.workOrderItemState,
                    },
                })),
            },
        };
        return scheduledOrder;
    });
}

export const scheduleHandlers = [
    rest.post(`${BASE_URL}/schedule`, (req, res, ctx) => {
        const data = getFakeSchedulesByDate((req.body as any)?.from, (req.body as any)?.to, {
            withWorkOrders: false,
        });
        return res(ctx.delay(500), ctx.status(200), ctx.json(data));
    }),

    rest.post(`${BASE_URL}/schedule/withworkorders`, (req, res, ctx) => {
        const data = getFakeSchedulesByDate((req.body as any)?.from, (req.body as any)?.to, {
            withWorkOrders: true,
        });

        return res(ctx.delay(500), ctx.status(200), ctx.json(data));
    }),

    rest.post(`${BASE_URL}/schedule/:scheduleId/scheduleworkorders`, (req, res, ctx) => {
        const body = req.body as ScheduleAssignParams;
        const allWorkOrders = fakeSchedules.map(s => s.context?.scheduleWorkOrders ?? []).flat();

        // determine index in target list
        const targetList = fakeSchedules.find(s => s.id === body.scheduleId)?.context
            ?.scheduleWorkOrders;
        if (!targetList) {
            return res(
                ctx.delay(500),
                ctx.status(400),
                ctx.json({ message: 'invalid scheduleId' }),
            );
        }
        let finalIndex = isNone(body.sortOrder)
            ? targetList.length
            : targetList.findIndex(w => w.sortOrder === body.sortOrder);

        if (body.position === 'BELOW') {
            finalIndex += 1;
        }

        // remove from source lists
        body.workOrders.forEach(id => {
            const sourceList = fakeSchedules.find(
                s => !!s.context?.scheduleWorkOrders.find(w => w.id === id),
            )?.context?.scheduleWorkOrders;

            if (sourceList) {
                const sourceIndex = sourceList.findIndex(w => w.id === id);
                if (!isNone(sourceIndex) && sourceIndex > -1) {
                    sourceList.splice(sourceIndex, 1);
                }
            }
        });

        // append to targetList
        const workOrders = body.workOrders
            .map(id => allWorkOrders.find(w => w?.id === id))
            .filter(Boolean) as ScheduledWorkOrder[];

        targetList.splice(finalIndex, 0, ...workOrders);

        // update sort orders
        targetList.forEach((s, i) => {
            s.sortOrder = i + 1;
        });

        return res(ctx.delay(500), ctx.status(200));
    }),
];
