import createDisplayEnum, { EnumDisplayLabels } from 'utils/createDisplayEnum';

enum WorkstationPrintMethod {
    Open = 'OPEN',
    Download = 'DOWNLOAD',
    AutoPrint = 'AUTO_PRINT',
    Share = 'SHARE',
}

export default WorkstationPrintMethod;

const labels: EnumDisplayLabels<typeof WorkstationPrintMethod> = {
    Open: 'Open',
    Download: 'Download',
    AutoPrint: 'Print',
    Share: 'Share',
};

export const WorkstationPrintMethodDisplay = createDisplayEnum(WorkstationPrintMethod, labels);
