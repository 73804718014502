import WorkItemStepStatus from 'features/workOrders/enums/WorkItemStepStatus';
import { dateTimeInstant } from 'utils/dateSchemas';
import z from 'zod';

export const SalesOrderWorkStepSummarySchema = z.object({
    statusCounts: z.record(z.nativeEnum(WorkItemStepStatus), z.number()),
    latestTransitionStep: z
        .object({
            stepName: z.string(),
            lastTransitionTimestamp: dateTimeInstant().nullable().optional(),
            status: z.nativeEnum(WorkItemStepStatus),
        })
        .nullable()
        .optional(),
});

export type SalesOrderWorkStepSummary = z.infer<typeof SalesOrderWorkStepSummarySchema>;

// Api returns a hash of sales item id to summary
export const SalesOrderWorkStepSummaryResultSchema = z.record(
    z.string(), // SalesItem.id as string
    SalesOrderWorkStepSummarySchema,
);

export type SalesOrderWorkStepSummaryResult = z.infer<typeof SalesOrderWorkStepSummaryResultSchema>;
