import { ApiTagType, api } from 'services/api';
import { Workstation, WorkstationSchema } from './models/Workstation';

import {
    WorkstationGroup,
    WorkstationGroupSchema,
} from 'features/workOrders/models/WorkstationGroup';
import z from 'zod';
import {
    WorkOrderItemStepHistory,
    WorkOrderItemStepHistorySchema,
} from './models/WorkOrderItemStepHistory';
import { WorkstationBasic, WorkstationBasicSchema } from './models/WorkstationBasic';
import { WorkstationStepDetail, WorkstationStepDetailSchema } from './models/WorkstationStepDetail';
import {
    WorkStationStepSummary,
    WorkstationStepSummarySchema,
} from './models/WorkstationStepSummary';
import { WorkstationStepTransitionParams } from './models/WorkstationStepTransitionParams';

type GetWorkOrderItemStepDetailParams = {
    stationId: string;
    stepId: string;
};

type WorkstationStepsQueryParams = {
    stationId: string;
    barcode: string;
};

const workstationsApi = api.injectEndpoints({
    endpoints: build => ({
        getWorkstations: build.query<Workstation[], void>({
            query: () => ({
                url: '/workstations',
                method: 'GET',
            }),
            transformResponse: (result: unknown) => z.array(WorkstationSchema).parse(result),
        }),

        getWorkstationReadySteps: build.query<WorkStationStepSummary[], string>({
            query: (stationId: string) => ({
                url: `/workstations/${stationId}/workordersteps/ready`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(WorkstationStepSummarySchema).parse(result),
            providesTags: [ApiTagType.WorkstationStep],
        }),

        getWorkstationComingUpSteps: build.query<WorkStationStepSummary[], string>({
            query: (stationId: string) => ({
                url: `/workstations/${stationId}/workordersteps/notready`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(WorkstationStepSummarySchema).parse(result),
            providesTags: [ApiTagType.WorkstationStep],
        }),

        getWorkstationCompletedSteps: build.query<WorkStationStepSummary[], string>({
            query: (stationId: string) => ({
                url: `/workstations/${stationId}/workordersteps/completed`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(WorkstationStepSummarySchema).parse(result), // dont apply sort here, leave in order from BE
            providesTags: [ApiTagType.WorkstationStep],
        }),

        getWorkstationStepDetail: build.query<
            WorkstationStepDetail,
            GetWorkOrderItemStepDetailParams
        >({
            query: (params: GetWorkOrderItemStepDetailParams) => ({
                url: `/workstations/${params.stationId}/workordersteps/${params.stepId}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                if (!result) {
                    throw new Error('getWorkOrderItemStepDetail returned no content');
                }
                return WorkstationStepDetailSchema.parse(result);
            },
            providesTags: result =>
                result ? [{ type: ApiTagType.WorkstationStep, id: result.id }] : [],
        }),

        workstationStepsQuery: build.query<WorkStationStepSummary[], WorkstationStepsQueryParams>({
            query: (params: WorkstationStepsQueryParams) => ({
                url: `/workstations/${params.stationId}/workordersteps/query`,
                method: 'POST',
                data: {
                    workOrderItemTuid: params.barcode,
                },
            }),
            transformResponse: (result: unknown) =>
                z.array(WorkstationStepSummarySchema).parse(result),
            providesTags: [ApiTagType.WorkstationStep],
        }),

        workstationStepTransition: build.mutation<void, WorkstationStepTransitionParams>({
            query: params => ({
                url: `/workstations/${params.stationId}/workordersteps/${params.stepId}/transition`,
                method: 'PUT',
                data: {
                    from: params.from,
                    to: params.to,
                    reason: params.reason,
                },
            }),

            // Note - InvalidatesTags and onQueryStarted don't play well together
            // It seems to prevent pessimistic updates from applying
            // So invalidation is done within onQueryStarted down the bottom
            async onQueryStarted({ stationId, stepId, to }, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of step details
                // So that we dont have an awkward pause between performing the transition
                // and refreshing the data from the BE
                dispatch(
                    workstationsApi.util.updateQueryData(
                        'getWorkstationStepDetail',
                        { stationId, stepId },
                        draft => {
                            Object.assign(draft, { status: to });
                        },
                    ),
                );

                // We also need to immediately update the ready query
                // to prevent a paused item being force opened again because the FE thinks it's still in progress
                dispatch(
                    workstationsApi.util.updateQueryData(
                        'getWorkstationReadySteps',
                        stationId,
                        data => {
                            const item = data.find(d => d.id === stepId);
                            if (item) {
                                Object.assign(item, { status: to });
                            }
                        },
                    ),
                );

                // invalidate cache
                // this will cause detail and any list views currently on screen to refetch
                dispatch(
                    api.util.invalidateTags([
                        ApiTagType.WorkOrder,
                        ApiTagType.WorkItem,
                        ApiTagType.WorkstationStep,
                        ApiTagType.SalesOrder,
                    ]),
                );
            },
        }),

        workItemStepHistory: build.query<WorkOrderItemStepHistory[], string>({
            query: stepId => ({
                url: `/workorderitemstep/${stepId}/history`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) =>
                z.array(WorkOrderItemStepHistorySchema).parse(result),
            providesTags: (req, res, id) => [
                { type: ApiTagType.WorkItemStepHistory, id },
                { type: ApiTagType.WorkItem, id },
            ],
        }),

        workItemStepWorkstationCandidates: build.query<WorkstationBasic[], string>({
            query: stepId => ({
                url: `/workorderitemstep/${stepId}/workstationcandidates`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => z.array(WorkstationBasicSchema).parse(result),
            providesTags: (req, res, id) => [
                { type: ApiTagType.WorkItemStepHistory, id },
                { type: ApiTagType.WorkItem, id },
            ],
        }),

        /** Generate labels for a workstation step - old route - to be removed soon */
        workItemStepGenerateLabelsOld: build.mutation<
            string,
            {
                workstationId: string;
                stepId: string;
                copies: number | null;
                labelIds: number[];
            }
        >({
            query: ({ workstationId, stepId, copies, labelIds }) => ({
                url: `/workstations/${workstationId}/workordersteps/${stepId}/labels`,
                method: 'POST',
                data: {
                    copies,
                    labelIds,
                },
                responseType: 'blob',
            }),
            transformResponse: (result: Blob) => {
                const fileUrl = URL.createObjectURL(result);
                return fileUrl;
            },
        }),

        workstationGroupList: build.query<WorkstationGroup[], void>({
            query: () => ({
                url: `/workstationgroup`,
                method: 'POST',
                data: {
                    data: null,
                    meta: {},
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    data: z.array(WorkstationGroupSchema),
                });
                return schema.parse(result).data;
            },
        }),
    }),
});

export default workstationsApi;
