import { Tooltip } from '@mui/material';
import WorkItemStepStatus, {
    WorkItemStepStatusDisplay,
} from 'features/workOrders/enums/WorkItemStepStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './WorkStepCountsProgress.scss';

// Progress bar showing work step progress
export default function WorkStepCountsProgress({
    statusCounts,
    showPercentage,
}: {
    statusCounts: Partial<Record<WorkItemStepStatus, number>>;
    showPercentage?: boolean;
}) {
    const countCompleted =
        (statusCounts[WorkItemStepStatus.Completed] ?? 0) +
        (statusCounts[WorkItemStepStatus.CompletedLocked] ?? 0);
    const countInProgress = statusCounts[WorkItemStepStatus.InProgress] ?? 0;
    const countOnHold = statusCounts[WorkItemStepStatus.OnHold] ?? 0;
    const countUnstarted = statusCounts[WorkItemStepStatus.Unstarted] ?? 0;

    const totalCount = countCompleted + countInProgress + countOnHold + countUnstarted;

    const percentCompleted = (countCompleted / totalCount) * 100;
    const percentInProgress = (countInProgress / totalCount) * 100;
    const percentOnHold = (countOnHold / totalCount) * 100;
    const percentUnstarted = (countUnstarted / totalCount) * 100;

    if (totalCount === 0) {
        return null;
    }

    return (
        <Tooltip
            title={
                <div className="WorkStepCountsProgress__Tooltip">
                    {showPercentage && (
                        <div className="WorkStepCountsProgress__Tooltip__Header">
                            {totalCount} {totalCount === 1 ? 'step' : 'steps'}, {countCompleted}{' '}
                            complete ({percentCompleted.toFixed(0)}%)
                        </div>
                    )}
                    <div className="WorkStepCountsProgress__Tooltip__Statuses">
                        <TooltipStepStatus
                            status={WorkItemStepStatus.Completed}
                            count={countCompleted}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.InProgress}
                            count={countInProgress}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.OnHold}
                            count={countOnHold}
                        />
                        <TooltipStepStatus
                            status={WorkItemStepStatus.Unstarted}
                            count={countUnstarted}
                        />
                    </div>
                </div>
            }
            placement="top"
            disableInteractive={true}
            arrow
        >
            <div className="WorkStepCountsProgress">
                <div className="WorkStepCountsProgress__Bar">
                    <div
                        className={`WorkStepCountsProgress__Bar__Progress ${WorkItemStepStatus.Completed}`}
                        style={{ width: `${percentCompleted}%` }}
                    />
                    <div
                        className={`WorkStepCountsProgress__Bar__Progress ${WorkItemStepStatus.InProgress}`}
                        style={{ width: `${percentInProgress}%` }}
                    />
                    <div
                        className={`WorkStepCountsProgress__Bar__Progress ${WorkItemStepStatus.OnHold}`}
                        style={{ width: `${percentOnHold}%` }}
                    />
                    <div
                        className={`WorkStepCountsProgress__Bar__Progress ${WorkItemStepStatus.Unstarted}`}
                        style={{ width: `${percentUnstarted}%` }}
                    />
                </div>
                {showPercentage && (
                    <div className="WorkStepCountsProgress__Percent">
                        {percentCompleted.toFixed(0)}%
                    </div>
                )}
            </div>
        </Tooltip>
    );
}

function TooltipStepStatus({ status, count }: { status: WorkItemStepStatus; count: number }) {
    if (count === 0) {
        return null;
    }

    return (
        <div className="WorkStepCountsProgress__TooltipStepStatus">
            <div
                className={coalesceClassNames(
                    'WorkStepCountsProgress__TooltipStepStatus__Color',
                    status,
                )}
            />
            <div className="WorkStepCountsProgress__TooltipStepStatus__Label">
                {WorkItemStepStatusDisplay.display(status)}
            </div>
            <div className="WorkStepCountsProgress__TooltipStepStatus__Value">{count}</div>
        </div>
    );
}
