import MySelectInput from 'components/MySelectInput/MySelectInput';
import {
    dateFilterOptionsAny,
    dateFilterOptionsFuture,
    dateFilterOptionsPast,
} from 'models/DateFilterOption';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { CriteriaCompProps, DataTableCriteriaFieldBase } from '../DataTableCriteriaTypes';

export type DateCriteriaFieldConfig = DataTableCriteriaFieldBase & {
    type: 'date';
    range?: 'past' | 'future' | 'any';
    field: string;
};

export const getDateCriteriaFromValue = (value: string, propertyKey: string) => {
    const opt = dateFilterOptionsAny.find(o => o.value === value);
    if (!opt) {
        throw new Error(`Unknown date criteria value '${value}'`);
    }
    return {
        type: 'group',
        operator: 'AND',
        left: {
            type: 'date',
            propertyKey,
            equality: 'GREATERTHANEQUAL',
            value: opt.minDate,
        },
        right: {
            type: 'date',
            propertyKey,
            equality: 'LESSTHANEQUAL',
            value: opt.maxDate,
        },
    };
};

export default function DateCriteriaField({
    field,
    onChange,
}: CriteriaCompProps<DateCriteriaFieldConfig>) {
    const handleInput = (val: string) => {
        field.value = val;
        onChange?.(field);
    };

    return (
        <div
            className={coalesceClassNames(
                'DataTableCriteria__Field',
                'DataTableCriteria__Field--Date',
            )}
        >
            <MySelectInput
                label={field.config.label}
                value={field.value}
                options={
                    field.config.range === 'future'
                        ? dateFilterOptionsFuture
                        : field.config.range === 'past'
                        ? dateFilterOptionsPast
                        : dateFilterOptionsAny
                }
                handleInput={handleInput}
                allowBlank={true}
            />
        </div>
    );
}
