import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ErrorContent.scss';

export default function ErrorContent({
    className,
    align = 'center',
}: {
    className?: string;
    align?: 'left' | 'center';
}) {
    return (
        <div
            className={coalesceClassNames(
                'ErrorContent',
                `ErrorContent--align-${align}`,
                className,
            )}
        >
            <div className="ErrorContent__TopWrapper">
                <div className="ErrorContent__Icon">😭</div>
                <h2 className="ErrorContent__Title">Sorry, something went wrong</h2>
            </div>
            <p className="ErrorContent__Message">
                The server might be offline or experiencing issues, please try again later or
                contact technical support.
            </p>
        </div>
    );
}
