import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import InventoryReceiptStatus, {
    InventoryReceiptStatusDisplay,
} from 'features/inventory/enums/InventoryReceiptStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './InventoryReceiptStatusBadge.scss';

export default function InventoryReceiptStatusBadges({
    status,
    isArchived,
    size = 'medium',
}: {
    status: InventoryReceiptStatus;
    isArchived?: boolean;
    size?: StatusPillSize;
}) {
    return (
        <>
            <StatusPill
                className={coalesceClassNames('InventoryReceiptStatusBadge', status)}
                size={size}
            >
                {InventoryReceiptStatusDisplay.display(status)}
            </StatusPill>
            {isArchived && (
                <>
                    {' '}
                    <ArchivedBadge size={size} />
                </>
            )}
        </>
    );
}
