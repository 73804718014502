import Icons from 'Icons';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import StocktakeTable from 'features/stocktake/components/StocktakeTable/StocktakeTable';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './StocktakePage.scss';

export default function StocktakePage() {
    usePageTitle('Stocktake');

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.Stocktake], { onMount: true });

    return (
        <>
            <LayoutHeader>
                <PageHeader title="Stocktake">
                    <div className="StocktakePage__Actions">
                        <MyButton
                            buttonType="Accent"
                            size="medium"
                            label="New stocktake"
                            IconLeft={Icons.Plus}
                            href="create"
                            LinkComponent={MyButtonLink}
                        />
                    </div>
                </PageHeader>
            </LayoutHeader>

            <StocktakeTable />

            <Outlet />
        </>
    );
}
