import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ArchivedBadge.scss';

export default function ArchivedBadge({
    className,
    size = 'small',
}: {
    className?: string;
    size?: StatusPillSize;
}) {
    return (
        <StatusPill
            className={coalesceClassNames('ArchivedBadge', className)}
            size={size}
        >
            Archived
        </StatusPill>
    );
}
