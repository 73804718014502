import z from 'zod';
import WorkstationPrintMethod from '../enums/WorkstationPrintMethod';
import WorkstationPrintOption from '../enums/WorkstationPrintOption';
import { WorkstationBasicSchema } from './WorkstationBasic';

export const WorkstationSchema = WorkstationBasicSchema.extend({
    allowMultipleInProgress: z.boolean(),
    printOption: z.nativeEnum(WorkstationPrintOption),
    printMethod: z.nativeEnum(WorkstationPrintMethod),
});

export type Workstation = z.infer<typeof WorkstationSchema>;
