import { ApiTagType, api } from 'services/api';
import z from 'zod';
import { Brand, BrandSchema } from './models/Brand';
import { Customer, CustomerSchema } from './models/Customer';
import { CustomerDetail, CustomerDetailSchema } from './models/CustomerDetail';

const customerLoginTokenResultSchema = z.object({
    data: z.string(),
});

const brandListResultSchema = z.object({
    data: z.array(BrandSchema),
});

const customersApi = api.injectEndpoints({
    endpoints: build => ({
        customerList: build.query<Customer[], void>({
            query: () => ({
                url: `/manufacturer/customer`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.array(CustomerSchema);
                return schema.parse(result);
            },
            providesTags: [ApiTagType.Customer],
        }),

        customerDetail: build.query<CustomerDetail, number>({
            query: id => ({
                url: `/manufacturer/customer/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => CustomerDetailSchema.parse(result),
            providesTags: [ApiTagType.Customer],
        }),

        customerUpdate: build.mutation<void, CustomerDetail>({
            query: cust => ({
                url: `/manufacturer/customer/${cust.id}`,
                method: 'POST',
                data: {
                    brands: cust.context.brands?.map(b => b.id),
                    holdStatus: cust.context.customerConfig.status,
                    notes: cust.context.customerConfig.notes,
                    isInternal: cust.context.customerConfig.isInternal,
                    shippingDetails: cust.context.customerConfig.shippingDetails,
                    taxApplicable: cust.context.customerConfig.taxApplicable,
                    paymentMethod: cust.context.customerConfig.paymentMethod,
                    creditLimit: cust.context.customerConfig.creditLimit,
                    creditTerms: cust.context.customerConfig.creditTerms,
                    taxExemptions: cust.context.customerConfig?.taxExemptions ?? [],
                },
            }),
            invalidatesTags: [ApiTagType.Customer],
        }),

        /** Create a login token for the dealer portal
         * @returns a url to the portal with login token as param */
        customerLoginToken: build.query<string, number>({
            query: customerId => ({
                url: `/old/manufacturer-customers/${customerId}/login-token`,
                method: 'PUT',
            }),
            transformResponse: (result: unknown) =>
                customerLoginTokenResultSchema.parse(result).data,
        }),

        brandList: build.query<Brand[], void>({
            query: () => ({
                url: `/old/brands/select`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => brandListResultSchema.parse(result).data,
        }),
    }),
});

export default customersApi;
