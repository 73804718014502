import { useLayoutClassModifier } from 'components/Layout/Layout';
import ScheduleMain from 'features/schedule/components/ScheduleMain/ScheduleMain';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './SchedulePage.scss';

export default function SchedulePage() {
    usePageTitle('Schedule');
    useLayoutClassModifier('Schedule');

    // Refresh relevant data when the page is loaded
    useApiTagInvalidate([ApiTagType.Schedule, ApiTagType.WorkOrder], {
        onMount: true,
    });

    return <ScheduleMain />;
}
