import { useCallback, useEffect } from 'react';
import { api, ApiTagType } from 'services/api';
import { useAppDispatch } from 'store/hooks';

/** Easy wrapper to invalidate api keys on demand or when component mounts */
export default function useApiTagInvalidate(
    keys: (ApiTagType | { type: ApiTagType; id: any })[],
    options?: { onMount?: boolean },
) {
    const dispatch = useAppDispatch();

    const invalidate = useCallback(() => {
        dispatch(api.util.invalidateTags(keys));
    }, [dispatch, keys]);

    useEffect(
        () => {
            if (options?.onMount) {
                invalidate();
            }
        },
        // Only run on mount - not on every render
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return invalidate;
}
