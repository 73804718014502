import z from 'zod';
import { WorkItemFieldAppearance } from '../enums/WorkItemFieldAppearance';
import WorkItemFieldUnits from '../enums/WorkItemFieldUnits';
import { WorkOrderItemEntitySchema } from './WorkOrderItemEntity';

// the value field can be a string, number or boolean
// or it can be an array of any of the above
// declared here to make it more readable below
const valueTypes = [z.string(), z.number(), z.boolean()] as const;

export const WorkstationWorkItemFieldSchema = z.object({
    key: z.string(),
    value: z.union([...valueTypes, z.array(z.union(valueTypes))]).nullable(),
    displayName: z.string().nullable(),
    hideIfEmpty: z.boolean(),
    unitSystem: z.nativeEnum(WorkItemFieldUnits),
    appearance: z.nativeEnum(WorkItemFieldAppearance).optional(),
});

export const WorkstationWorkItemSchema = WorkOrderItemEntitySchema.extend({
    identifier: z.string().nullable(),
    detailFields: z.array(WorkstationWorkItemFieldSchema),
    summaryFields: z.array(
        // summary fields should not have an appearance
        // for "reasons" the BE allows setting it but it is not intended to be shown on the summary fields
        WorkstationWorkItemFieldSchema.transform(f => {
            delete f.appearance;
            return f;
        }),
    ),
});

export type WorkstationWorkItemField = z.infer<typeof WorkstationWorkItemFieldSchema>;
export type WorkstationWorkItem = z.infer<typeof WorkstationWorkItemSchema>;
