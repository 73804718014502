import z from 'zod';
import UnitOfMeasureFamily from '../enums/UnitOfMeasureFamily';

export const UnitOfMeasureSchema = z.object({
    key: z.string(),
    name: z.string(),
    family: z.nativeEnum(UnitOfMeasureFamily),
    symbol: z.string(),
});

export type UnitOfMeasure = z.infer<typeof UnitOfMeasureSchema>;
