import { UdfValueHashSchema } from 'models/UserDefinedField';
import z from 'zod';

export const SupplierSchema = z.object({
    id: z.string(),
    name: z.string(),
    emailAddress: z.string().optional(),
    fax: z.string().optional(),
    phone1: z.string().optional(),
    phone2: z.string().optional(),
    userDefinedFields: UdfValueHashSchema,
});

export type Supplier = z.infer<typeof SupplierSchema>;
