enum FeatureFlag {
    Accounting = 'accounting',
    CostPrice = 'cost_price',
    Dispatch = 'dispatch',
    Receiving = 'receiving',
    SalesOrderUpdateRoutesNew = 'sales_order_update_routes_new',
    ProductOptionsNew = 'product_options_new',
}

export default FeatureFlag;
