import Icons from 'Icons';
import AttributeEdit from 'components/AttributeEdit/AttributeEdit';
import MyEditModal from 'components/MyEditModal/MyEditModal';
import MyTabs, { TabBuilder } from 'components/MyTabs/MyTabs';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyEditNumber from 'components/PropertyEditNumber/PropertyEditNumber';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import UdfEdit from 'components/UdfEdit/UdfEdit';
import YesNoEnum, { YesNoEnumDisplay } from 'enums/YesNoEnum';
import inventoryApi from 'features/inventory/inventory.api';
import { Inventory } from 'features/inventory/models/Inventory';
import { InventoryGroupListWithAttributesResult } from 'features/inventory/models/InventoryGroupListWithAttributesResult';
import useUrlQueryState from 'hooks/useUrlQueryState';
import { UserDefinedField } from 'models/UserDefinedField';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo } from 'react';
import InventoryDetailsMeasures from './InventoryDetailsMeasures';
import './InventoryDetailsModal.scss';
import InventoryDetailsMovements from './InventoryDetailsMovements';
import InventoryDetailsStockLevels from './InventoryDetailsStockLevels';

const TAB_NAMES = ['details', 'levels', 'movements', 'measures'] as const;

export default function InventoryDetailsModal({
    model,
    isNew = false,
    udfs = [],
    isLoading = false,
    isError = false,
    onSave,
    isSaving,
    close,
}: {
    model?: Inventory;
    isNew?: boolean;
    udfs?: UserDefinedField[];
    isLoading?: boolean;
    isError?: boolean;
    onSave: (model: NonNullable<Inventory>) => Promise<any | void>;
    isSaving: boolean;
    close?: () => void;
}) {
    const [archiveQuery] = inventoryApi.useInventoryArchiveMutation();

    const [tab] = useUrlQueryState('tab', {
        allowedValues: TAB_NAMES,
    });
    const dialogManager = useDialogManager();

    const handleArchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            message: 'Are you sure you want to archive this record?',
            acceptLabel: 'Yes, archive it',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(archiveQuery(model.id).unwrap());
            close?.();
        }
    }, [close, archiveQuery, dialogManager, model]);

    return (
        <MyEditModal
            className="InventoryDetailsModal"
            title={isNew ? 'Add Inventory' : 'Inventory'}
            titleContext={model?.partNumber}
            subtitle={model?.description}
            mobileTitle="Inventory"
            close={close}
            isLoading={isLoading}
            isError={isError}
            model={model}
            onSave={onSave}
            isSaving={isSaving}
            withTabs={true}
            readonly={tab !== 'details'}
            editImmediately={isNew}
            headerMenuItems={
                isNew
                    ? undefined
                    : [
                          {
                              label: 'Archive',
                              IconLeft: Icons.Archive,
                              onClick: handleArchive,
                          },
                      ]
            }
        >
            {({ editModel, isEditing, updateField }) => (
                <Tabs
                    editModel={editModel}
                    isEditing={isEditing}
                    updateField={updateField}
                    isSaving={isSaving}
                    isNew={isNew}
                    udfs={udfs}
                />
            )}
        </MyEditModal>
    );
}

function Tabs({
    editModel,
    isEditing,
    isSaving,
    updateField,
    isNew,
    udfs,
}: {
    editModel: Inventory;
    isEditing: boolean;
    isSaving: boolean;
    isNew: boolean;
    udfs: UserDefinedField[];
    updateField: (data: Partial<Inventory>) => void;
}) {
    const [tab, setTab] = useUrlQueryState('tab', {
        allowedValues: TAB_NAMES,
    });

    const tabs = useMemo(
        () =>
            TabBuilder([
                {
                    name: 'details',
                    label: 'Details',
                    content: (
                        <DetailsTab
                            editModel={editModel}
                            isNew={isNew}
                            isEditing={isEditing}
                            updateField={updateField}
                            isSaving={isSaving}
                            udfs={udfs}
                        />
                    ),
                },
                !isNew && {
                    name: 'levels',
                    label: 'Stock Levels',
                    content: <InventoryDetailsStockLevels model={editModel} />,
                },
                !isNew && {
                    name: 'movements',
                    label: 'Stock Movements',
                    content: <InventoryDetailsMovements model={editModel} />,
                },
                !isNew &&
                    !!editModel && {
                        name: 'measures',
                        label: 'Measures',
                        content: <InventoryDetailsMeasures model={editModel} />,
                    },
            ]),
        [editModel, isEditing, isNew, isSaving, udfs, updateField],
    );

    return (
        <MyTabs
            activeTab={tab}
            setActiveTab={setTab}
            disabled={isEditing}
            tabs={tabs}
        />
    );
}

function DetailsTab({
    editModel,
    udfs,
    isNew,
    isEditing = false,
    isSaving = false,
    updateField,
}: {
    editModel: Inventory;
    udfs?: UserDefinedField[];
    isNew: boolean;
    isEditing?: boolean;
    isSaving?: boolean;
    updateField: (data: Partial<Inventory>) => void;
}) {
    const inventoryGroupsQuery = inventoryApi.useInventoryGroupListWithAttributesQuery();
    const suppliersQuery = inventoryApi.useSupplierListQuery();

    // SI measures are used to populate the initial unit of measure dropdown. This is later superceded by the inventory-specific measures
    const uomQuery = inventoryApi.useUnitOfMeasureListQuery(undefined, {
        skip: !isNew,
    });

    const displayUomOptions = useMemo(
        () =>
            uomQuery.data?.map(m => ({
                label: m.name,
                value: m.key,
            })) ?? [],
        [uomQuery.data],
    );

    const inventoryMeasuresQuery = inventoryApi.useInventoryMeasureListQuery(editModel.id, {
        skip: isNew,
    });

    const displayMeasureOptions = useMemo(
        () =>
            inventoryMeasuresQuery.data?.map(m => ({
                label: m.name ?? m.unitOfMeasure.name,
                value: m.id,
            })) ?? [],
        [inventoryMeasuresQuery.data],
    );

    const inventoryGroupOptions = useMemo(
        () =>
            (inventoryGroupsQuery.data?.data || []).map(group => ({
                label: group.name,
                value: group.id,
            })),
        [inventoryGroupsQuery.data],
    );

    const supplierOptions = useMemo(
        () =>
            (suppliersQuery.data?.data || []).map(supplier => ({
                label: supplier.name,
                value: supplier.id,
            })),
        [suppliersQuery.data],
    );

    return (
        <PropertyContainer className="InventoryDetailsModal__PropertyContainer">
            <div className="InventoryDetailsModal__PropertyContainer__Main">
                <PropertyEditText
                    label="Part no"
                    value={editModel.partNumber}
                    onChange={val => updateField({ partNumber: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                    autoFocus={true}
                    validationRequired={true}
                />

                <PropertyEditText
                    label="Description"
                    value={editModel.description}
                    onChange={val => updateField({ description: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                    validationRequired={true}
                />

                <PropertyContainer layout="row">
                    <PropertyEditSelect
                        className="InventoryDetailsModal__FixedWidthField"
                        label="Primary supplier"
                        value={editModel.tenantSupplierId || ''}
                        onChange={(val, label) =>
                            updateField({
                                tenantSupplierId: val,
                                tenantSupplierName: label,
                            })
                        }
                        readonly={!isEditing}
                        disabled={isSaving}
                        options={supplierOptions}
                        validationRequired={true}
                    />

                    <PropertyEditText
                        label="Supplier part no"
                        value={editModel.supplierPartNumber}
                        onChange={val => updateField({ supplierPartNumber: val })}
                        readonly={!isEditing}
                        disabled={isSaving}
                        validationRequired={true}
                    />
                </PropertyContainer>

                <PropertyContainer layout="row">
                    <PropertyEditSelect
                        className="InventoryDetailsModal__FixedWidthField"
                        label="Has serial numbers"
                        value={editModel.hasSerialNumber ? YesNoEnum.Yes : YesNoEnum.No}
                        options={YesNoEnumDisplay.options}
                        onChange={val => updateField({ hasSerialNumber: val === YesNoEnum.Yes })}
                        readonly={!isEditing}
                        disabled={isSaving}
                    />
                </PropertyContainer>
                <PropertyEditText
                    label="Notes"
                    multiline={true}
                    value={editModel.notes}
                    onChange={val => updateField({ notes: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                />

                <PropertyEditSelect
                    label="Inventory group"
                    value={editModel.inventoryGroupId}
                    onChange={(val, label) =>
                        updateField({
                            inventoryGroupId: val,
                            inventoryGroupName: label,
                        })
                    }
                    readonly={!isEditing}
                    disabled={isSaving}
                    options={inventoryGroupOptions}
                    validationRequired={true}
                />

                {/* Attributes */}
                <AttributesPanel
                    groupId={editModel.inventoryGroupId}
                    editModel={editModel}
                    inventoryGroups={inventoryGroupsQuery.data}
                    updateField={updateField}
                    isEditing={isEditing}
                    isSaving={isSaving}
                />

                {/* User defined fields */}
                {udfs?.map(udf => (
                    <UdfEdit
                        key={udf.id}
                        udf={udf}
                        value={editModel.userDefinedFields[udf.uniqueKey]}
                        onChange={val =>
                            updateField({
                                userDefinedFields: { [udf.uniqueKey]: val },
                            })
                        }
                        readonly={!isEditing}
                        disabled={isSaving}
                    />
                ))}
            </div>

            <div className="InventoryDetailsModal__UnitsWrapper">
                {isNew ? (
                    <PropertyEditSelect
                        className="InventoryDetailsModal__FixedWidthField"
                        label="Display Units"
                        value={editModel.displayUom}
                        options={displayUomOptions}
                        onChange={val => updateField({ displayUom: val })}
                        readonly={!isEditing}
                        disabled={isSaving}
                        allowBlank={false}
                        validationRequired={true}
                    />
                ) : (
                    <PropertyEditSelect
                        className="InventoryDetailsModal__FixedWidthField"
                        label="Display Units"
                        value={editModel.displayMeasureId ?? ''}
                        options={displayMeasureOptions}
                        onChange={val => updateField({ displayMeasureId: val })}
                        readonly={!isEditing || !displayMeasureOptions?.length}
                        disabled={isSaving}
                        // Only allow blank value if it was already blank. Once set it should not be removed.
                        allowBlank={!editModel.displayMeasureId}
                        validationRequired={editModel.displayMeasureId}
                    />
                )}
                <PropertyEditNumber
                    className="InventoryDetailsModal__FixedWidthField"
                    label="Minimum quantity level"
                    value={editModel.minimumQuantityLevel}
                    onChange={val => updateField({ minimumQuantityLevel: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                />
                <PropertyEditNumber
                    label="Maximum quantity level"
                    className="InventoryDetailsModal__FixedWidthField"
                    value={editModel.maximumQuantityLevel}
                    onChange={val => updateField({ maximumQuantityLevel: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                />
                <PropertyEditNumber
                    label="Reorder quantity"
                    value={editModel.reorderQuantity ?? undefined}
                    onChange={val => updateField({ reorderQuantity: val })}
                    readonly={!isEditing}
                    disabled={isSaving}
                    allowBlank={true}
                />
            </div>
        </PropertyContainer>
    );
}

function AttributesPanel({
    groupId,
    inventoryGroups,
    editModel,
    updateField,
    isEditing,
    isSaving,
}: {
    groupId: string;
    inventoryGroups?: InventoryGroupListWithAttributesResult;
    editModel: Inventory;
    updateField: (val: any) => void;
    isEditing?: boolean;
    isSaving?: boolean;
}) {
    const group = useMemo(
        () => inventoryGroups?.data.find(g => g.id === groupId),
        [inventoryGroups, groupId],
    );

    return (
        <>
            {group?.context.attributes.length ? (
                <PropertyContainer indent>
                    {group.context.attributes.map(attr => (
                        <AttributeEdit
                            key={attr.id}
                            attribute={attr}
                            value={editModel.userDefinedFields[attr.uniqueKey]}
                            onChange={val =>
                                updateField({
                                    userDefinedFields: { [attr.uniqueKey]: val },
                                })
                            }
                            readonly={!isEditing}
                            disabled={isSaving}
                        />
                    ))}
                </PropertyContainer>
            ) : null}
        </>
    );
}
