import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import React from 'react';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import './HelpIcon.scss';

export default function HelpIcon({
    tooltipTitle,
    className,
}: {
    tooltipTitle: React.ReactNode;
    className?: string;
}) {
    return (
        <Tooltip
            title={tooltipTitle}
            placement="top"
            arrow
            classes={{
                popper: coalesceClassNames(
                    'HelpIcon__Tooltip',
                    className && extendClassName(className, '__Tooltip'),
                ),
            }}
        >
            <a
                tabIndex={0}
                className={coalesceClassNames('HelpIcon', className)}
            >
                <Icons.Help className="HelpIcon__Icon" />
            </a>
        </Tooltip>
    );
}
